import React from 'react';

function NotFound() {
  return (
    <div>
      <h3>
        404: Page Not Found.
      </h3>
      <p>
        If we can help you find anything or you think this is a
        mistake, please contact us at
        &nbsp;<a href="mailto:support@growvera.com">support@growvera.com</a>
      </p>

    </div>
  );
}

export default NotFound;
