import React, { useState, useEffect, memo, useCallback } from "react";
// import "../Users/UsersView.css";
import { observer } from "mobx-react";
import { useRootStore } from "../StoreProvider";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DryStore, { Dry, Note } from "../stores/DryStore";
import DeleteWithConfirmation from "../DeleteWithConfirmation";
import UpdateNoteButton from "./UpdateNoteButton";
import DateTime from "../DateTime";


interface DryNoteViewProps {
    notes : Note[];
    deleteNote: (note : Note) => void;
    updateNote: (note: Note) => void;
}
const DryNoteView = observer(function DryNotesView(props: DryNoteViewProps) {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { userStore, accountStore, dryStore } = useRootStore();
  const users = Array.from(userStore.users.values());
  const navigate = useNavigate();
  const account = accountStore.currentAccount;
  const { notes, deleteNote, updateNote } = props;

  if (!account) {
    return <NotFound />;
  }
  
  if (deleteInProgress) {
    return <MainContentView><h2>Deleting Note...</h2></MainContentView>;
  }

  if (!notes) {
    return <NotFound />;
  }

  const DryNotesColumns: any = [

    {
        name: 'Time',
        selector: (row: Note) => row.timestamp,
        cell: (row: Note) => <DateTime iso8601={row.timestamp} />,
        sortable: true,
        id: "timeColumn"
    },
    {
      name: "Details",
      selector: (row: Note) => row.details,
    },
    {
      name: "Creator",
      selector: (row: Note, index: number) =>  row.creator,
      cell: (row: Note) => {
        const user = users.find(u => u.id === row.creator);
        if (user) {
          return user.given_name + " " + user.family_name;
        }
        return "Unknown User";
      },
      id: "creator",
    },
    {
      name: "",
      cell: (row: Note) => (
        row.id ?
        <>
            <UpdateNoteButton
            note = {row}
            editNote = {updateNote}
            />
            <DeleteWithConfirmation
            key={row.id}
            onDelete={() => deleteNote(row)}
            itemName={row.id}
            itemType={"Note"}
            /> 
        </>
        : ""
      )
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "3rem", // override the row height
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        justifyContent: "left",
      },
    },
    cells: {
      style: {
        justifyContent: "left",
      },
    },
  };


  return (
    <div>
        <DataTable
          columns={DryNotesColumns}
          data={notes}
          customStyles={customStyles}
          defaultSortFieldId={"timeColumn"}
          defaultSortAsc={false}
          pagination
          paginationPerPage={5} // Number of rows per page
          paginationRowsPerPageOptions={[5, 10]} // Options for rows per page
      />
    </div>
  );
});

export default DryNoteView;
