import React, { useEffect } from "react";
import { useRootStore } from "./StoreProvider";
import { observer } from "mobx-react";
import Loading from "./Loading";
import { CognitoAttributesType } from "./types";

interface DataLoadingComponentProps {
  children: React.ReactNode;
  cognitoUser: CognitoAttributesType;
}

let timeoutId: ReturnType<typeof setTimeout>;

const DataLoadingComponent = observer(function DataLoadingComponent(
  props: DataLoadingComponentProps
) {
  const rootStore = useRootStore();
  const {
    dryStore,
    sensorStore,
    spaceStore,
    strainStore,
    accountStore,
    gatewayStore,
    userStore,
  } = rootStore;

  const dryPoller = () => {
    dryStore.fetchUpdatedDries();
    setTimeout(dryPoller, 300000);
  };

  const dryGatewayPoller = () => {
    gatewayStore.fetchGateways();
    setTimeout(dryGatewayPoller, 30000);
  };


  const debounceFetch = () => {
    // Clear the previous timeout (if any)
    clearTimeout(timeoutId);
  
    // Set a new timeout
    timeoutId = setTimeout(() => {
      // Perform the fetch action after debounce delay (e.g., 500ms)
      console.log("fetching data")
      accountStore.createCurrentAccount(props.cognitoUser);
      dryGatewayPoller();
      dryPoller();

      sensorStore.fetchSensorPacks();
      spaceStore.fetchSpaces();
      strainStore.fetchStrains();
      gatewayStore.fetchGateways();
      userStore.fetchUsers();
    }, 500);
  }

  useEffect(() => {
    debounceFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rootStore.loaded) {
    return <Loading>Loading app...</Loading>;
  }

  return <>{props.children}</>;
});

export default DataLoadingComponent;
