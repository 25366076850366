import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./UpdateUser.css";
import Select from "react-select";
import Button from "../Button";
import CancelButton from "../CancelButton";
import ErrorText from "../ErrorText";
import MainContentView from "../MainContentView";
import UserFieldEditor from "../UserFieldEditor";
import { observer } from "mobx-react";
import { validateUserForm, UserFormErrors } from "../FormValidators";
import { User } from "../stores/UserStore";
import { useRootStore } from "../StoreProvider";

interface UpdateUserProps {
  user: User;
}

const UpdateUser = observer(function UpdateUser(props: UpdateUserProps) {
  const { userStore } = useRootStore();
  const { user } = props;
  const navigate = useNavigate();
  const initialErrors: UserFormErrors = {};
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [saveClicked, setSaveClicked] = useState(false);
  const userRoleOpts = User.roleOpts.map((s) => ({ label: s, value: s }));
  const userDefault = {
    label: user.role.toString(),
    value: user.role.toString(),
  };

  const handleChange = (selectedOption: any) => {
    user.setRole(selectedOption.value);
  };

  useEffect(() => {
    return function cleanup() {
      user.discardChanges();
    };
  }, [user]);

  const onSave = () => {
    const errors = validateUserForm(user);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSaveClicked(true);
      user
        .save()
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            navigate("/team");
          }
          setSaveClicked(false);
        })
        .catch((err) => {
          setSaveClicked(false);
        });
    }
  };

  return (
    <MainContentView>
      <div className="my-8 grid grid-cols-1 items-center gap-2">
        <div className="flex justify-between flex-col md:flex-row max-w-md">
          <div className="">
            <h2 className="title">First Name: </h2>
          </div>
          <div className="">
            <UserFieldEditor
              title={user.given_name}
              onChange={(evt) => {
                user.setGivenName(evt.target.value);
              }}
              value={user.given_name}
              setIsEditingFromOutside={() => {}}
            />
          </div>
          {formErrors.given_name && (
              <ErrorText>{formErrors.given_name}</ErrorText>
            )}
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
          <div className="">
            <h2 className="title">Last Name: </h2>
          </div>
          <div className="">
            <UserFieldEditor
              title={user.family_name}
              onChange={(evt) => {
                user.setFamilyName(evt.target.value);
              }}
              value={user.family_name}
              setIsEditingFromOutside={() => {}}
            />
      
          </div>
          {formErrors.family_name && (
              <ErrorText>{formErrors.family_name}</ErrorText>
            )}
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
          <div className="">
            <h2 className="title"> Email:</h2>
          </div>
          <div className="">
            <UserFieldEditor
              title={user.email}
              onChange={(evt) => {
                user.setEmail(evt.target.value);
              }}
              value={user.email}
              setIsEditingFromOutside={() => {}}
            />
           
          </div>
          {formErrors.email && <ErrorText>{formErrors.email}</ErrorText>}
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
          <h2 className="title">Role</h2>
          <div className="px-2">
            <Select
              defaultValue={userDefault}
              onChange={handleChange}
              options={userRoleOpts}
              className="w-48 text-black"
              placeholder="Select Role Here"
            />
          
          </div>
          {formErrors.role && <ErrorText>{formErrors.role}</ErrorText>}
        </div>

        <div />
      </div>

      <div className="my-8 grid grid-cols-1 gap-4">
        <div className="flex justify-between flex-col md:flex-row max-w-xl">
          <Button className="my-4 md:my-0" onPress={onSave} disabled={saveClicked}>
            {saveClicked
              ? "SAVING..."
              : user.id
              ? "UPDATE USER"
              : "ADD NEW USER"}
          </Button>
          {!saveClicked && (
            <CancelButton
              onCancel={() => {
                user.discardChanges();
                navigate("/team");
              }}
            />
          )}
        </div>
      </div>
      <div />
      {Object.keys(formErrors).length > 0 && (
        <div>
          <ErrorText>Check for errors in the fields above</ErrorText>
        </div>
      )}
    </MainContentView>
  );
});

export default UpdateUser;
