import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='footer-container' >
            <Link to={"/credits"} className='footer-link' >
                Growvera Zone Attributions
            </Link>
        </div>
    )
}

export default Footer