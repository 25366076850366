import React, { useState, useEffect, memo, useCallback } from "react";
import { observer } from "mobx-react";
import { useRootStore } from "../StoreProvider";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import AddNewTile from "../AddNewTile";
import StatBox from "../StatBox";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DryStore, { Dry, Note } from "../stores/DryStore";
import Modal from "react-modal";
import Button from "../Button";
import Select from "react-select";
import DeleteWithConfirmation from "../DeleteWithConfirmation";
import PlainTextEditor from "../PlainTextEditor";
import DateTimePicker from "../DatetimePicker";
import { MdEdit } from "react-icons/md";
import './UpdateNoteButton.css'
import { NoteFormErrors, validateNoteForm } from "../FormValidators";
import ErrorText from "../ErrorText";

interface UpdateNoteButtonProps {
    note: Note;
    editNote: (n: Note) => void;
}

function UpdateNoteButton(props: UpdateNoteButtonProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modelOpen, setModalOpen] = useState(false)
  const initialErrors: NoteFormErrors = {};
  const [formErrors, setFormErrors] = useState(initialErrors);
  const {note, editNote} = props;
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const [details, setDetails] = useState<string | "">("");

  useEffect(() => {
    if (note) {
      setTimestamp(note.timestamp);
      setDetails(note.details);
    }
  }, [note]);

  const handleDetailsChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (note) {
      note.setDetails(evt.target.value);
      setDetails(evt.target.value);
    }
  };

  const handleTimestampChange = (value: string) => {
    if (note) {
      note.setTimestamp(value);
      setTimestamp(value);
    }
  };

  if (!note) {
    return <NotFound/>; 
  }

  return (
    <div className="">
      <div
        className="EditNoteButton" onClick={() => setModalOpen(true)}>
        <MdEdit/>
      </div>
     
      <Modal
        isOpen={modelOpen}
        onRequestClose={() => {
            note.discardChanges();
            setFormErrors(initialErrors);
        }}
        className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 42, 64, 0.8)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }
        }}
        contentLabel="Example Modal"
      >
      <h1 className='modal-title' >Edit Note</h1>
      <div className="my-8 grid grid-cols-1 items-center gap-2">
        <div className="flex justify-between flex-col md:flex-row max-w-md">
            <h2 className="title">Time: </h2>
        </div>
          
        <div className="flex justify-between flex-col md:flex-row max-w-md">
            <DateTimePicker
                timestamp={timestamp || ""}
                onChange={handleTimestampChange}
            />
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
            <h2 className="title">Details: </h2>
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
            <PlainTextEditor
                text={details}
                value={details}
                onChange={handleDetailsChange}
            />
        </div>
        <div className="flex justify-between flex-col md:flex-row max-w-md">
            {formErrors.details && <ErrorText>{formErrors.details}</ErrorText>}
        </div>
      </div>
        <div className='w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4'>
          <Button className='Button-red' onPress={() => { 
            note.discardChanges(); 
            setFormErrors(initialErrors);
            setModalOpen(false) 
            }
            } >Cancel</Button>
          <Button className='Button-green' onPress={() => {
            const errors = validateNoteForm(note);
            setFormErrors(errors);
            if (Object.keys(errors).length === 0) {
                editNote(note);
                setModalOpen(false);
            }
            }} >Save Changes </Button>
        </div>

      </Modal >
    </div>
  );
}

export default UpdateNoteButton;
