import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootStore } from '../StoreProvider';
import Dry from './Dry';
import NotFound from '../NotFound';
import { Dry as zz } from '../stores/DryStore';

const DryContainer = observer(function DryContainer() {
  const { dryStore } = useRootStore();
  const { id } = useParams();
  const [dryTemp, detDryTemp] = useState<zz | undefined>(undefined);

  useEffect(() => {
    if (dryStore.loaded && id !== undefined) {
      const dry = dryStore.userDries.get(id);
      detDryTemp(dry);
    }
  }, [dryStore, id]);

  if (!dryStore.loaded || id === undefined) {
    return <NotFound />;
  }

  if (!dryTemp) {
    return <div>Loading...</div>;
  }

  return <Dry dry={dryTemp} />;
});

export default DryContainer;
