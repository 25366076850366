import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../StoreProvider';
import { useParams } from 'react-router-dom';
import { User } from '../stores/UserStore';
import UpdateUser from './UpdateUser';
import NotFound from '../NotFound';

const UpdateUserView = observer(function UpdateUserView() {
  const {
    userStore
  } = useRootStore();
  const { id } = useParams();

  let user: User | undefined;
  if (id) {
    user = userStore.users.get(id);
  } else {
    user = userStore.createUser();
  }
  if (user === undefined) {
    return <NotFound />;
  }

  return (
    <div>
      <UpdateUser
        user={user}
      />
    </div>
  );
});

export default UpdateUserView;
