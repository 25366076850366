import { Auth } from 'aws-amplify';
import axios from 'axios';
import { APIEndpoint } from './AWSConfig';

async function getConfig() {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = user.signInUserSession.idToken.jwtToken;
  return {
    headers: {
      Authorization: 'Bearer ' + jwtToken,
    },
    params : {}
  };
}

const httpClient = {
    apiGet: async function(path: string, options?: { params?: { NextToken?: string | null } }) {
    const config = await getConfig();
    
    // If there are options and a params object within it, merge this with the existing config
    if (options && options.params) {
        config.params = { ...config.params, ...options.params };
    }
    
    return axios.get(APIEndpoint + path, config);
    },
    apiPost: async function(path: string, data: string) {
        const config = await getConfig();
        return axios.post(APIEndpoint + path, data, config);
    },
    apiPut: async function(path: string, data: string) {
        const config = await getConfig();
        return axios.put(APIEndpoint + path, data, config);
    },
    apiDelete: async function(path: string) {
        const config = await getConfig();
        return axios.delete(APIEndpoint + path, config);
    },
};

export default httpClient;
