import ReactSlider from 'react-slider';
import './Slider.css';

type onChangeType = (values: Array<number>) => void;
interface SliderProps {
  value: Array<number>;
  title: string;
  unitIndicator?: string;
  onChange: onChangeType;
  min?: number;
  max?: number;
  minDistance?: number
  step?:number
}

function Slider(props: SliderProps) {
  const [leftValue, rightValue] = props.value;
  return (
    <div className="Slider-container">
      <h3>{props.title}</h3>
      <div className="Slider-values">
        {leftValue}{props.unitIndicator} - {rightValue}{props.unitIndicator}
      </div>
      <ReactSlider
        className="Slider"
        thumbClassName="Slider-thumb"
        trackClassName="Slider-track"
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={state => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        pearling
        {...props}
      />
    </div>
  );
}

export default Slider;
