import React from 'react';
import './StatBox.css';

interface StatBoxProps {
  title: string;
  children: React.ReactNode;
}

function StatBox(props: StatBoxProps) {
  return (
    <div className="StatBox">
      <h2>{props.title}</h2>
      <div className="StatBox-box">
        {props.children}
      </div>
    </div>
  );
}

export default StatBox;
