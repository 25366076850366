import React, { useCallback } from "react";
import "./DryMoistureChart.css";
import { DryTimeseriesDataType } from "../types";
import moment from "moment";

import {
  EAutoRange,
  EAxisAlignment,
  ELegendOrientation,
  ELineDrawMode,
  FastLineRenderableSeries,
  LegendModifier,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  SciChartOverview,
  SciChartSurface,
  VisibleRangeChangedArgs,
  XyDataSeries,
  XySeriesInfo,
  ZoomExtentsModifier,
  ZoomPanModifier,
} from "scichart";


interface DryMoistureChartProps {
  data: Array<DryTimeseriesDataType>;
  dryName: string;
  dryStartTime: string | undefined;
  moistureTarget: number | string;
}


export const divElementId = "chart";
export const divOverviewId = "overview";

const surfaceChart = (type: string, series: Array<any>, tm_series: Array<any>) => async () => {
  // Create a SciChartSurface
  const { wasmContext, sciChartSurface } = await SciChartSurface.create(
    `${type + divElementId}`
  );

  // Create an XAxis and YAxis
  const xAxis = new NumericAxis(wasmContext, {
    axisTitle: "Time Since Start"
  });
  const xAxisFormatter = (dataValue: number) => {
    const duration = moment.duration(dataValue);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    return days > 0 ? `${days}d ${hours}h` : `${hours}h`;
  };
  
  xAxis?.visibleRangeChanged.subscribe(
    (data: VisibleRangeChangedArgs | undefined) => {
      xAxis.labelProvider.formatLabel = xAxisFormatter;
    }
  );
  
  xAxis.labelProvider.formatCursorLabel = (dataValue) => {
    const duration = moment.duration(dataValue);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    return days > 0 ? `${days}d ${hours}h` : `${hours}h`;
  };

  const yAxis = new NumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Left,
    growBy: new NumberRange(0.1, 0.2),
    labelPrecision: 1,
    autoRange: EAutoRange.Always,
  });

  sciChartSurface.xAxes.add(xAxis);
  sciChartSurface.yAxes.add(yAxis);

  for (let i = 0; i < series.length; i++) {

    // Create arrays of x, y values (just arrays of numbers)
    const { xValues, yValues } = {
        xValues: series[i].data.map((d: { xx: any }) => d.xx),
        yValues: series[i].data.map((d: { y: any }) => d.y),
    };
    const lineSeries = new FastLineRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
        xValues,
        yValues,
        dataSeriesName: `${series[i].name}`,
        isSorted: true
        }),
        stroke: series[i].color,
        strokeThickness: 3,
        strokeDashArray:series[i].strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });
    const tooltipDataTemplate = (seriesInfo: XySeriesInfo): string[] => {
        const valuesWithLabels: string[] = [];
        // Line Series
        const xySeriesInfo = seriesInfo as XySeriesInfo;
        valuesWithLabels.push(
            ` ${xySeriesInfo.seriesName}: ${xySeriesInfo.formattedYValue} `
        );
        return valuesWithLabels;
        };
    
        lineSeries.rolloverModifierProps.tooltipDataTemplate = tooltipDataTemplate;
        sciChartSurface.renderableSeries.add(lineSeries);
  }

  for (let i = 0; i < tm_series.length; i++) {

    // Create arrays of x, y values (just arrays of numbers)
    const { xValues, yValues } = {
        xValues: tm_series[i].data.map((d: { xx: any }) => d.xx),
        yValues: tm_series[i].data.map((d: { y: any }) => d.y),
    };
    const tm_lineSeries = new FastLineRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
        xValues,
        yValues,
        dataSeriesName: `${tm_series[i].name}`,
        isSorted: true
        }),
        stroke: tm_series[i].color,
        strokeThickness: 3,
        strokeDashArray:tm_series[i].strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });

    const tooltipDataTemplate = (seriesInfo: XySeriesInfo): string[] => {
        const valuesWithLabels: string[] = [];
        // Line Series
        const xySeriesInfo = seriesInfo as XySeriesInfo;
        valuesWithLabels.push(
            ` ${xySeriesInfo.seriesName}: ${xySeriesInfo.formattedYValue} `
        );
        return valuesWithLabels;
    };
    
    tm_lineSeries.rolloverModifierProps.tooltipTextColor = "black"
    tm_lineSeries.rolloverModifierProps.tooltipDataTemplate = tooltipDataTemplate;
    sciChartSurface.renderableSeries.add(tm_lineSeries);
  }

  sciChartSurface.background = "#002a40";

  // Optional: Add some interactivity to the chart
  const modifierGroup = type;
  const overview = await SciChartOverview.create(
    sciChartSurface,
    `${type + divOverviewId}`
  );
  const rollover = new RolloverModifier({
    modifierGroup,
    showAxisLabel: true,
    rolloverLineStrokeDashArray: [2, 2],
  });

  sciChartSurface.chartModifiers.add(
    new ZoomExtentsModifier({ modifierGroup }),
    new MouseWheelZoomModifier({ modifierGroup }),
    new ZoomPanModifier({ modifierGroup }),
    rollover,
    new LegendModifier({
      modifierGroup,
      showCheckboxes: true,
      orientation: ELegendOrientation.Horizontal,
      placementDivId: `legend-${type}`,
      backgroundColor: "#002a40",
    })
  );

  sciChartSurface.zoomExtents();

  return { wasmContext, sciChartSurface, overview };
};

const DryMoistureChart = (props: DryMoistureChartProps) => {
 
  const sciChartSurfaceRef = React.useRef<SciChartSurface>();
  const sciChartOverviewRef = React.useRef<SciChartOverview>();
  const seriesRef = React.useRef<DryTimeseriesDataType[]>()

  const type = "dryChart";
  const unsubscribe = React.useCallback(() => {
    sciChartSurfaceRef.current?.xAxes
      .get(0)
      ?.visibleRangeChanged.unsubscribeAll();
    sciChartSurfaceRef.current?.delete();
    sciChartOverviewRef.current?.delete();
    sciChartSurfaceRef.current = undefined;
    sciChartOverviewRef.current = undefined;
  }, []);

  const {data, dryName, dryStartTime, moistureTarget} = props

  const getData = useCallback(() => {
    const startTime = moment(dryStartTime).valueOf();
    const series = [
      {
        name: "Average Moisture",
     data: data.map((d) => (
        {
          xx: moment(d.timestamp).valueOf() - startTime,
          y: d.avg_moisture,
        })),
        color: "#82ca9d",
      }
    ];
    return series;
  }, [data, dryName, dryStartTime]);


  const getTargetMoistureData = useCallback(() => {
    const startTime = moment(dryStartTime).valueOf();
    const tm_series = [
        {
          name: "Target moisture",
          data: data.map((d) => (
          {
            xx: moment(d.timestamp).valueOf() - startTime,
            y: moistureTarget,
          })),
          color: "#ffffff",
          strokeDashArray: [5,5]
        }
    ];
    return tm_series
  }, [data, dryStartTime, moistureTarget])


  React.useEffect(() => {
    const series = getData();
    const tm_series = getTargetMoistureData();
    const fetchData = async () => {
      const { sciChartSurface, overview } = await surfaceChart(type, series, tm_series)();
      sciChartSurfaceRef.current = sciChartSurface;
      sciChartOverviewRef.current = overview;
      seriesRef.current = data
    };
    fetchData();

    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        unsubscribe();
        return;
      }

      // else postpone deletion
      fetchData().then(() => {
        unsubscribe();
      });
    };
  }, [unsubscribe]);

  React.useEffect(() => {

    if (sciChartSurfaceRef.current) {
      let series = getData()
      
      const redneredSeries = sciChartSurfaceRef.current.renderableSeries
      for (let i = 0; i < redneredSeries.size(); i++) {
        let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries
        const temp = series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()) || []
        const { xValues, yValues } = {
          xValues: temp.map((d: { xx: any }) => d.xx),
          yValues: temp.map((d: { y: any }) => d.y),
        };
    
        // console.log(xyDs.count(), xValues, yValues)
        
        xValues?.forEach((x, ind) => {
          xyDs.append(x, yValues?.[ind] || Number.NaN) 
        })
        
      }
    }
  }, [data])

  return (
    <div className="">
      <div style={{}}>
        <div>
          <div
            id={`${type + divElementId}`}
            style={{ width: "100%", height: 370 }}
          />
          <div
            id={`${type + divOverviewId}`}
            style={{ width: "100%", height: 100 }}
          />
          <div id={`legend-${type}`}  />
        </div>
        {/* <div id={`tooltip-${type}`} style={{ width: "10%" }} /> */}
      </div>
    </div>
  );
};

export default DryMoistureChart;
