// DateTimePicker.tsx
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatetimePicker.css';

interface DateTimePickerProps {
  timestamp: string | null;
  onChange: (newTimestamp: string) => void;
}

function DateTimePicker(props: DateTimePickerProps) {
  const {timestamp, onChange } = props;
  const isValidTimestamp = timestamp && timestamp.trim() !== "";
  const initialDate = isValidTimestamp ? new Date(timestamp!) : new Date();

  const [date, setDate] = useState<Date | null>(initialDate);
  const [time, setTime] = useState<string>(
    initialDate.toTimeString().slice(0, 5)
  );
  const [lastValidTime, setLastValidTime] = useState<string>(initialDate.toTimeString().slice(0, 5));

  useEffect(() => {
    if (date) {
      const [hours, minutes] = time.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes)) {
        const updatedDate = new Date(date);
        updatedDate.setHours(hours, minutes);
        onChange(updatedDate.toISOString());
        setLastValidTime(time); // Update last valid time
      } else {
        setTime(lastValidTime); // Revert to last valid time if current time is invalid
      }
    }
  }, [date, time, onChange]);


  return (
    <div className='DateTimePicker-container'>
        <div className='DateTimePicker-wrapper'>
            <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="DateTimePicker-time"
            />
            <DatePicker
                selected={date}
                onChange={(newDate: Date) => setDate(newDate)}
                dateFormat="MMMM d, yyyy"
                className="DateTimePicker-date"
            />
        </div>
    </div>
  );
};

export default DateTimePicker;