import React from "react";
import "./CancelButton.css";

interface CancelButtonProps {
  children?: React.ReactNode;
  onCancel: () => void;
  onMouseDown?: any;
}

function CancelButton(props: CancelButtonProps) {
  const { children, onCancel, onMouseDown } = props;
  const inner = children || "CANCEL";
  return (
    <div className="CancelButton" onClick={onCancel} onMouseDown={onMouseDown}>
      {inner}
    </div>
  );
}

export default CancelButton;
