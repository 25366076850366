import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import "./HomeView.css";
import { useRootStore } from "../StoreProvider";
import search from "../Search";

import AddNewTile from "../AddNewTile";
import MainContentView from "../MainContentView";
import DryTile from "./DryTile";
import { Dry } from "../stores/DryStore";
import { title } from "process";

interface HomeViewSectionProps {
  title: string;
  value: string;
  setValue: React.Dispatch<string>;
  list: Dry[];
  addNew: boolean
}

const HomeViewSection = (props: HomeViewSectionProps) => {
  const { title, value, setValue, list, addNew } = props;
  return (
    <>
      <div className="flex uppercase flex-col sm:flex-row justify-center sm:justify-start sm:items-center">
        <h2 className="text-2xl py-2 sm:pr-12 sm:py-8">{title}</h2>
        <div className="p-2">
          <input
            placeholder="Type to filter..."
            onChange={(evt) => setValue(evt.target.value)}
            value={value}
          />
        </div>
      </div>
      <div className="flex mb-8 overflow-x-auto">
        {addNew && <AddNewTile path="/home/dries/new" text="Add New Dry" />}
        {list.map((dry) => (
          <DryTile key={dry.id} dry={dry} />
        ))}
      </div>
    </>
  );
};

const HomeView = observer(function HomeView() {
  const { dryStore } = useRootStore();
  const { notStartedDries, ongoingDries, completedDries } = dryStore;
  const maxDisplayedCompletedDries = 100;

  const [ongoingSearchTerm, setOngoingSearchTerm] = useState("");
  const [pendingSearchTerm, setPendingSearchTerm] = useState("");
  const [completedSearchTerm, setCompletedSearchTerm] = useState("");
  const [filteredOngoing, setFilteredOngoing] = useState<Dry[]>([]);

  const [filteredPending, setFilteredPending] = useState<Dry[]>([]);
  const [displayedCompletedDries, setDisplayedCompletedDries] = useState<Dry[]>(
    []
  );
  const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const dryPoller = useCallback(()  => {
    setFilteredOngoing(search(ongoingDries, "name", ongoingSearchTerm));
    setFilteredPending(search(notStartedDries, "name", pendingSearchTerm));
    const filteredC = search(completedDries, "name", completedSearchTerm);
    filteredC.sort((a, b) => {
      const stoptimeA: any = new Date(a.stoptime);
      const stoptimeB: any = new Date(b.stoptime);
      return stoptimeB - stoptimeA;
    });
    setDisplayedCompletedDries(
      filteredC.slice(0, maxDisplayedCompletedDries)
    );  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingSearchTerm, pendingSearchTerm, completedSearchTerm]);

  useEffect(() => {
    dryPoller()
    intervalIdRef.current = setInterval(dryPoller, 3000);
    return () => {clearInterval(intervalIdRef.current)}
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingSearchTerm, pendingSearchTerm, completedSearchTerm]);
  
  return (
    <MainContentView>
      <HomeViewSection
        title="Ongoing Dries"
        value={ongoingSearchTerm}
        setValue={setOngoingSearchTerm}
        list={filteredOngoing}
        addNew={true}
      />

      {notStartedDries.length > 0 && (
        <HomeViewSection
          title="Pending Dries"
          value={pendingSearchTerm}
          setValue={setPendingSearchTerm}
          list={filteredPending}
          addNew={false}
        />
      )}
      {completedDries.length > 0 && (
        <>
          <HomeViewSection
            title="Recent Dries"
            value={completedSearchTerm}
            setValue={setCompletedSearchTerm}
            list={displayedCompletedDries}
            addNew={false}
          />
          {completedDries.length > maxDisplayedCompletedDries && (
            <Link to="/reports">View all completed dries</Link>
          )}
        </>
      )}
    </MainContentView>
  );
});

export default HomeView;
