import React from 'react';
import './MainContentView.css';

interface MainContentViewProps {
  children: React.ReactNode;
}

function MainContentView(props: MainContentViewProps) {
  return (
    <div className="py-2 px-4 overflow-y-scroll md:px-12">
      {props.children}
    </div>
  );
}

export default MainContentView;
