import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import './DeleteWithConfirmation.css';
import Modal from 'react-modal';
import Button from './Button';

type onDeleteType = () => void;
interface DeleteWithConfirmationProps {
  onDelete: onDeleteType;
  key: any;
  itemName: any;
  itemType: any;
}

function DeleteWithConfirmation(props: DeleteWithConfirmationProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modelOpen, setModalOpen] = useState(false)
  return (
    <div className="">
      <div
        className="DeleteWithConfirmation-initial"
        onClick={() => setModalOpen(true)}
      >
        <MdDelete />
      </div>

     
      <Modal
        isOpen={modelOpen}
        onRequestClose={() => setModalOpen(false)}
        className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 42, 64, 0.8)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }
        }}
        contentLabel="Example Modal"
      >
        <h1 className='modal-title' >{props.itemType === "Note" ? `Delete ${props.itemType}` :`Delete ${props.itemType} - ${props.itemName}`}</h1>
        <p className='modal-description' >
            {props.itemType === "Sensor Pack" || props.itemType === "Note" ?
            `Are you sure you want to remove this ${props.itemType} from the dry?`
            : 
            `Deleting a ${props.itemType} is an irreversible process. Are you sure you want to delete this ${props.itemType}?`
            }
        </p>
        <div className='w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4'>
          <Button onPress={() => setModalOpen(false)} >No</Button>
          <Button className='Button-red' 
          onPress={() => {
          props.onDelete();
          setModalOpen(false);
          }}>
            Yes
          </Button>
        </div>

      </Modal >
    </div>
  );
}

export default DeleteWithConfirmation;
