import React from 'react';
import RootStore from './stores/RootStore';

let store: RootStore;
const StoreContext = React.createContext<RootStore | undefined>(undefined);

export function RootStoreProvider({ children }: { children: React.ReactNode }) {
  const rootStore = store ?? new RootStore();
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
}

export function useRootStore() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
}
