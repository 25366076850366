import React, { useState, useEffect } from "react";
import Select, {StylesConfig} from "react-select";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import "./Dry.css";
import "./UpdateDry.css";
import Button from "../Button";
import CancelButton from "../CancelButton";
import DeleteWithConfirmation from "../DeleteWithConfirmation";
import ErrorText from "../ErrorText";
import MainContentView from "../MainContentView";
import Slider from "../Slider";
import TitleEditor from "../TitleEditor";
import { CelsiusTemperature } from "../types";
import { Dry } from "../stores/DryStore";
import { DryFormErrors, validateDryForm } from "../FormValidators";
import { SensorPack } from "../stores/SensorStore";
import { Space } from "../stores/SpaceStore";
import { Strain } from "../stores/StrainStore";
import { useRootStore } from "../StoreProvider";
import StringEditor from "../StringEditor";
import NumberEditor from "../NumberEditor";
import QuestionMarkPopup from "../QuestionMarkPopup";
import IdealDryChart from "../strains/IdealDryChart";

interface OptionType {
    label: string;
    value: string;
  }

interface UpdateDryProps {
  dry: Dry;
  sensorPacks: Array<SensorPack>;
  spaces: Array<Space>;
  strains: Array<Strain>;
}

const UpdateDry = observer(function UpdateDry(props: UpdateDryProps) {
  const { dry, sensorPacks, spaces, strains } = props;
  const uiStore = dry.store.rootStore.uiStore;
  const navigate = useNavigate();
  const initialErrors: DryFormErrors = {};
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [saveClicked, setSaveClicked] = useState(false);
  const sensorPackOpts = sensorPacks
    .filter((s) => !dry.sensor_pack_ids?.includes(s.id))
    .map((s) => ({ label: s.id, value: s.id }));
  const spaceOpts = spaces.map((s) => ({ label: s.name, value: s.id }));
  const spaceDefault = dry.space
    ? spaceOpts.find((o) => o.value === dry.space_id)
    : null;
  const strainOpts = strains.map((s) => ({ label: s.name, value: s.id }));
  const strainDefault = dry.strain
    ? strainOpts.find((o) => o.value === dry.strain_id)
    : null;
  const enrolledSensorPacks = dry.sensorPacks ? (
    dry.sensorPacks.map((s) => (
      <div className="UpdateDry-enrolled-sensor-item" key={s?.id}>
        {s?.id}
        {s && (
          <DeleteWithConfirmation
            key={s.id}
            onDelete={() => dry.removeSensorPack(s.id)}
            itemName={s.id}
            itemType={"dry"}
          />
        )}
      </div>
    ))
  ) : (
    <i>None yet</i>
  );
  const [temperatureRange, setTemperatureRange] = useState([
    uiStore.displayTemperature(dry.temperature_lower_bound),
    uiStore.displayTemperature(dry.temperature_upper_bound),
  ]);
  const [humidityRange, setHumidityRange] = useState([
    dry.humidity_lower_bound,
    dry.humidity_upper_bound,
  ]);
  const [vpdRange, setVpdRange] = useState([
    dry.vpd_lower_bound,
    dry.vpd_upper_bound,
  ]);
  const [moistureTarget, setMoistureTarget] = useState(
    dry.moisture_target || 0
  );
  const [humidityVariability, setHumidityVariability] = useState(
    dry.humidity_spatial_variability || ""
  );
  const [vpdVariability, setVpdVariability] = useState(
    dry.vpd_spatial_variability || ""
  );
  const [temperatureVariability, setTemperatureVariability] = useState(
    uiStore.displayTemperatureDelta(dry.temperature_spatial_variability) || ""
  );
  const [moistureVariability, setMoistureVariability] = useState(
    dry.moisture_spatial_variability || ""
  );

  const customSelectStyles : StylesConfig<OptionType, false> = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    })
  };

  useEffect(() => {
    return function cleanup() {
      dry.discardChanges();
    };
  }, [dry]);

  const changeStrain = (strainId: string) => {
    if (strainId === dry.strain_id) {
      return;
    }
    dry.setStrain(strainId);
    const strain = dry.strain;
    if (strain) {
      setMoistureTarget(strain.moisture_target);
      setMoistureVariability(strain.moisture_spatial_variability);
    }
  };

  const changeSpace = (spaceId: string) => {
    if (spaceId === dry.space_id) {
      return;
    }
    dry.setSpace(spaceId);
    const space = dry.space;
    if (space) {
      updateTemperatureRange([
        uiStore.displayTemperature(space.temperature_lower_bound),
        uiStore.displayTemperature(space.temperature_upper_bound),
      ]);
      updateHumidityRange([
        space.humidity_lower_bound,
        space.humidity_upper_bound,
      ]);
      updateVpdRange([
        space.vpd_lower_bound,
        space.vpd_upper_bound,
      ]);
      setTemperatureVariability(
        uiStore.displayTemperatureDelta(space.temperature_spatial_variability)
      );
      setHumidityVariability(space.humidity_spatial_variability);
      setVpdVariability(space.vpd_spatial_variability);
    }
  };

  const updateTemperatureRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    dry.setTemperatureUpperBound(uiStore.toCelsius(upperBound));
    dry.setTemperatureLowerBound(uiStore.toCelsius(lowerBound));
    setTemperatureRange(range);
  };

  const updateMoistureTarget = (value: string) => {
    const val =
    Number.parseFloat(
      Number.parseFloat(value).toFixed(1)
    ) || 0;
    dry.setMoistureTarget(val);
    setMoistureTarget(val);
    const val1 = moistureTarget;
  };

  const updateHumidityRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    dry.setHumidityUpperBound(upperBound);
    dry.setHumidityLowerBound(lowerBound);
    setHumidityRange(range);
  };

  const updateVpdRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    dry.setVpdUpperBound(upperBound);
    dry.setVpdLowerBound(lowerBound);
    setVpdRange(range);
  };

  const { dryStore } = useRootStore();
  const { fetchDries } = dryStore;
  const onSave = () => {
    const errors = validateDryForm(dry);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSaveClicked(true);
      dry
        .save()
        .then((resp) => {
          fetchDries();

          if (resp.status === 200 || resp.status === 201) {
            navigate("/");
          }
          setSaveClicked(false);
        })
        .catch((e) => setSaveClicked(false));
    }
  };

  return (
    <MainContentView>
      <div className="Dry-header">
        <div className="Dry-header-left">
          <TitleEditor
            title={dry.name}
            onChange={(evt) => {
              dry.setName(evt.target.value);
            }}
            value={dry.name}
            setIsEditingFromOutside={() => {}}
          />
        </div>
        {formErrors.name && <ErrorText>{formErrors.name}</ErrorText>}
      </div>
      <div className="Dry-info-section">
        <div className="Dry-info-row">
          <div className="Dry-info-key">Lot Number:</div>
          <StringEditor
            text={dry.lot_number || "Enter Lot Number"}
            onChange={(evt) => {
              dry.setLotNum(evt.target.value);
            }}
            value={dry.lot_number || ""}
          />
        </div>
        {formErrors.lot_number && (
          <ErrorText>{formErrors.lot_number}</ErrorText>
        )}
        {dry.id && (
          <>
            {" "}
            <div className="Dry-info-row">
              <div className="Dry-info-key">
                Dry Weight (<b>{uiStore.preferredWeightUnit}</b>):
              </div>
              <NumberEditor
                text={
                  dry.dry_weight
                    ? uiStore.displayWeight(dry.dry_weight)
                    : "Enter Dry Weight"
                }
                onChange={(val) => {
                  dry.setDryWeight(uiStore.toKilogram(val));
                }}
                value={parseFloat(uiStore.displayWeight(dry.dry_weight || 0))}
              />
            </div>
            {formErrors.dry_weight && (
              <ErrorText>{formErrors.dry_weight}</ErrorText>
            )}
          </>
        )}

        <div className="Dry-info-row">
          <div className="Dry-info-key">
            Wet Weight (<b>{uiStore.preferredWeightUnit}</b>):
          </div>
          <NumberEditor
            text={
              dry.wet_weight
                ? uiStore.displayWeight(dry.wet_weight)
                : "Enter Wet Weight"
            }
            onChange={(val) => {
              dry.setWetWeight(uiStore.toKilogram(val));
            }}
            value={parseFloat(uiStore.displayWeight(dry.wet_weight || 0))}
          />
        </div>
        {formErrors.wet_weight && (
          <ErrorText>{formErrors.wet_weight}</ErrorText>
        )}
      </div>

      {dry.actionStatus !== "COMPLETED" && (
        <div className="Dry-info-section">
          <div className="Dry-info-row">
            <div className="Dry-info-key">Strain:</div>
            <Select
              defaultValue={strainDefault}
              onChange={(strain) => {
                if (strain) {
                  changeStrain(strain.value);
                }
              }}
              options={strainOpts}
              className="UpdateDry-dropdown"
              placeholder="Select strain name here"
              menuPortalTarget={document.body}
              styles={customSelectStyles}
            />
          </div>
          {formErrors.strain_id && (
            <ErrorText>{formErrors.strain_id}</ErrorText>
          )}
          <br />
          <div className="Dry-info-row">
            <div className="Dry-info-key">Space:</div>
            <Select
              defaultValue={spaceDefault}
              onChange={(space) => {
                if (space) {
                  changeSpace(space.value);
                }
              }}
              options={spaceOpts}
              className="UpdateDry-dropdown"
              menuPortalTarget={document.body}
              placeholder="Select space name here"
              styles={customSelectStyles}
            />
          </div>
          {formErrors.space_id && <ErrorText>{formErrors.space_id}</ErrorText>}
        </div>
      )}
      {dry.actionStatus !== "COMPLETED" && dry.strain && dry.space && (
        <div>
        {dry.strain.ideal_dry_data.time_elapsed_hrs.length > 0 && moistureTarget ? (
            <>     
                <div className="flex items-center space-x-2">
                    <h2>Ideal Moisture Curve from Strain: {dry.strain.name} </h2>
                    <QuestionMarkPopup text="This ideal moisture curve can be used as a guide when you are drying this strain. \nIt will appear on the 'Data History' plot once you start the dry. \nClick for more information." url="https://support.growvera.com/ideal-moisture-curves"/>
                </div>
                <div key={"idealprofile" + dry.strain.id + moistureTarget} className="IdealDryChart-chart">
                <IdealDryChart data={dry.strain.ideal_dry_data} dryName={dry.strain.name} targetMoisture={moistureTarget}/>
                </div>
            </>
            ) : (
            <div className="flex items-center space-x-2">
                <h2>No Ideal Moisture Curve: Edit the Strain to Select One </h2>
                <QuestionMarkPopup text="Ideal moisture curves are set in the Strain page. \nAfter you set one, the ideal moisture curve will appear as a guide when you dry that particular strain. \nClick for more information." url="https://support.growvera.com/ideal-moisture-curves"/>
            </div>
            )}
        </div>
      )}
      {/* {dry.actionStatus !== "COMPLETED" && (
        <div>
          <h2 className="title">Add Sensor Packs</h2>
          <div className="Dry-info-row">
            <div className="Dry-info-key">Add Sensor Packs by ID</div>
            <Select
              onChange={(sensorPack) => {
                if (sensorPack) {
                  dry.addSensorPack(sensorPack.value);
                }
              }}
              value={null}
              options={sensorPackOpts}
              className="UpdateDry-dropdown"
              placeholder="Add sensor pack here"
            />
          </div>
          <div className="UpdateDry-enrolled-sensors">
            <div className="Dry-info-key">Enrolled sensor packs</div>
            <div className="UpdateDry-enrolled-sensor-items">
              {enrolledSensorPacks}
            </div>
          </div>
        </div>
      )} */}
      {dry.actionStatus !== "COMPLETED" && dry.strain && dry.space && (
        <div>
          <h2 className="title">Alerting Ranges</h2>
          <p>
            Sensor measurements outside these thresholds will trigger an alert.
          </p>
          <div className="Strain-info-section">
            <div className="Strain-info-row">
              <div className="Strain-info-key">Target Moisture Content:</div>
              <div>
                <input
                  className="Strain-moisture-input"
                  type="number"
                  value={moistureTarget}
                  onChange={(evt) => {
                    updateMoistureTarget(evt.target.value);
                  }}
                />
                %
              </div>
              {formErrors.moisture_target && (
                <ErrorText>{formErrors.moisture_target}</ErrorText>
              )}
            </div>
          </div>
          {/* <p className="UpdateDry-strain-defaults">
            Strain default: {dry.strain.moisture_target}% target moisture
          </p>
          <div className="Strain-info-section">
            <div className="Strain-info-row">
              <div className="Strain-info-key">Moisture Variability:</div>
              <div>
                <input
                  className="Strain-moisture-input"
                  type="number"
                  value={moistureVariability}
                  onChange={(evt) => {
                    const val = Number.parseInt(evt.target.value) || "";
                    const dryVal = val || 0;
                    setMoistureVariability(val);
                    dry.setMoistureSpatialVariability(dryVal);
                  }}
                />
                %
              </div>
              {formErrors.moisture_spatial_variability && (
                <ErrorText>{formErrors.moisture_spatial_variability}</ErrorText>
              )}
            </div>
          </div>
          <p className="UpdateDry-strain-defaults">
            Strain default: {dry.strain.moisture_spatial_variability}%
            variability
          </p> */}
          <div>
            <Slider
              value={temperatureRange}
              title="Temperature"
              unitIndicator={`${uiStore.preferredTemperatureUnit}`}
              onChange={(range) => updateTemperatureRange(range)}
              minDistance={5}
              min={uiStore.displayTemperature(new CelsiusTemperature(0))}
              max={uiStore.displayTemperature(new CelsiusTemperature(60))}
            />
          </div>
          <p className="UpdateDry-space-defaults">
            Space default: temperature between&nbsp;
            {uiStore.displayTemperatureString(
              dry.space?.temperature_lower_bound,
              2
            )}{" "}
            -{" "}
            {uiStore.displayTemperatureString(
              dry.space?.temperature_upper_bound,
              2
            )}
          </p>
          {/* <div className="Space-info-section">
            <div className="Space-info-row">
              <div className="Space-info-key">Temperature Variability:</div>
              <div>
                <input
                  className="Space-Temperature-input"
                  type="number"
                  value={temperatureVariability}
                  onChange={(evt) => {
                    const val = Number.parseInt(evt.target.value) || "";
                    const dryVal = val || 0;
                    setTemperatureVariability(val);
                    dry.setTemperatureSpatialVariability(dryVal);
                  }}
                />
                {uiStore.preferredTemperatureUnit}
              </div>
              {formErrors.temperature_spatial_variability && (
                <ErrorText>
                  {formErrors.temperature_spatial_variability}
                </ErrorText>
              )}
            </div>
          </div> */}
          {/* <p className="UpdateDry-space-defaults">
            Space default:{" "}
            {uiStore.displayTemperatureDeltaString(
              dry.space.temperature_spatial_variability,
              2
            )}{" "}
            variability
          </p> */}
          <div>
            <Slider
              value={humidityRange}
              title="Humidity"
              unitIndicator="%"
              minDistance={5}
              onChange={(range) => updateHumidityRange(range)}
            />
          </div>
          <p className="UpdateDry-strain-defaults">
            Space default: humidity between&nbsp;
            {dry.space?.humidity_lower_bound}% -{" "}
            {dry.space?.humidity_upper_bound}%
          </p>
          <div>
            <Slider
              value={vpdRange}
              title="VPD"
              unitIndicator=" kPa"
              onChange={(range) => updateVpdRange(range)}
              minDistance={0.05}
              min={0}
              max={3}
              step={0.05}
            />
          </div>
          <p className="UpdateDry-strain-defaults">
            Space default: VPD between&nbsp;
            {dry.space?.vpd_lower_bound} kPa -{" "}
            {dry.space?.vpd_upper_bound} kPa
          </p>
          {/* <div className="Strain-info-section">
            <div className="Strain-info-row">
              <div className="Strain-info-key">Humidity Variability:</div>
              <div>
                <input
                  className="Strain-moisture-input"
                  type="number"
                  value={humidityVariability}
                  onChange={(evt) => {
                    const val = Number.parseInt(evt.target.value) || "";
                    const dryVal = val || 0;
                    setHumidityVariability(val);
                    dry.setHumiditySpatialVariability(dryVal);
                  }}
                />
                %
              </div>
              {formErrors.humidity_spatial_variability && (
                <ErrorText>{formErrors.humidity_spatial_variability}</ErrorText>
              )}
            </div>
          </div> */}
          {/* <p className="UpdateDry-strain-defaults">
            Space default: {dry.space.humidity_spatial_variability}% variability
          </p> */}
        </div>
      )}
      <div className="flex flex-col md:flex-row">
        <div className="UpdateDry-button">
          <Button onPress={onSave} disabled={saveClicked}>
            {saveClicked
              ? "SAVING..."
              : dry.id
              ? "UPDATE DRY"
              : "CREATE NEW DRY"}
          </Button>
        </div>
        {!saveClicked && (
          <div className="UpdateDry-button">
            <CancelButton
              onCancel={() => {
                dry.discardChanges();
                navigate(-1);
              }}
            />
          </div>
        )}
      </div>
      {Object.keys(formErrors).length > 0 && (
        <div>
          <ErrorText>Check for errors in the fields above</ErrorText>
        </div>
      )}
    </MainContentView>
  );
});

export default UpdateDry;
