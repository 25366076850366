import React from "react";
import "./GatewayTile.css";
import { useNavigate } from "react-router-dom";
import { Gateway } from "../stores/GatewayStore";

interface GatewayTileProps {
  gateway: Gateway;
}

const GoodIndicator = ({ is_good }: { is_good: boolean }) => {
  const is_good_style = {
    display: "inline-block",
    backgroundColor: "#35B44C",
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    justifyContent: "center",
    marginTop: "10px",
  };

  const not_is_good_style = {
    display: "inline-block",
    backgroundColor: "crimson",
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    justifyContent: "center",
    marginTop: "10px",
  };
  return (
    <>
      <div
        className="GoodIndicator"
        style={is_good ? is_good_style : not_is_good_style}
      ></div>
    </>
  );
};

function GatewayTile(props: GatewayTileProps) {
  const { gateway } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/settings/gateway/${gateway?.id}`)}
      className="GatewayTile"
    >
      <h4>{gateway.id}</h4>
      <h4>{gateway.alias}</h4>
      <GoodIndicator is_good={gateway.online} />
    </div>
  );
}

export default GatewayTile;
