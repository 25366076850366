import { makeAutoObservable } from "mobx";
import { CelsiusTemperature, TemperatureUnitType, WeightUnitType} from "../types";
import RootStore from "./RootStore";

class UIStore {
  preferredTemperatureUnit: TemperatureUnitType = "°F";
  preferredWeightUnit: WeightUnitType = "kg"
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  private isWeightUnitType(value: any): value is WeightUnitType {
    return value === "kg" || value === "lb";
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const storedTemp = window.localStorage.getItem("preferredTemperatureUnit");
    if (storedTemp === "°F" || storedTemp === "°C") {
      this.preferredTemperatureUnit = storedTemp;
    }
    const storedWeight = window.localStorage.getItem("preferredWeightUnit");
    if (storedWeight && this.isWeightUnitType(storedWeight)) {
      this.preferredWeightUnit = storedWeight;
    }
    makeAutoObservable(this, {
      toCelsius: false,
      toCelsiusDelta: false,
      //displayTemperature: false,
      displayTemperatureString: false,
    });
    this.loaded = true;
    this.hasError = false;
  }

  setPreferredTemperatureUnit(unit: TemperatureUnitType) {
    window.localStorage.setItem("preferredTemperatureUnit", unit);
    this.preferredTemperatureUnit = unit;
  }

  setPreferredWeightUnit(unit: WeightUnitType) {
    window.localStorage.setItem("preferredWeightUnit", unit);
    this.preferredWeightUnit = unit;
  }

  displayWeight(weight: number) {
    if (this.preferredWeightUnit === "lb") {
      return (weight * 2.20462).toFixed(2)
    }
    return weight.toFixed(2);
  }

  toKilogram(weight: number): number {
    if (this.preferredWeightUnit === "lb") {
      return weight / 2.20462
    }
    return weight;
  }

  toCelsius(degrees: number) {
    if (this.preferredTemperatureUnit === "°F") {
      return ((degrees - 32) * 5) / 9;
    }
    return degrees;
  }

  // For things like variability which use an absolute number of degrees,
  // rather than an actual temperature reading
  toCelsiusDelta(degrees: number) {
    if (this.preferredTemperatureUnit === "°F") {
      return (degrees * 5) / 9;
    }
    return degrees;
  }

  displayTemperature(degreesCelsius: CelsiusTemperature) {
    if (this.preferredTemperatureUnit === "°F") {
      return (degreesCelsius.degrees * 9) / 5 + 32;
    }
    return degreesCelsius.degrees;
  }

  // For things like variability which use an absolute number of degrees,
  // rather than an actual temperature reading
  displayTemperatureDelta(degreesCelsius: CelsiusTemperature) {
    if (this.preferredTemperatureUnit === "°F") {
      return (degreesCelsius.degrees * 9) / 5;
    }
    return degreesCelsius.degrees;
  }

  displayTemperatureString(
    degreesCelsius: CelsiusTemperature,
    decimalDigits = 2
  ) {
    const numberPart = this.displayTemperature(degreesCelsius);
    const roundedNumberPart =
      Math.round(10 ** decimalDigits * numberPart) / 10 ** decimalDigits;
    return `${roundedNumberPart}${this.preferredTemperatureUnit}`;
  }

  displayTemperatureDeltaString(
    degreesCelsius: CelsiusTemperature,
    decimalDigits = 2
  ) {
    const numberPart = this.displayTemperatureDelta(degreesCelsius);
    const roundedNumberPart =
      Math.round(10 ** decimalDigits * numberPart) / 10 ** decimalDigits;
    return `${roundedNumberPart}${this.preferredTemperatureUnit}`;
  }
}

export default UIStore;
