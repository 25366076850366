import { makeAutoObservable } from "mobx";
import { UserServerJSONType } from "../types/UserType";
import RootStore from "./RootStore";
import HTTPClient from "../HTTPClient";

class UserStore {
  users = new Map<string, User>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

  updateUserFromServer(json: UserServerJSONType) {
    let user = this.users.get(json.id);
    if (!user) {
      user = new User(this);
    }
    user.updateFromJSON(json);
    this.users.set(user.id, user);
  }

  createUser() {
    return new User(this);
  }

  fetchUsers() {
    const userStore = this;
    HTTPClient.apiGet("/users")
      .then((res) => {
        console.log();
        res.data.forEach(function (d: UserServerJSONType) {
            if (d.email.includes("+demo@growvera.com")){
                userStore.updateUserFromServer(d);
            }
            else if (!d.email.includes("@growvera.com")){
                userStore.updateUserFromServer(d);
            }
        });

        this.setLoaded();
      })
      .catch((err) => {
        if(err.response!.status >= 500) {
          this.setHasError();
        }
        console.log(err, "in fetch users");
      });
  }
}

export class User {
  constructor(parentStore: UserStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: UserStore;
  id = "";
  given_name = "";
  family_name = "";
  phone_number = "";
  email = "";
  role = "USER";
  static roleOpts: string[] = ["USER", "ADMIN"];
  persistedJSON = "";

  setGivenName(given_name: string) {
    this.given_name = given_name;
  }

  setFamilyName(family_name: string) {
    this.family_name = family_name;
  }

  setEmail(email: string) {
    this.email = email;
  }
  setRole(role: string){
    this.role = role
  }

  updateFromJSON(json: UserServerJSONType) {
    this.id = json.id;
    this.given_name = json.given_name;
    this.family_name = json.family_name;
    this.email = json.email;
    this.phone_number = json.phone_number;
    this.role = json.role;
    this.persistedJSON = this.asJSON;
  }

  discardChanges() {
    if (this.persistedJSON) {
      const json = JSON.parse(this.persistedJSON);
      this.updateFromJSON(json);
    }
  }

  save() {
    const data = JSON.parse(this.asJSON);
    if (!this.id) {
      delete data.id;
    }
    const payload = JSON.stringify(data);
    const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    const updateUrl = this.id ? `/users/${this.id}` : "/users";
    return updateMethod(updateUrl, payload)
      .then((resp) => {
        this.store.updateUserFromServer(resp.data);
        return resp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  remove() {
    return HTTPClient.apiDelete(`/users/${this.id}`)
      .then((resp) => {
        console.log("resp", resp);
        if (resp.status === 204) {
          this.store.users.delete(this.id);
        }
        return resp;
      })
      .catch((err) => {
        console.log(err, "in remove ");
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  get asJSON() {
    const jsonData: UserServerJSONType = {
      id: this.id,
      given_name: this.given_name,
      family_name: this.family_name,
      email: this.email,
      phone_number: this.phone_number,
      role: this.role
    };
    return JSON.stringify(jsonData);
  }
}

export default UserStore;
