import React from 'react';

interface DateTimeProps {
  iso8601?: string;
}

function DateTime(props: DateTimeProps) {
  if (!props.iso8601) {
    return <span>&nbsp;-&nbsp;</span>;
  }
  const jsDate = new Date(props.iso8601);
  return (
    <span>
      {`${jsDate.toLocaleDateString()} ${jsDate.toLocaleTimeString()}`}
    </span>
  );
}

export default DateTime;
