import React, { useState } from 'react';
import './QuestionMarkPopup.css';

interface QuestionMarkPopupProps {
    text: string;
    url: string;
}

const QuestionMarkPopup = (props: QuestionMarkPopupProps) => {
    const [showPopup, setShowPopup] = useState(false);

    const textWithNewLines = props.text.replace(/\\n/g, '\n');
    const lines = textWithNewLines.split('\n');

    return (
        <div className="question-mark-container">
            <a
                href={props.url}
                target="_blank"
                className="question-mark"
                onMouseEnter={() => setShowPopup(true)}
                onMouseLeave={() => setShowPopup(false)}
            >
                ?
            </a>
            {showPopup && (
                <div className="popup">
                    {lines.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default QuestionMarkPopup;
