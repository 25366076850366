import React from 'react';
import './OrderSensorsView.css';

import MainContentView from '../MainContentView';

function OrderSensorsView() {
  return (
    <div className="OrderSensorsView">
      <MainContentView>
        <h1 className="title">Order Sensors</h1>
        <p>
          Need more sensors? Get in touch with us and we'll send them to you and
          get them added to your account.
        </p>
        <h2 className="title">Contact</h2>
        <p>
          Email:&nbsp;
          <a
            href="mailto:support@growvera.com?subject=Order Sensors"
            target="_blank"
            rel="noreferrer"
          >
            support@growvera.com
          </a>
        </p>
        <p>
          Phone: <a href="tel:+1-505-336-1223">+1-505-336-1223</a>
        </p>
      </MainContentView>
    </div>
  );
}

export default OrderSensorsView;
