import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "./Dry.css";
import "react-datepicker/dist/react-datepicker.css";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { useNavigate, Link } from "react-router-dom";
import { useRootStore } from "../StoreProvider";
import BackButton from "../BackButton";
import Button from "../Button";
import DateTime from "../DateTime";
import DeleteWithConfirmation from "../DeleteWithConfirmation";
import EndDryWithConfirmation from "../EndDryWithConfirmation";
import {DryTHChart, DryVPDChart} from "./DryChart";
import { Dry as DryType, Note } from "../stores/DryStore";
import { DryIndividualSensorData } from "../types";
import Edit from "../Edit";
import MainContentView from "../MainContentView";
import StatBox from "../StatBox";
import SensorChart from "./SensorChart";
import DryNoteView from "./DryNoteView";
import CreateNoteButton from "./CreateNoteButton";
import {
  IndividualTimeseriesHumidityChart,
  IndividualTimeseriesTemperatureChart,
  IndividualTimeseriesVpdChart,
} from "./IndividualTimeseriesStatsChart";
import ErrorText from "../ErrorText";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


type filterDateTimeType = Date | null;

interface DryProps {
  dry: DryType;
}

const headers = [
  { label: "Avg. Humidity", key: "avg_humidity" },
  { label: "Avg. Moisture", key: "avg_moisture" },
  { label: "Avg. Temperature", key: "avg_temperature" },
  { label: "Timestamp", key: "timestamp" },
];

// const tempStore: {ts: Array<number>, th: Array<number>} = {ts: [], th: []}

const Dry = observer(function Dry(props: DryProps) {
  const { dry } = props;
  const { uiStore, sensorStore, dryStore } = useRootStore();

  const { fetchDries } = dryStore;
  const sensorPacksAvailable = sensorStore.sensorPacksAvailable;

  const [individualDataLoaded, setIndividualDataLoaded] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [endProgress, setEndProgress] = useState(false);
  const navigate = useNavigate();
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [chart, setChart] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [showSensorTable, setShowSensorTable] = useState(false);
  const [levelFilter, setLevelFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [sensorAssignError, setSensorAssignError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [assignedSensors, setAssignedSensors] = useState<any>([]);

  
  const [noteChart, setNoteChart] = useState([]);
  const [noteLoading, setNoteLoading] = useState(false);
  const [noteDataLoaded, setNoteDataLoaded] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [notePaginationIndex, setNotePaginationIndex] = useState(0);

  const [filterStartDateTime, setFilterStartDateTime] =
    useState<filterDateTimeType>(null);
  const [filterEndDateTime, setFilterEndDateTime] =
    useState<filterDateTimeType>(null);

    const [refresh, setRefresh] = useState(false)

  const lastSyncDate = dry.lastSyncUnixTimestamp
    ? new Date(dry.lastSyncUnixTimestamp)
    : null;

  const newNote = new Note(dryStore);
  newNote.setType("GENERAL");

    const chartNotes = dry.noteData.map(noteData => new Note(dryStore, noteData.id, noteData.creator, noteData.type, noteData.timestamp, noteData.details));

    const createNote = () => {
        setNoteLoading(true);
        dry.addNote(newNote.asJSON).then((resp) => {
          newNote.id = resp.data.id
          setNotes((prevNotes) => [...prevNotes, newNote]);
          setNoteLoading(false);
        });
    };

  
    const updateNote = (note: Note) => {
        setNoteLoading(true);

        dry.updateNote(note.id, note.asJSON).then((resp) => {
            note.id = resp.data.id;
            note.details = resp.data.details;
            note.timestamp = resp.data.timestamp;
            note.creator = resp.data.creator;
            const updatedNotes = notes.map(n => (n.id === note.id ? note : n));
            setNotes(updatedNotes);
            setNoteLoading(false);
            setNoteLoading(false);
          });
    };

  
    const deleteNote = (note: Note) => {
        dry.deleteNote(note.id);
        setNoteLoading(true);
        const delete_id = note.id;
        const updatedNotes = notes.filter(note => note.id !== delete_id )
        setNotes(updatedNotes);
        setNoteLoading(false);
    };

  const lastDataUpdate = lastSyncDate
    ? `Last Updated: ${lastSyncDate.toLocaleDateString()} ${lastSyncDate.toLocaleTimeString()}`
    : "DATA PENDING";

  const sensorPackOpts = sensorPacksAvailable
    .filter((s) => !dry.sensor_pack_ids?.includes(s.id))
    .map((s) => ({ label: s.id, value: s.id }));

  const enrolledSensorPacks = dry.sensorPacks ? (
    dry.sensorPacks.map((s) => (
      <div className="UpdateDry-enrolled-sensor-item" key={s?.id}>
        {s?.id}
        {s && (
          <DeleteWithConfirmation
            key={s.id}
            onDelete={() => dry.removeSensorPacks(s.id)}
            itemName={s.id}
            itemType={"Sensor Pack"}
          />
        )}
      </div>
    ))
  ) : (
    <i>None yet</i>
  );

  useEffect(() => {
    // Start the general timeseries data subscription
    dry.startSubscription();

    // Start the individual timeseries data subscription
    // if (!individualDataLoaded) {
    //   dry.startIndividualSubscription();
    //   setIndividualDataLoaded(true); // Prevent multiple subscriptions
    // }

    // Cleanup function to stop subscriptions when component unmounts or updates
    return () => {
      dry.stopSubscription();
      //   dry.stopIndividualSubscription();
    };
  }, []); // Ensure effect runs when 'dry' object or 'individualDataLoaded' state changes

  useEffect(() => {
    setRefresh((refr) => !refr)
  }, [dry.individualTimeseriesData, dry.timeseriesData, dry.noteData])

    useEffect(() => {
    if (!noteDataLoaded && !dry.noteDataLoading) {
        if (!dry.noteDataLoaded) {
            dry.fetchNoteData().then(() => {
                const new_notes = dry.noteData.map(noteData => new Note(dryStore, noteData.id, noteData.creator, noteData.type, noteData.timestamp, noteData.details));
                setNotes(new_notes);
            });
        }
        setNoteDataLoaded(true); // Prevent multiple subscriptions
    }
    return () => {
        // dry.individualTimeseriesData = ({timestamps: [], data: []});
    };
    }, [dry.noteDataLoaded, dry.noteDataLoading]);


  useEffect(() => {
    if (!individualDataLoaded && !dry.individualTimeseriesDataLoading) {
      if (!dry.individualTimeseriesDataLoaded) {
        dry.fetchIndividualTimeseriesData();
      }
      setIndividualDataLoaded(true); // Prevent multiple subscriptions
    }
    return () => {
      // dry.individualTimeseriesData = ({timestamps: [], data: []});
    };
  }, [dry.individualTimeseriesDataLoading, dry.individualTimeseriesDataLoaded]);

  const changeLevelFilter = (l: string) => {
    setLevelFilter(l);
  };
  const changeTypeFilter = (t: string) => {
    setTypeFilter(t);
  };
  const clearFilters = () => {
    setLevelFilter("all");
    setTypeFilter("all");
    setFilterStartDateTime(null);
    setFilterEndDateTime(null);
  };
  const filtersSet =
    levelFilter !== "all" ||
    typeFilter !== "all" ||
    filterStartDateTime ||
    filterEndDateTime;

  const levelFilterOpts: any = [
    { label: "all", value: "all" },
    { label: "critical", value: "critical" },
    { label: "warning", value: "warning" },
    { label: "info", value: "info" },
  ];
  const typeFilterOpts: any = [
    { label: "all", value: "all" },
    { label: "temperature", value: "temperature" },
    { label: "humidity", value: "humidity" },
    { label: "moisture", value: "moisture" },
    { label: "disconnected", value: "disconnected" },
    { label: "lowbattery", value: "lowbattery" },
  ];

  const filteredEvents = dry.eventData
    .filter((e) => levelFilter === "all" || e.level === levelFilter)
    .filter((e) => typeFilter === "all" || e.type === typeFilter)
    .filter((e) => {
      if (!filterStartDateTime) {
        return true;
      } else if (e.timestamp >= filterStartDateTime.getTime()) {
        return true;
      } else {
        return false;
      }
    })
    .filter((e) => {
      if (!filterEndDateTime) {
        return true;
      } else if (e.timestamp <= filterEndDateTime.getTime()) {
        return true;
      } else {
        return false;
      }
    });
  const sensorPackCount: number = dry.sensorPacks ? dry.sensorPacks.length : 0;

  const onStop = async () => {
    setEndProgress(true);
    await dry.stop();
    setTimeout(() => {
      navigate("/home");
    }, 5000);
  };

  const onDelete = () => {
    setDeleteInProgress(true);
    dry.remove().then((resp) => {
      if (resp.status === 204) {
        navigate("/");
      } else {
        setDeleteInProgress(false);
      }
    });
  };

  if (deleteInProgress) {
    return (
      <MainContentView>
        <h2>Deleting dry...</h2>
      </MainContentView>
    );
  }
  if (endProgress) {
    return (
      <MainContentView>
        <h2>Loading...</h2>
      </MainContentView>
    );
  }

  const onAssignmentSensors = async () => {
    let sensors: any = [];
    var updatedData: any[] = [];
    setLoading(true);
    let error = "";
    for (const id of dry.addedSensorPackIds) {
      const res = await dry.updateSensorsInDB(id);
      if (res?.data?.id) {
        sensors?.push(res?.data);
      } else {
        for (const item of dry.sensor_pack_ids) {
          const res: any = sensorStore.sensorPacks.get(item);

          if (!res) {
            error = res?.data?.message;
            break;
          }
        }
      }
    }
    setSensorAssignError(error);
    setLoading(false);
    setAssignedSensors(updatedData);
    if (error.length === 0) {
      setShowSensorTable(true);
    }
  };

  const onSensorCancel = async () => {
    for (const id of dry.addedSensorPackIds) {
      const res = await dry.removeSensorPacks(id);
    }
    setShowSensorTable(false);
  };

  const plotChartData = dry.timeseriesData?.map((row, index) => {
    if (row.avg_moisture > 60) {
      return {
        ...row,
        avg_moisture: 60,
        moisture_bound: 60,
      };
    } else {
      return { ...row, moisture_bound: 60 };
    }
  });
  const convertCtoF = (value: number) => {
    const temp = value * (9 / 5) + 32;

    return parseFloat(temp.toFixed(3));
  };

  const convertCtoFincludenull = (value: number | null) => {
    if (value === null) {
      return null;
    }
    const temp = value * (9 / 5) + 32;

    return parseFloat(temp.toFixed(3));
  };

  const plotChartDataInFhrnt = plotChartData?.map((row, index) => {
    return {
      ...row,
      avg_temperature: convertCtoF(row.avg_temperature),
    };
  });

  const plotIndChartData = dry.individualTimeseriesData;

  
  function convertPlotIndChartData(
    plotIndChartData: DryIndividualSensorData
  ): DryIndividualSensorData {
    
    // testing code 

    // function randomNumber(min: number, max: number) {
    //   return Math.floor(Math.random() * (max - min)) + min;
    // }

    // const {timestamps, data} = plotIndChartData

    // tempStore.ts.push(new Date().getTime())
    // tempStore.th.push(randomNumber(30, 60))


    // let newts = [...timestamps, ...tempStore.ts]
    // let newData = data.map((individualSensorData) => ({
    //   ...individualSensorData,
    //   temperatures: [...individualSensorData.temperatures, ...tempStore.th],
    //   humidities: [...individualSensorData.humidities, ...tempStore.th],
    // })),

    // nplotIndChartData = { timestamps: newts, data: newData }
    
    return {
      ...plotIndChartData,
      data: plotIndChartData.data.map((individualSensorData) => ({
        ...individualSensorData,
        temperatures: individualSensorData.temperatures.map(
          convertCtoFincludenull
        ),
      })),
    };
  }
  const dryChartData = uiStore.preferredTemperatureUnit?.includes("C")
    ? plotChartData
    : plotChartDataInFhrnt;

  const dryIndividualTemperatureData =
    uiStore.preferredTemperatureUnit?.includes("C")
      ? plotIndChartData
      : convertPlotIndChartData(plotIndChartData);

  const formattedData = dryChartData?.map((row) => ({
    ...row,
    timestamp: row.timestamp.toString(), // Convert the timestamp to a formatted date string
  }));
  return (
    <MainContentView>
      <BackButton to="/">Back to Dries</BackButton>
      <div className="my-2 md:flex items-center md:space-x-4">
        <div className="flex items-center space-x-2">
          <h1 className="Dry-name">{dry.name}</h1>
          <Edit to="update" />
          <DeleteWithConfirmation
            key={dry.id}
            onDelete={onDelete}
            itemName={dry.name}
            itemType={"dry"}
          />
        </div>
        <p className="">Status: {dry.actionStatus}</p>
        <p className="">{lastDataUpdate}</p>
        {sensorAssignError && <ErrorText>{sensorAssignError}</ErrorText>}
      </div>
      <div className="Dry-info-section">
        <div className="Dry-info-row">
          <div className="Dry-info-key">Start Time:</div>
          <DateTime iso8601={dry.starttime} />
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Stop Time:</div>
          <DateTime iso8601={dry.stoptime} />
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Strain:</div>
          {dry.strain?.name}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Lot Number:</div>
          {dry.lot_number || "-"}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Dry Weight (<b>{uiStore.preferredWeightUnit}</b>):</div>
          {dry.dry_weight ? uiStore.displayWeight(dry.dry_weight) : "0"}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Wet Weight (<b>{uiStore.preferredWeightUnit}</b>):</div>
          {dry.wet_weight ? uiStore.displayWeight(dry.wet_weight) : "0"}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Space:</div>
          {dry.space?.name}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Sensor Packs:</div>
          {sensorPackCount}
          &nbsp;&nbsp;
          {/* {dry.actionStatus !== "COMPLETED" && (
            <Link to="update">add sensor packs</Link>
          )} */}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Target Moisture:</div>
          {dry.moisture_target}%
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Temperature Range:</div>
          {uiStore.displayTemperatureString(
            dry.temperature_lower_bound,
            0
          )} -{" "}
          {uiStore.displayTemperatureString(dry.temperature_upper_bound, 2)}
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">Humidity Range:</div>
          {dry.humidity_lower_bound}% - {dry.humidity_upper_bound}%
        </div>
        <div className="Dry-info-row">
          <div className="Dry-info-key">VPD Range:</div>
          {dry.vpd_lower_bound} kPa - {dry.vpd_upper_bound} kPa
        </div>
      </div>

      {dry.actionStatus === "NOT_STARTED" && (
        <div>
          {showSensorTable && sensorPackCount > 0 ? (
            <div>
              <h2> Recommended Procedure </h2>
              <h3 className="title"> 1. Attach your sensors to the plants</h3>
              <h3 className="title">
                {" "}
                2. Verify your sensors are online and connected using the table
              </h3>
              <h3 className="title">
                {" "}
                3. Disable any sensors you aren't using
              </h3>
              <h3 className="title">
                {" "}
                4. Start the dry using the <i>Start Dry</i> button
              </h3>
              <p />
              <SensorChart
                sensors={assignedSensors}
                dry={dry}
                onCancelClick={onSensorCancel}
                paginationIndex={paginationIndex}
                setPaginationIndex={setPaginationIndex}
                chart={chart}
                setChart={setChart}
                displayData={displayData}
                setDisplayData={setDisplayData}
                setLoading={setEndProgress}
              />
            </div>
          ) : (
            <div>
              {/* {dry.actionStatus !== "COMPLETED" && ( */}
              <div>
                <h2 className="title">Add Sensor Packs</h2>
                <div className="Dry-info-row">
                  <div className="Dry-info-key">Add Sensor Packs by ID</div>
                  <Select
                    onChange={(sensorPack) => {
                      if (sensorPack) {
                        dry.addSensorPack(sensorPack.value);
                      }
                    }}
                    value={null}
                    options={sensorPackOpts}
                    className="UpdateDry-dropdown"
                    placeholder="Add sensor pack here"
                  />
                </div>
                <div className="UpdateDry-enrolled-sensors">
                  <div className="Dry-info-key">Enrolled sensor packs</div>
                  <div className="UpdateDry-enrolled-sensor-items">
                    {enrolledSensorPacks}
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          )}
        </div>
      )}

      <div className="Dry-button-container">
        {!showSensorTable && dry.actionStatus === "NOT_STARTED" && (
          <Button
            disabled={sensorPackCount === 0 || loading}
            onPress={() => onAssignmentSensors()}
          >
            Next
          </Button>
        )}
        {/* {dry.actionStatus === "NOT_STARTED" &&
          showSensorTable &&
          sensorPackCount !== 0 && (
            <div className="action-buttons">
              <CancelButton onCancel={() => setShowSensorTable(false)} />

              <Button
                disabled={sensorPackCount === 0}
                onPress={() => {
                  dry.start();
                  setTimeout(() => {
                    navigate("/home");
                  });
                }}
              >
                START DRY
              </Button>
            </div>
          )} */}
        {dry.actionStatus === "NOT_STARTED" && sensorPackCount === 0 && (
          <p>
            <i>No sensor packs, add sensor packs to start dry</i>
          </p>
        )}
        {dry.actionStatus === "ONGOING" && (
          <EndDryWithConfirmation
            key={dry.id}
            onStop={
              () => onStop()
              // .then((res) => {
              //   fetchDries();
              //   navigate("/home");
              // })
              // .catch((err) => console.log(err))
            }
            itemName={dry.name}
            itemType={"dry"}
          />
        )}
      </div>
      {dry.actionStatus === "ONGOING" && (
        <div className="Dry-stat-boxes">
          <StatBox title="Moisture">
            {dry.moisture
              ? dry.moisture > 60
                ? `> 60%`
                : `${dry.moisture}%`
              : "DATA PENDING"}
          </StatBox>
          <StatBox title="Temperature">
            {dry.temperature
              ? `${uiStore.displayTemperatureString(dry.temperature, 2)}`
              : "DATA PENDING"}
          </StatBox>
          <StatBox title="Humidity">
            {dry.humidity ? `${dry.humidity}%` : "DATA PENDING"}
          </StatBox>
          <StatBox title="VPD">
            {dry.vpd ? `${dry.vpd} kPa` : "DATA PENDING"}
          </StatBox>
        </div>
      )}
      {dry.actionStatus !== "NOT_STARTED" && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0.5em",
            }}
          >
            <h2 style={{ margin: 0 }}>Data History</h2>
          </div>

          {dry.timeseriesData.length > 0 && dry.noteDataLoaded && noteDataLoaded && !noteLoading? (
            <div key={"timeseries" + dry.id + noteDataLoaded + noteLoading} className="Dry-chart">
              <DryTHChart data={dryChartData} dryName={dry.name} idealData={dry.ideal_dry_data} dryStartTime={dry.starttime} moistureTarget={dry.moisture_target} noteData={chartNotes} noteDataLoaded={noteDataLoaded} newNote={newNote} addNote={createNote}/>
            </div>
          ) : (
            <div>
                {noteLoading ? (<i>Loading...</i>): (<i>No timeseries data yet</i>)}
            </div>
          )}

          <div style={{ marginTop: "2rem" }}>
            <h2>Individual Sensor Data</h2>

            {individualDataLoaded && dry.individualTimeseriesDataLoaded ? (
              <>
                {dry.individualTimeseriesData.timestamps.length > 0 ? (
                <Tabs>
                  <TabList>
                    <Tab>Temperature</Tab>
                    <Tab>Humidity</Tab>
                    <Tab>VPD</Tab>
                  </TabList>
                  <TabPanel>
                    <IndividualTimeseriesTemperatureChart
                      data={dryIndividualTemperatureData}
                      dryName={dry.name}
                    />
                  </TabPanel>
                  <TabPanel>
                    <IndividualTimeseriesHumidityChart
                      data={dry.individualTimeseriesData}
                      dryName={dry.name}
                    />
                  </TabPanel>
                  <TabPanel>
                    <IndividualTimeseriesVpdChart
                      data={dry.individualTimeseriesData}
                      dryName={dry.name}
                    />
                  </TabPanel>
                </Tabs>
                ) : (
                  <p>
                    <i>No individual sensor timeseries data available yet</i>
                  </p>
                )}
              </>
            ) : (
              <div>Loading Individual Sensor Data...</div>
            )}
          </div>
        </div>
      )}
      {dry.actionStatus === "ONGOING" && (
        <div>
          {" "}
          <h2>Assigned Sensors</h2>
          <SensorChart
            dry={dry}
            paginationIndex={paginationIndex}
            setPaginationIndex={setPaginationIndex}
            chart={chart}
            setChart={setChart}
            displayData={displayData}
            setDisplayData={setDisplayData}
          />
        </div>
      )}


      {dry.actionStatus !== "NOT_STARTED" && (
        <>
        <div>
          {" "}
        <h2>Dry Notes</h2>
      </div>
        {dry.noteData.length > 0 ? (
            <div key={"notes" + dry.id+ dry.noteDataLoaded} className="Dry-note-table">
            <DryNoteView notes={chartNotes} deleteNote = {deleteNote} updateNote = {updateNote}/>
            </div>
        ) : (
            <div>
            <i>No notes</i>
            </div>
        )}
      </>
      )}
      {dry.actionStatus !== "NOT_STARTED" && (
        <div className="Dry-events-container">
          <h2>Event History</h2>
          <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-4">
            {dry.eventData.length > 0 ? (
              <div className="">
                <div className="Dry-filters-header">
                  <h3>Filters</h3>
                  {filtersSet && (
                    <div
                      className="Dry-clear-filters"
                      onClick={(evt) => clearFilters()}
                    >
                      Clear Filters
                    </div>
                  )}
                </div>
                <div className="">
                  Level:&nbsp;
                  <Select
                    onChange={(val: any) => {
                      const levelFilter = val?.value || "";
                      changeLevelFilter(levelFilter);
                    }}
                    value={levelFilterOpts.filter(
                      (opt: any) => opt.value === levelFilter
                    )}
                    options={levelFilterOpts}
                    className="Dry-dropdown"
                  />
                  Type:&nbsp;
                  <Select
                    onChange={(val: any) => {
                      const typeFilter = val?.value || "";
                      changeTypeFilter(typeFilter);
                    }}
                    value={typeFilterOpts.filter(
                      (opt: any) => opt.value === typeFilter
                    )}
                    options={typeFilterOpts}
                    className="Dry-dropdown"
                  />
                </div>
                <div className="">
                  Start:&nbsp;
                  <div className="Dry-datepicker-container">
                    <DatePicker
                      selected={filterStartDateTime}
                      onChange={(date) => setFilterStartDateTime(date)}
                      selectsStart
                      showTimeSelect
                      dateFormat="MM/d/yyyy h:mm aa"
                      placeholderText="Select start date/time..."
                    />
                  </div>
                  End:&nbsp;
                  <div className="Dry-datepicker-container">
                    <DatePicker
                      selected={filterEndDateTime}
                      onChange={(date) => setFilterEndDateTime(date)}
                      selectsEnd
                      showTimeSelect
                      dateFormat="MM/d/yyyy h:mm aa"
                      placeholderText="Select start date/time..."
                    />
                  </div>
                </div>
                <br></br>
                <div className="Dry-events-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Timestamp</th>
                        <th>Level</th>
                        <th>Type</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEvents.map((e) => (
                        <tr
                          key={e.id}
                          className={
                            e.level === "info"
                              ? "Dry-event-info"
                              : e.level === "warning"
                              ? "Dry-event-warning"
                              : "Dry-event-critical"
                          }
                        >
                          <td>
                            {new Date(e.timestamp).toLocaleTimeString()}
                            &nbsp;{new Date(e.timestamp).toDateString()}
                          </td>
                          <td>{e.level}</td>
                          <td>{e.type}</td>
                          <td>{e.details}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>
                <i>No events yet</i>
              </div>
            )}
          </div>
          <br></br>
        </div>
      )}
    </MainContentView>
  );
});

export default Dry;
