import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../StoreProvider';
import { useParams } from 'react-router-dom';
import { Dry } from '../stores/DryStore';
import UpdateDry from './UpdateDry';
import NotFound from '../NotFound';

const UpdateDryView = observer(function UpdateDryView() {
  const {
    dryStore,
    sensorStore,
    spaceStore,
    strainStore
  } = useRootStore();
  const { id } = useParams();
  const sensorPacksAvailable = sensorStore.sensorPacksAvailable;
  const spaces = Array.from(spaceStore.spaces.values());
  const strains = Array.from(strainStore.strains.values());

  let dry: Dry | undefined;
  if (id) {
    dry = dryStore.userDries.get(id);
  } else {
    dry = dryStore.createDry();
  }
  if (dry === undefined) {
    return <NotFound />;
  }
  return (

    <div>
      <UpdateDry
        dry={dry}
        sensorPacks={sensorPacksAvailable}
        spaces={spaces}
        strains={strains}
      />
    </div>
  );
});

export default UpdateDryView;
