interface AmplifyConfigType {
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_mandatory_sign_in: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
}

let AmplifyConfig: AmplifyConfigType, APIEndpoint: string;

if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  AmplifyConfig = {
    aws_cognito_identity_pool_id:
      "us-east-1:2337e49a-adce-4373-ad19-a25ced8fb7d0",
    aws_cognito_region: "us-east-1",
    aws_mandatory_sign_in: "enable",
    aws_user_pools_id: "us-east-1_drzKNWIFh",
    aws_user_pools_web_client_id: "7hsojo2491135sojmk6rmb68sd",
  };
  // APIEndpoint = 'https://api.growvera.cloud/staging';
  APIEndpoint = "https://7bcfpzx1tc.execute-api.us-east-1.amazonaws.com/prod";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
    AmplifyConfig = {
      aws_cognito_identity_pool_id:
        	"us-east-1:dc57b6cf-4346-4a93-b01b-d583770c0922",
      aws_cognito_region: "us-east-1",
      aws_mandatory_sign_in: "enable",
      aws_user_pools_id: "us-east-1_BCsLAThoC",
      aws_user_pools_web_client_id: "5rm7pktu69h6uqkk1qi761vm0m",
    };
    APIEndpoint = "https://api.growvera.com";
} else if (process.env.REACT_APP_ENVIRONMENT === "qa") {
    AmplifyConfig = {
      aws_cognito_identity_pool_id:
        	"us-east-1:dc57b6cf-4346-4a93-b01b-d583770c0922",
      aws_cognito_region: "us-east-1",
      aws_mandatory_sign_in: "enable",
      aws_user_pools_id: "us-east-1_BCsLAThoC",
      aws_user_pools_web_client_id: "5rm7pktu69h6uqkk1qi761vm0m",
    };
    APIEndpoint = "https://re5tbyew2e.execute-api.us-east-1.amazonaws.com/prod";
  } else {
    AmplifyConfig = {
      aws_cognito_identity_pool_id:
        	"us-east-1:dc57b6cf-4346-4a93-b01b-d583770c0922",
      aws_cognito_region: "us-east-1",
      aws_mandatory_sign_in: "enable",
      aws_user_pools_id: "us-east-1_BCsLAThoC",
      aws_user_pools_web_client_id: "5rm7pktu69h6uqkk1qi761vm0m",
    };
    APIEndpoint = "https://api.growvera.com";
}

export { AmplifyConfig, APIEndpoint };
