import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useMatch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./TopBar.css";

import AlertBar from "./AlertBar";
import logo from "./logowhitestacked.png";
import AccountDisplay from "./AccountDisplay";
import { useRootStore } from "./StoreProvider";

function TopBar() {
  const location = useLocation();
  const navigation = useNavigate();

  const { dryStore, accountStore } = useRootStore();
  const currentAccount = accountStore.currentAccount;
  const match = useMatch("/home");
  const message = match
    ? "Please review the dries on this page that need attention"
    : "Tap here to address current alerts";

  useEffect(() => {
    if (location.pathname === "/") {
      navigation("/home");
    }
  }, [location, navigation]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  if (!currentAccount) {
    return null;
  }

  return (
    <header className="">
      <div className="flex px-4 md:px-8 justify-between items-center">
        <div className="text-white flex justify-start">
          <Link to="/">
            <img src={logo} className="object-contain h-48 w-48" alt="Home" />
          </Link>
        </div>
        <div className="hidden justify-between items-center lg:flex">
          <NavButton to="/home" text="Home" />
          <NavButton to="/reports" text="Completed" />
          <NavButton to="/settings" text="Settings" />
          <NavButton to="/team" text="My Team" />
          <div className="TopBar-navbutton">
            <a href="https://support.growvera.com">
              <h3 className="text-xl sm:text-sm xl:text-2xl">Help</h3>
            </a>
          </div>
          {/* <NavButton to="/feedback" text="Feedback" /> */}
        </div>
        <div className="hidden lg:flex">
            <AccountDisplay account={currentAccount} />
          </div>
        <div className="flex lg:hidden">
          <button className="text-white" onClick={toggleMenu}>
            {/* Hamburger menu icon */}
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu Overlay */}
      {isOpen && (
        <div className="lg:hidden fixed inset-0 bg-blue bg-opacity-95 z-50">
          <div className="flex flex-col items-center justify-center h-full">
          <div className="absolute top-4 left-4" onClick={closeMenu}>
              <AccountDisplay account={currentAccount} />
            </div>
            <button
              className="text-white absolute top-4 right-4"
              onClick={closeMenu}
            >
              {/* Close menu icon */}
              <svg
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <NavButton to="/home" text="Home" onClick={closeMenu} />
            <br />
            <NavButton to="/reports" text="Completed" onClick={closeMenu}/>
            <br />
            <NavButton to="/settings" text="Settings" onClick={closeMenu}/>
            <br />
            <NavButton to="/team" text="My Team" onClick={closeMenu}/>
            <br />
            <div className="TopBar-navbutton" onClick={closeMenu}>
              <a href="https://support.growvera.com">
                <h3 className="text-xl sm:text-sm xl:text-2xl">Help</h3>
              </a>
            </div>
            <br />
            {/* <NavButton to="/feedback" text="Feedback" onClick={closeMenu}/> */}
          </div>
        </div>
      )}
    </header>
  );
  // return (
  //   <div>
  //     <header className="TopBar">
  //       <div className="TopBar-left">
  //         <Link to="/">
  //           <img src={logo} className="TopBar-logo" alt="Home" />
  //         </Link>
  //         <NavButton to="/home" text="Home" />
  //         <NavButton to="/reports" text="Completed" />
  //         <NavButton to="/settings" text="Settings" />
  //         <NavButton to="/team" text="My Team" />
  //         <div className="TopBar-navbutton">
  //           <a href="https://support.growvera.com">
  //               <h1>Help</h1>
  //           </a>
  //         </div>
  //         <NavButton to="/feedback" text="Feedback" />
  //       </div>
  //       <div className="TopBar-right">
  //         <AccountDisplay account={currentAccount} />
  //       </div>
  //     </header>
  //     <AlertBar
  //       message={message}
  //       visible={!!dryStore.showNeedsAttention}
  //     />
  //   </div>
  // );
}

function NavButton(props: any) {
  return (
    <div className="TopBar-navbutton">
      <NavLink to={props.to} onClick={props.onClick}>
        <h3 className="text-xl sm:text-sm xl:text-2xl">{props.text}</h3>
      </NavLink>
    </div>
  );
}

export default TopBar;
