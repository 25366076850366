import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../StoreProvider';
import { useParams } from 'react-router-dom';
import { Strain } from '../stores/StrainStore';
import UpdateStrain from './UpdateStrain';
import NotFound from '../NotFound';
import { Dry } from '../stores/DryStore';

const UpdateStrainView = observer(function UpdateStrainView() {
    const {
      dryStore,
      strainStore
    } = useRootStore();

  const {idealDryCandidates } = dryStore;
  const { id } = useParams();

  let strain: Strain | undefined;
  if (id) {
    strain = strainStore.strains.get(id);
  } else {
    strain = strainStore.createStrain();
  }
  if (strain === undefined) {
    return <NotFound />;
  }

  return (
    <div>
      <UpdateStrain strain={strain} candidate_dries = {idealDryCandidates} />
    </div>
  );
});

export default UpdateStrainView;
