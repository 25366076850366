import {
    AnnotationHoverEventArgs,
    CustomAnnotation,
    EHorizontalAnchorPoint,
    EVerticalAnchorPoint,
    TextAnnotation,
    Thickness,
    CursorModifier,
    AnnotationClickEventArgs,
    AnnotationHoverModifier
} from "scichart";

class CustomNoteAnnotation extends CustomAnnotation {
    public timestamp: number;
    public details: string;
    private toolTipAnnotation: TextAnnotation | undefined;
    private cursorModifier: CursorModifier | undefined;

    public onHover (args?: AnnotationHoverEventArgs){
        // document.documentElement.style.cursor =  args.isHovered ? 'pointer' : 'auto';
        if (!args) {
            this.parentSurface.domCanvas2D.style.cursor = 'auto'
        } else {
            this.parentSurface.domCanvas2D.style.cursor = args.isHovered ? 'pointer' : 'auto'
        }
    }


    public onClick(args?: AnnotationClickEventArgs) {

        if (!this.toolTipAnnotation) {
            this.toolTipAnnotation = new TextAnnotation({
                yCoordShift:15,
                xCoordShift:0,
                x1: this.timestamp,
                y1: this.y1,
                verticalAnchorPoint: EVerticalAnchorPoint.Top,
                horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
                background: "#00bac2",
                textColor: "white",
                padding: new Thickness(5, 5, 5, 5),
                fontSize: 16,
                fontFamily:"Montserrat-SemiBold",
                text: this.details,
            });
            this.parentSurface.annotations.add(this.toolTipAnnotation);
        } else if ( this.toolTipAnnotation) {
            this.parentSurface.annotations.remove(this.toolTipAnnotation, true);
            this.toolTipAnnotation = undefined;
        }
    }

    public constructor(timestamp: number, details: string) {
        super({
            x1: timestamp,
            y1: 75, // Assuming a middle position on the Y axis, adjust as needed
            verticalAnchorPoint: EVerticalAnchorPoint.Center,
            horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        });
        this.timestamp = timestamp;
        this.details = details;
        // this.onHover = this.onHover.bind(this);
        // this.hovered.subscribe(this.onHover);

        this.onClick = this.onClick.bind(this);
        this.clicked.subscribe(this.onClick);
        // this.parentSurface.chartModifiers.add(new AnnotationHoverModifier());
        this.onHover = this.onHover.bind(this);
        this.hovered.subscribe(this.onHover);
        this.svgString = this.getSvgString();
    }

    public getSvgString(): string {
        return   `<?xml version="1.0" encoding="UTF-8"?>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="scale(0.35)">
            <path d="m40 18.062v30h-20l30 33.875 30-33.875h-20v-30z" fill="#00bac2"/>
        </g>
    </svg>`;
    }
}

export default CustomNoteAnnotation;
