import React from 'react';
import './Toggle.css';

type onChangeType = (evt: React.ChangeEvent<HTMLInputElement>) => void;
interface ToggleProps {
  checked: boolean;
  onChange: onChangeType;
}

function Toggle(props: ToggleProps) {
  return (
		<label className="Toggle">
			<input type="checkbox" checked={props.checked} onChange={props.onChange} />
			<span className="Toggle-slider" />
		</label>
  );
}

export default Toggle;
