import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import "./SettingsView.css";
import { useRootStore } from "../StoreProvider";
import AddNewTile from "../AddNewTile";
import SpaceTile from "../home/SpaceTile";
import StrainTile from "../home/StrainTile";
import SensorPackTile from "../home/SensorPackTile";
import search from "../Search";
import GatewayTile from "../home/GatewayTile";
import MainContentView from "../MainContentView";

interface SettingViewSectionProps {
  title: string;
  value: string;
  setValue: React.Dispatch<string>;
}

const SettingViewSection = (props: SettingViewSectionProps) => {
  const { title, value, setValue } = props;
  return (
    <>
      <div className="flex uppercase flex-col sm:flex-row justify-center sm:justify-start sm:items-center">
        <p className="text-2xl font-bold py-4 sm:pr-12 sm:py-8">{title}</p>
        <div className="py-2">
          <input
            placeholder="Type to filter..."
            onChange={(evt) => setValue(evt.target.value)}
            value={value}
          />
        </div>
      </div>
    </>
  );
};

const SettingsView = observer(function SettingsView() {
  const { dryStore, spaceStore, strainStore, sensorStore, gatewayStore } = useRootStore();
  const [spaceSearchTerm, setSpaceSearchTerm] = useState("");
  const [strainSearchTerm, setStrainSearchTerm] = useState("");
  const [sensorPackSearchTerm, setSensorPackSearchTerm] = useState("");
  const [gatewaySearchTerm, setGatewaySearchTerm] = useState("");

  const [spaceTiles, setSpaceTiles] = useState<any>([]);
  const [strainTiles, setStrainTiles] = useState<any>([]);
  const [sensorPackAvailableTiles, setSensorPackAvailableTiles] = useState<any>(
    []
  );
  const [sensorPacksInUse, setSensorPacksInUse] = useState<any>([]);
  const [gatewayTiles, setGatewayTiles] = useState<any>([]);

  const intervalDryIdRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const intervalGatewayIdRef = useRef<ReturnType<typeof setInterval> | undefined>();

  useEffect(() => {
    const spaces = Array.from(spaceStore.spaces.values());
    const filteredSpaces = search(spaces, "name", spaceSearchTerm);
    setSpaceTiles(filteredSpaces);

    const strains = Array.from(strainStore.strains.values());
    const filteredStrains = search(strains, "name", strainSearchTerm);
    setStrainTiles(filteredStrains);
    strainStore.fetchStrains();
  }, [spaceSearchTerm, strainSearchTerm]);

  const dryPolling = useCallback(() => {
    const sensorPacksAvailable = Array.from(
      sensorStore.sensorPacksAvailable.values()
    );
    const filteredSensorPacksAvailable = search(
      sensorPacksAvailable,
      "id",
      sensorPackSearchTerm
    );
    setSensorPackAvailableTiles(filteredSensorPacksAvailable);

    const sensorPacksInUseVar = Array.from(
      sensorStore.sensorPacksInUse.values()
    );
    const filteredSensorPacksInUse = search(
      sensorPacksInUseVar,
      "id",
      sensorPackSearchTerm
    );
    setSensorPacksInUse(filteredSensorPacksInUse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorPackSearchTerm]);

  const {idealDryCandidates } = dryStore;

  useEffect(() => {
    dryPolling();
    intervalDryIdRef.current = setInterval(dryPolling, 10000);
    return () => {
      clearInterval(intervalDryIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorPackSearchTerm]);

  const gatewayPolling = useCallback(() => {
    const gateways = Array.from(gatewayStore.gateways.values());
    const filteredGateways = search(gateways, "alias", gatewaySearchTerm);
    setGatewayTiles(filteredGateways);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaySearchTerm]);

  useEffect(() => {
    gatewayPolling();
    intervalGatewayIdRef.current = setInterval(gatewayPolling, 30000);
    return () => {
      clearInterval(intervalGatewayIdRef.current);
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaySearchTerm]);

  return (
    <MainContentView>
      <SettingViewSection
        title="Spaces"
        value={spaceSearchTerm}
        setValue={setSpaceSearchTerm}
      />
      <div className="flex overflow-x-auto mb-8">
        <AddNewTile text="Create New Space" path="/settings/spaces/new" />
        {spaceTiles?.map((space: any) => (
          <SpaceTile key={space.id} space={space} />
        ))}
      </div>
      <SettingViewSection
        title="Strains"
        value={strainSearchTerm}
        setValue={setStrainSearchTerm}
      />
      <div className="flex overflow-x-auto mb-8">
        <AddNewTile text="Create New Strain" path="/settings/strains/new" />
        {strainTiles?.map((strain: any) => (
          <StrainTile key={strain.id} strain={strain} />
        ))}
      </div>
      <SettingViewSection
        title="Sensor Packs"
        value={sensorPackSearchTerm}
        setValue={setSensorPackSearchTerm}
      />
      <h3>Available</h3>
      <div className="flex overflow-x-auto mb-8">
        <AddNewTile text="Order Sensors" path="/settings/sensors/order" />
        {sensorPackAvailableTiles?.map((sensorPack: any) => (
          <SensorPackTile key={sensorPack.id} sensorPack={sensorPack} />
        ))}
      </div>
      {sensorPacksInUse?.length > 0 && (
        <>
          <h3>Assigned</h3>
          <div className="flex overflow-x-auto mb-8">
            {sensorPacksInUse?.map((sensorPack: any) => (
              <SensorPackTile key={sensorPack.id} sensorPack={sensorPack} />
            ))}
          </div>
        </>
      )}
      <SettingViewSection
        title="Gateways"
        value={gatewaySearchTerm}
        setValue={setGatewaySearchTerm}
      />
      <div className="flex overflow-x-auto mb-8">
        {/* <AddNewTile text="Create New Space" path="/settings/spaces/new" /> */}
        {gatewayTiles?.map((gateway: any) => (
          <GatewayTile key={gateway.id} gateway={gateway} />
        ))}
      </div>
    </MainContentView>
  );
});

export default SettingsView;
