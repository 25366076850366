import React from "react";
import "./DryTile.css";
import { useNavigate } from "react-router-dom";

import { Dry } from "../stores/DryStore";

interface DryTileProps {
  dry: Dry;
}

function DryTile(props: DryTileProps) {
  const { dry } = props;
  const uiStore = dry.store.rootStore.uiStore;
  const navigate = useNavigate();
  let className = "DryTile";

  if (dry.needsattention) {
    className = className + " DryTile-needs-attention";
  }

  if (dry.actionStatus === "NOT_STARTED") {
    className = className + " DryTile-not-started";
  } else if (dry.actionStatus === "ONGOING") {
    className = className + " DryTile-ongoing";
  } else if (dry.actionStatus === "COMPLETED") {
    className = className + " DryTile-completed";
  }

  const hasAllData = dry.moisture && dry.humidity && dry.temperature;
  return (
    <div
      onClick={() => navigate(`/home/dries/${dry.id}`)}
      className={className}
    >
      <h2>{dry.name}</h2>
      {dry.strain ? 
        <h4>{dry.strain.name}</h4> :
        <></>
      }
      {dry.actionStatus !== "NOT_STARTED" && (
        <>
          {hasAllData ? (
            <>
              <h1>
                {dry.moisture
                  ? dry.moisture > 60
                    ? "> 60"
                    : dry.moisture
                  : ""}
                %
              </h1>
              <h5 className="special-h5">
                <div className="flex-item">{dry.humidity?.toFixed(0)}%RH</div>
                <div className="flex-item">
                 {dry.temperature &&
                  uiStore.displayTemperatureString(dry.temperature, 0)}
                </div>
                  {dry.vpd ? 
                    <div className="flex-item"> {dry.vpd.toFixed(1)}kPa</div>: 
                    <></>  
                    }
              </h5>
            </>
          ) : (
            <h3>DATA PENDING</h3>
          )}
        </>
      )}
    </div>
  );
}

export default DryTile;
