import React, { useCallback, useEffect } from "react";

import moment from "moment";
import { DryIndividualSensorData } from "../types";
import {
  EAutoRange,
  EAxisAlignment,
  EExecuteOn,
  ELegendOrientation,
  ELineDrawMode,
  FastLineRenderableSeries,
  IRenderableSeries,
  LegendModifier,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  RubberBandXyZoomModifier,
  SciChartOverview,
  SciChartSurface,
  VisibleRangeChangedArgs,
  XyDataSeries,
  XySeriesInfo,
  ZoomExtentsModifier,
  ZoomPanModifier,
} from "scichart";

import { downloadCSV, formatDate, capCase } from "../Utils";
import useScreenSize from "../screenSizeHook";

interface IndividualTimeseriesStatsChart {
  data: DryIndividualSensorData;
  dryName: string;
}
export interface OneLine {
  name: string;
  data: (number | null)[];
}

interface ICSVData {
  data: DryIndividualSensorData;
  series: Array<OneLine>;
  chartName: string;
}

const colors_ser: string[] = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
];

export const divElementId = "chart";
export const divOverviewId = "overview";

const handleCSVExport = (csvData: ICSVData | undefined) => {
  if (!!!csvData) return;

  const headers = ["Timestamp ISO8601 Format"];
  let csvContent = "";
  const { series, data, chartName } = csvData;

  for (let i = 0; i < series.length; i++) {
    headers.push(series[i].name);
  }
  csvContent += headers.join(",") + "\n";

  // Construct rows
  for (let i = 0; i < data.timestamps.length; i++) {
    let row = [];
    for (let j = 0; j < series.length; j++) {
      row.push(series[j].data[i] || ""); // Push data or empty string if data is missing
    }
    csvContent += formatDate(data.timestamps[i]) + "," + row.join(",") + "\n";
  }
  downloadCSV(csvContent, chartName + ".csv");
};

const surfaceChart =
  (type: string, series: Array<OneLine>, data: DryIndividualSensorData, isMobile: boolean) =>
  async () => {
    // Create a SciChartSurface
    const { wasmContext, sciChartSurface } = await SciChartSurface.create(
      `${type + divElementId}`
    );

    // Create an XAxis and YAxis
    const xAxis = new NumericAxis(wasmContext);

    const xAxisFormatter = (diffInDays: number) => (dataValue: number) => {
      return diffInDays < 3
        ? moment(dataValue).format("D MMM YY hh:mm A").valueOf()
        : moment(dataValue).format("D MMM YY").valueOf();
    };

    xAxis?.visibleRangeChanged.subscribe(
      (data: VisibleRangeChangedArgs | undefined) => {
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        const diffInDays = Math.floor(
          (data?.visibleRange.diff || 0) / millisecondsInDay
        );
        xAxis.labelProvider.formatLabel = xAxisFormatter(diffInDays);
      }
    );

    xAxis.labelProvider.formatCursorLabel = (dataValue) => {
      return moment(dataValue).format("MM/DD/YY hh:mm A").valueOf();
    };

    let axisTitle = undefined;

    if (type === "humidity") axisTitle = "Relative Humidity (%)";
    if (type === "temperature") axisTitle = "Temperature";
    if (type === "vpd") axisTitle = "VPD (kPa)";

    const yAxis = new NumericAxis(wasmContext, {
      axisAlignment: EAxisAlignment.Left,
      autoRange: EAutoRange.Always,
      growBy: new NumberRange(0.1, 0.2),
      labelPrecision: 1,
      axisTitle: axisTitle,
      axisTitleStyle: {
        fontSize: 16,
      },
    });

    sciChartSurface.xAxes.add(xAxis);
    sciChartSurface.yAxes.add(yAxis);

    let moving: Map<string, number> = new Map();
    const tooltipDataTemplate = (seriesInfo: XySeriesInfo): string[] => {
      const valuesWithLabels: string[] = [];

      let yVal = "N/A ";
      let prevNum = moving.get(seriesInfo.seriesName) || seriesInfo.xValue;
      if (prevNum !== seriesInfo.xValue) {
        yVal = seriesInfo.formattedYValue;
      }

      // VPD Chart needs to display values with 2 decimal places.
      if (type === 'vpd'){
        yVal = seriesInfo.yValue.toFixed(2);
      }

      moving.set(seriesInfo.seriesName, seriesInfo.xValue);

      let fmt = ''
      if(isMobile) {
        fmt = `${yVal} `
      } else {
        fmt = ` ${seriesInfo.seriesName}: ${yVal} `
      }
      valuesWithLabels.push(fmt);
      return valuesWithLabels;
    };

    for (let i = 0; i < series.length; i++) {
      // Create arrays of x, y values (just arrays of numbers)
      const { xValues, yValues } = {
        xValues: data.timestamps,
        yValues: series[i].data.map((d) => d || Number.NaN),
      };

      const dataSeries = new XyDataSeries(wasmContext, {
        xValues,
        yValues,
        dataSeriesName: `${series[i].name}`,
        containsNaN: true,
        isSorted: true,
      });

      
      const lineSeries = new FastLineRenderableSeries(wasmContext, {
        dataSeries,
        stroke: colors_ser[i % colors_ser.length],
        strokeThickness: 3,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
        //   pointMarker: new EllipsePointMarker(wasmContext, {
        //     width: 4,
        //     height: 4,
        //     fill: colors_ser[i % colors_ser.length],
        //     strokeThickness: 0
        // }),
      });

      sciChartSurface.renderableSeries.add(lineSeries);
    }
    sciChartSurface.background = "#002a40";

    const overview = await SciChartOverview.create(
      sciChartSurface,
      `${type + divOverviewId}`
    );

    const modifierGroup = type;
    const rolloverModifier = new RolloverModifier({
      modifierGroup,
      showAxisLabel: true,
      snapToDataPoint: true,
      // showXLine: true,
      // showYLine: false,
      rolloverLineStrokeDashArray: [2, 2],
      showTooltip: true,
      placementDivId: `tooltip-${type}`,
      tooltipDataTemplate,
      //   tooltipLegendTemplate: tooltipLegendTemplate,
    });

    sciChartSurface.chartModifiers.add(
      new ZoomExtentsModifier({ modifierGroup }),
      new MouseWheelZoomModifier({ modifierGroup }),
      new ZoomPanModifier({ modifierGroup }),
      new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
      rolloverModifier,
      new LegendModifier({
        modifierGroup,
        showCheckboxes: true,
        placementDivId: `legend-${type}`,
        orientation: ELegendOrientation.Horizontal,
        backgroundColor: "#002a40",
        isCheckedChangedCallback: (
          series: IRenderableSeries,
          isChecked: boolean
        ) => {
          if (!isChecked) {
            series.rolloverModifierProps.tooltipColor = `${series.rolloverModifierProps.tooltipColor}11`;
            series.rolloverModifierProps.tooltipTextColor = `${series.rolloverModifierProps.tooltipTextColor}22`;
          } else {
            series.rolloverModifierProps.tooltipColor =
              series.rolloverModifierProps.tooltipColor.substring(0, 7);
            series.rolloverModifierProps.tooltipTextColor =
              series.rolloverModifierProps.tooltipTextColor.substring(0, 7);
          }
        },
      })
    );

    sciChartSurface.zoomExtents();

    return { wasmContext, sciChartSurface, overview };
  };

function IndividualTimeseriesTemperatureChart(
  props: IndividualTimeseriesStatsChart
) {
  const { isMobile } = useScreenSize()
  const sciChartSurfaceRef = React.useRef<SciChartSurface>();
  const sciChartOverviewRef = React.useRef<SciChartOverview>();
  const seriesRef = React.useRef<ICSVData>();

  const type = "temperature";
  const unsubscribe = React.useCallback(() => {
    sciChartSurfaceRef.current?.xAxes
      .get(0)
      ?.visibleRangeChanged.unsubscribeAll();
    sciChartSurfaceRef.current?.delete();
    sciChartOverviewRef.current?.delete();
    sciChartSurfaceRef.current = undefined;
    sciChartOverviewRef.current = undefined;
  }, []);

  const { data, dryName } = props;

  const getData = useCallback(() : Array<OneLine> => {

    let mappedData = data.data.map(
      ({ id, alias, sensor_pack_id, temperatures }) => ({
        name: alias && sensor_pack_id ? `${sensor_pack_id} - ${alias}` : id,
        data: temperatures,
      })
    );

    // Sort the mapped data by sensor_pack_id and then by alias
    mappedData.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  
    return mappedData
  }, [data])

  React.useEffect(() => {

    let series = getData()

    const fetchData = async () => {
      const { sciChartSurface, overview } = await surfaceChart(
        type,
        series,
        data,
        isMobile
      )();

      sciChartSurfaceRef.current = sciChartSurface;
      sciChartOverviewRef.current = overview;

      const now = moment(Date.now()).toISOString();
      const chartName =
        dryName.replace(/\s+/g, "") + "_" + now + "_" + capCase(type) + "_Data";
      seriesRef.current = { data, series, chartName };
    };
    fetchData();

    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        unsubscribe();
        return;
      }

      // else postpone deletion
      fetchData().then(() => {
        unsubscribe();
      });
    };
  }, []);


  React.useEffect(() => {

    if (sciChartSurfaceRef.current) {
      let series = getData()
      const redneredSeries = sciChartSurfaceRef.current.renderableSeries
      for (let i = 0; i < redneredSeries.size(); i++) {
        let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries

        const { xValues, yValues } = {
          xValues: data.timestamps.slice(xyDs.count()),
          yValues: series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()).map((d) => d || Number.NaN) || []
        };

        // console.log(xyDs.count(), xValues, yValues)
        
        xValues.forEach((x, ind) => {
          xyDs.append(x, yValues[ind] || Number.NaN) 
        })
        
      }
    }
  }, [data])

  return (
    <div className="Dry-chart">
      {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
      <button
        className="export-button"
        onClick={() => handleCSVExport(seriesRef.current)}
      >
        Export CSV
      </button>
      <div className="w-full flex">
        <div className="w-5/6">
          <div
            id={`${type + divElementId}`}
            style={{ width: "100%", height: 370 }}
          />
          <div
            id={`${type + divOverviewId}`}
            style={{ width: "100%", height: 100 }}
          />
          <div style={{ width: "100%" }}>
            <div
              id={`legend-${type}`}
              style={{ height: 100, overflowY: "auto" }}
            />
          </div>
        </div>
        <div className="w-1/6">
          <div
            id={`tooltip-${type}`}
            style={{ height: 600, overflowY: "auto" }}
          />
        </div>
      </div>
    </div>
  );
}

function IndividualTimeseriesHumidityChart(
  props: IndividualTimeseriesStatsChart
) {

  const {isMobile} = useScreenSize()
  const sciChartSurfaceRef = React.useRef<SciChartSurface>();
  const sciChartOverviewRef = React.useRef<SciChartOverview>();
  const seriesRef = React.useRef<ICSVData>();

  const type = "humidity";
  const unsubscribe = React.useCallback(() => {
    sciChartSurfaceRef.current?.xAxes
      .get(0)
      ?.visibleRangeChanged.unsubscribeAll();
    sciChartSurfaceRef.current?.delete();
    sciChartOverviewRef.current?.delete();
    sciChartSurfaceRef.current = undefined;
    sciChartOverviewRef.current = undefined;
  }, []);

  const { data, dryName } = props;

  const getData = useCallback(() : Array<OneLine> => {
    let mappedData = data.data.map(({ id, alias, sensor_pack_id, humidities }) => ({
      name: alias && sensor_pack_id ? `${sensor_pack_id} - ${alias}` : id,
      data: humidities,
    }));

    // Sort the mapped data by sensor_pack_id and then by alias
    mappedData.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return mappedData;
  }, [data])

  React.useEffect(() => {

    let series = getData()
    const fetchData = async () => {
      const { sciChartSurface, overview } = await surfaceChart(
        type,
        series,
        data,
        isMobile
      )();
      sciChartSurfaceRef.current = sciChartSurface;
      sciChartOverviewRef.current = overview;

      const now = moment(Date.now()).toISOString();
      const chartName =
        dryName.replace(/\s+/g, "") + "_" + now + "_" + capCase(type) + "_Data";
      seriesRef.current = { data, series, chartName };
    };
    fetchData();

    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        unsubscribe();
        return;
      }

      // else postpone deletion
      fetchData().then(() => {
        unsubscribe();
      });
    };
  }, [unsubscribe]);



  React.useEffect(() => {

    if (sciChartSurfaceRef.current) {
      let series = getData()
      
      const redneredSeries = sciChartSurfaceRef.current.renderableSeries
      for (let i = 0; i < redneredSeries.size(); i++) {
        let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries

        const { xValues, yValues } = {
          xValues: data.timestamps.slice(xyDs.count()),
          yValues: series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()).map((d) => d || Number.NaN) || []
        };
        
        // console.log(xyDs.count(), xValues, yValues)
        
        xValues.forEach((x, ind) => {
          xyDs.append(x, yValues[ind] || Number.NaN) 
        })
        
      }
    }
  }, [data])

  return (
    <div className="Dry-chart">
      <button
        className="export-button"
        onClick={() => handleCSVExport(seriesRef.current)}
      >
        Export CSV
      </button>
      {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
      <div className="w-full flex">
        <div className="w-5/6">
          <div
            id={`${type + divElementId}`}
            style={{ width: "100%", height: 370 }}
          />
          <div
            id={`${type + divOverviewId}`}
            style={{ width: "100%", height: 100 }}
          />
          <div style={{ width: "100%" }}>
            <div
              id={`legend-${type}`}
              style={{ height: 100, overflowY: "auto" }}
            />
          </div>
        </div>
        <div className="w-1/6">
          <div
            id={`tooltip-${type}`}
            style={{ height: 600, overflowY: "auto" }}
          />
        </div>
      </div>
    </div>
  );
}


function IndividualTimeseriesVpdChart(
    props: IndividualTimeseriesStatsChart
  ) {
  
    const {isMobile} = useScreenSize()
    const sciChartSurfaceRef = React.useRef<SciChartSurface>();
    const sciChartOverviewRef = React.useRef<SciChartOverview>();
    const seriesRef = React.useRef<ICSVData>();
  
    const type = "vpd";
    const unsubscribe = React.useCallback(() => {
      sciChartSurfaceRef.current?.xAxes
        .get(0)
        ?.visibleRangeChanged.unsubscribeAll();
      sciChartSurfaceRef.current?.delete();
      sciChartOverviewRef.current?.delete();
      sciChartSurfaceRef.current = undefined;
      sciChartOverviewRef.current = undefined;
    }, []);
  
    const { data, dryName } = props;
  
    const getData = useCallback(() : Array<OneLine> => {
      let mappedData = data.data.map(({ id, alias, sensor_pack_id, vpds }) => ({
        name: alias && sensor_pack_id ? `${sensor_pack_id} - ${alias}` : id,
        data: vpds,
      }));
  
      // Sort the mapped data by sensor_pack_id and then by alias
      mappedData.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
  
      return mappedData;
    }, [data])
  
    React.useEffect(() => {
  
      let series = getData()
      const fetchData = async () => {
        const { sciChartSurface, overview } = await surfaceChart(
          type,
          series,
          data,
          isMobile
        )();
        sciChartSurfaceRef.current = sciChartSurface;
        sciChartOverviewRef.current = overview;
  
        const now = moment(Date.now()).toISOString();
        const chartName =
          dryName.replace(/\s+/g, "") + "_" + now + "_" + capCase(type) + "_Data";
        seriesRef.current = { data, series, chartName };
      };
      fetchData();
  
      // Delete sciChartSurface on unmount component to prevent memory leak
      return () => {
        // check if chart is already initialized
        if (sciChartSurfaceRef.current) {
          unsubscribe();
          return;
        }
  
        // else postpone deletion
        fetchData().then(() => {
          unsubscribe();
        });
      };
    }, [unsubscribe]);
  
  
  
    React.useEffect(() => {
  
      if (sciChartSurfaceRef.current) {
        let series = getData()
        
        const redneredSeries = sciChartSurfaceRef.current.renderableSeries
        for (let i = 0; i < redneredSeries.size(); i++) {
          let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries
  
          const { xValues, yValues } = {
            xValues: data.timestamps.slice(xyDs.count()),
            yValues: series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()).map((d) => d || Number.NaN) || []
          };
          
          // console.log(xyDs.count(), xValues, yValues)
          
          xValues.forEach((x, ind) => {
            xyDs.append(x, yValues[ind] || Number.NaN) 
          })
          
        }
      }
    }, [data])
  
    return (
      <div className="Dry-chart">
        <button
          className="export-button"
          onClick={() => handleCSVExport(seriesRef.current)}
        >
          Export CSV
        </button>
        {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
        <div className="w-full flex">
          <div className="w-5/6">
            <div
              id={`${type + divElementId}`}
              style={{ width: "100%", height: 370 }}
            />
            <div
              id={`${type + divOverviewId}`}
              style={{ width: "100%", height: 100 }}
            />
            <div style={{ width: "100%" }}>
              <div
                id={`legend-${type}`}
                style={{ height: 100, overflowY: "auto" }}
              />
            </div>
          </div>
          <div className="w-1/6">
            <div
              id={`tooltip-${type}`}
              style={{ height: 600, overflowY: "auto" }}
            />
          </div>
        </div>
      </div>
    );
  }

export {
  IndividualTimeseriesHumidityChart,
  IndividualTimeseriesTemperatureChart,
  IndividualTimeseriesVpdChart,
};
