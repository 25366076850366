import React from 'react';
import './SpaceTile.css';
import { useNavigate } from 'react-router-dom';

import { Space } from '../stores/SpaceStore';

interface SpaceTileProps {
  space: Space;
}

function SpaceTile(props: SpaceTileProps) {
  const { space } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/settings/spaces/${space.id}`)}
      className="SpaceTile"
    >
      <h4>{space.name}</h4>
    </div>
  );
}

export default SpaceTile;
