import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  to?: string;
  onPress?: (event: React.SyntheticEvent) => void;
  className?: string;
  onMouseDown?: any;
}

function Button(props: ButtonProps) {
  const className = `${props.disabled ? "Button-disabled" : "Button"} ${
    props.className || ""
  }`;

  if (props.disabled) {
    const disabledClassName = `${className}Button-disabled`;
    return <div className={disabledClassName}>{props.children}</div>;
  }

  if (props.to) {
    return (
      <Link className="Button-link" to={props.to}>
        <div className={className}>{props.children}</div>
      </Link>
    );
  }
  return (
    <div
      onClick={props.onPress}
      className={className}
      onMouseDown={props?.onMouseDown}
    >
      {props.children}
    </div>
  );
}

export default Button;
