import { makeAutoObservable } from 'mobx';
import {
  CognitoAttributesType,
  AccountAlertLevelType,
  AccountReceiveEmailSettingType,
  AccountReceiveTextSettingType,
  AccountRoleType,
  AccountJSONType
} from '../types';
import { Auth } from 'aws-amplify';
import RootStore from './RootStore';

class AccountStore {
  accounts = new Map<string, Account>();
  // AWS Cognito calls a user's id "sub" for some reason so we match it here
  currentAccountSub = 'unset';
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }
  
  updateAccountFromCognitoUser(cognito_attrs: CognitoAttributesType) {
    // Begin data munging for TS-unfriendly names
    const output: AccountJSONType = {
      phoneNumber: cognito_attrs.phone_number,
      email: cognito_attrs.email,
      role: cognito_attrs['custom:role'],
      alertLevel: cognito_attrs['custom:alert_level'],
      receiveEmail: cognito_attrs['custom:receive_email'],
      receiveText: cognito_attrs['custom:receive_text'],
      givenName: cognito_attrs.given_name,
      familyName: cognito_attrs.family_name,
      sub: cognito_attrs.sub,
    };


    let user = this.accounts.get(output.sub);
    if (!user) {
      user = new Account(this);
    }
    user.updateFromJSON(output);
    this.accounts.set(user.sub, user);
    this.setLoaded();
  }

  createAccount() {
    return new Account(this);
  }

  createCurrentAccount(cognitoAttributes: CognitoAttributesType) {
    this.updateAccountFromCognitoUser(cognitoAttributes);
    this.currentAccountSub = cognitoAttributes.sub;
  }

  get currentAccount() {
    return this.accounts.get(this.currentAccountSub);
  }
}

export class Account {
  constructor(parentStore: AccountStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: AccountStore;
  // 0 -> INFO, 1 -> WARNING, 2 -> CRITICAL
  alertLevel: AccountAlertLevelType = '0';
  email = '';
  familyName = '';
  givenName = '';
  persistedJSON = '';
  phoneNumber = '';
  receiveEmail: AccountReceiveEmailSettingType = "true";
  receiveText: AccountReceiveTextSettingType = "true";
  role: AccountRoleType = 'USER';
  sub = '';

  setAlertLevel(alertLevel: AccountAlertLevelType) {
    this.alertLevel = alertLevel;
  }

  setFamilyName(name: string) {
    this.familyName = name;
  }

  setGivenName(name: string) {
    this.givenName = name;
  }

  setPhoneNumber(num: string) {
    this.phoneNumber = num;
  }

  setReceiveEmail(setting: AccountReceiveEmailSettingType) {
    this.receiveEmail = setting;
  }

  setReceiveText(setting: AccountReceiveTextSettingType) {
    this.receiveText = setting;
  }

  discardChanges() {
    if (this.persistedJSON) {
      const json = JSON.parse(this.persistedJSON);
      this.updateFromJSON(json);
    }
  }

  updateFromJSON(json: AccountJSONType) {
    this.alertLevel = json.alertLevel;
    this.email = json.email;
    this.familyName = json.familyName;
    this.givenName = json.givenName;
    this.phoneNumber = json.phoneNumber;
    this.receiveEmail = json.receiveEmail;
    this.receiveText = json.receiveText;
    this.role = json.role;
    this.sub = json.sub;

    this.persistedJSON = this.asJSON;
  }

  get name() {
    return `${this.givenName} ${this.familyName}`;
  }

  get alertLevelName() {
    if (this.alertLevel === '0') {
      return 'INFO';
    } else if (this.alertLevel === '1') {
      return 'WARNING';
    } else {
      return 'CRITICAL';
    }
  }

  get dirty() {
    return this.asJSON !== this.persistedJSON;
  }

  async save() {
    const user = await Auth.currentAuthenticatedUser();
    const resp = await Auth.updateUserAttributes(user, {
      phone_number: this.phoneNumber,
      'custom:alert_level': this.alertLevel,
      'custom:receive_email': this.receiveEmail,
      'custom:receive_text': this.receiveText,
      given_name: this.givenName,
      family_name: this.familyName,
    }).catch(error => {
      return error;
    });
    if (resp === 'SUCCESS') {
      this.persistedJSON = this.asJSON;
    };
    return resp;
  }

  remove() {
    this.store.accounts.delete(this.sub);
  }

  get asJSON() {
    const jsonData: AccountJSONType = {
      alertLevel: this.alertLevel,
      email: this.email,
      familyName: this.familyName,
      givenName: this.givenName,
      role: this.role,
      sub: this.sub,
      phoneNumber: this.phoneNumber,
      receiveEmail: this.receiveEmail,
      receiveText: this.receiveText,
    };
    return JSON.stringify(jsonData);
  }
}

export default AccountStore;
