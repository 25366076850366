import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { observer } from "mobx-react";
import { useRootStore } from "./StoreProvider";
import './ErrorPage.css';
import Modal from 'react-modal';
import Button from './Button';
import { ReactNode } from 'react'

interface ErrorPageProps {
  children : ReactNode
}

const ErrorPage = observer(function ErrorPage(props : ErrorPageProps) {
  const rootStore = useRootStore();

  if (rootStore.hasError) {
    return (
      <div>
      <h2 className="ErrorPage">
        {rootStore.errorPageMessage}
      </h2>
    </div>
    );
  }
  else {
    return <>{props.children}</>;
  }
});

export default ErrorPage;
