import React, { useCallback } from "react";
import "./IdealDryChart.css";
import { IdealDryData } from "../types";
import moment from "moment";
import useScreenSize from "../screenSizeHook";

import {
  EAutoRange,
  EAxisAlignment,
  ELegendOrientation,
  ELineDrawMode,
  FastBandRenderableSeries,
  LegendModifier,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  SciChartOverview,
  SciChartSurface,
  VisibleRangeChangedArgs,
  XyDataSeries,
  XyyDataSeries,
  FastLineRenderableSeries,
  IRenderableSeries,
  ZoomExtentsModifier,
  ZoomPanModifier,
} from "scichart";

interface IdealDryChartProps {
  data: IdealDryData;
  dryName: string;
  targetMoisture: number;
}


export const divElementId = "chart";
export const divOverviewId = "overview";

const surfaceChart = (type: string, series: Array<any>, tm_series: Array<any>, isMobile: boolean) => async () => {
  // Create a SciChartSurface
  const { wasmContext, sciChartSurface } = await SciChartSurface.create(
    `${type + divElementId}`
  );

  const xAxis = new NumericAxis(wasmContext, {
    axisTitle: "Time Since Start",
  });

  const xAxisFormatter = (diffInHours: number) => (dataValue: number) => {
    const num_hrs = dataValue * 24;
    const rounded_num_hours = Math.floor(dataValue) * 24;
    const rounded_days = Math.floor(dataValue);
    if (Math.round(num_hrs - rounded_num_hours) === 0){
        return dataValue < 2
        ? `${num_hrs.toFixed(0)} Hours`
        : `${rounded_days.toFixed(0)} Days`;
    } else {
        return dataValue < 2
        ? `${num_hrs.toFixed(0)} Hours`
        : `${rounded_days.toFixed(0)} Days, ${(num_hrs - rounded_num_hours).toFixed(0)} Hours`;
    }
  };

  xAxis.visibleRangeChanged.subscribe(
    (data: VisibleRangeChangedArgs | undefined) => {
      const diffInHours = Math.floor(data?.visibleRange.diff || 0);
      xAxis.labelProvider.formatLabel = xAxisFormatter(diffInHours);
    }
  );

  xAxis.labelProvider.formatCursorLabel = (dataValue) => {
    const num_hrs = dataValue * 24;
    const rounded_num_hours = Math.floor(dataValue) * 24;
    const rounded_days = Math.floor(dataValue);
    if (Math.round(num_hrs - rounded_num_hours) === 0){
        return dataValue < 2
        ? `${num_hrs.toFixed(0)} Hours`
        : `${rounded_days.toFixed(0)} Days`;
    } else {
        return dataValue < 2
        ? `${num_hrs.toFixed(0)} Hours`
        : `${rounded_days.toFixed(0)} Days, ${(Math.floor(num_hrs - rounded_num_hours)).toFixed(0)} Hours`;
    }
  };

  const yAxis = new NumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Left,
    growBy: new NumberRange(0.1, 0.2),
    labelPrecision: 1,
    autoRange: EAutoRange.Always,
    axisTitle: "Moisture (%)"
  });

  sciChartSurface.xAxes.add(xAxis);
  sciChartSurface.yAxes.add(yAxis);

  for (let i = 0; i < series.length; i++) {
    // Create arrays of x, y values (just arrays of numbers)
    
    const { xValues, yValues, y1Values } = {
        xValues: series[i].data.xx,
        yValues: series[i].data.y,
        y1Values: series[i].data.y1,
    };
    const bandSeries = new FastBandRenderableSeries(wasmContext, {
        dataSeries: new XyyDataSeries(wasmContext, {
            xValues,
            yValues,
            y1Values,
            dataSeriesName: `${series[i].name}`,
            isSorted: true
          }),
        strokeThickness: 2,
        fill: '#69ad85',
        fillY1: '#69ad85',
        opacity: 0.3,
        stroke: series[i].color,
        strokeY1: series[i].color,
        strokeDashArray: series[i].strokeDashArray,
        strokeY1DashArray: series[i].strokeDashArray,
    });
    if (!isMobile) {
        bandSeries.rolloverModifierProps.tooltipLabelY = "Lower Limit"
        bandSeries.rolloverModifierProps1.tooltipLabelY = "Upper Limit"
    }
    bandSeries.rolloverModifierProps1.tooltipTitle = ''
    bandSeries.rolloverModifierProps.tooltipTitle = ''
    sciChartSurface.renderableSeries.add(bandSeries);
  }


  for (let i = 0; i < tm_series.length; i++) {

    // Create arrays of x, y values (just arrays of numbers)
    const { xValues, yValues } = {
        xValues: tm_series[i].data.xx,
        yValues: tm_series[i].data.y,
    };
    const lineSeries = new FastLineRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
        xValues,
        yValues,
        dataSeriesName: `${tm_series[i].name}`,
        isSorted: true
        }),
        stroke: tm_series[i].color,
        strokeThickness: 3,
        strokeDashArray:tm_series[i].strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });

    if (!isMobile) {
        lineSeries.rolloverModifierProps.tooltipLabelY = "Target Moisture"
    }
    lineSeries.rolloverModifierProps.tooltipTextColor = "black"
    lineSeries.rolloverModifierProps.tooltipTitle = ''
    sciChartSurface.renderableSeries.add(lineSeries);
  }
  sciChartSurface.background = "#002a40";

  const overview = await SciChartOverview.create(
    sciChartSurface,
    `${type + divOverviewId}`
  );
  const modifierGroup = type;
  const rolloverModifier = new RolloverModifier({
    modifierGroup,
    showAxisLabel: true,
    snapToDataPoint: true,
    rolloverLineStrokeDashArray: [2, 2],
    showTooltip: true,
    placementDivId: `tooltip-${type}`,
  });

  sciChartSurface.chartModifiers.add(
    new ZoomExtentsModifier({ modifierGroup }),
    new MouseWheelZoomModifier({ modifierGroup }),
    new ZoomPanModifier({ modifierGroup }),
    rolloverModifier,
    new LegendModifier({
      modifierGroup,
      showCheckboxes: true,
      placementDivId: `legend-${type}`,
      orientation: ELegendOrientation.Horizontal,
      backgroundColor: "#002a40",
      isCheckedChangedCallback: (
        series: IRenderableSeries,
        isChecked: boolean
      ) => {
        if (!isChecked) {
          series.rolloverModifierProps.tooltipColor = `${series.rolloverModifierProps.tooltipColor}11`;
          series.rolloverModifierProps.tooltipTextColor = `${series.rolloverModifierProps.tooltipTextColor}22`;

          series.rolloverModifierProps1.tooltipColor = `${series.rolloverModifierProps.tooltipColor}11`;
          series.rolloverModifierProps1.tooltipTextColor = `${series.rolloverModifierProps.tooltipTextColor}22`;
        } else {
          series.rolloverModifierProps.tooltipColor =
            series.rolloverModifierProps.tooltipColor.substring(0, 7);
          series.rolloverModifierProps.tooltipTextColor =
            series.rolloverModifierProps.tooltipTextColor.substring(0, 7);


          series.rolloverModifierProps1.tooltipColor =
            series.rolloverModifierProps1.tooltipColor.substring(0, 7);
          series.rolloverModifierProps1.tooltipTextColor =
            series.rolloverModifierProps1.tooltipTextColor.substring(0, 7);
        }
      },
    })
  );

  sciChartSurface.zoomExtents();

  return { wasmContext, sciChartSurface, overview };
};


const IdealDryChart = (props: IdealDryChartProps) => {
 
  const sciChartSurfaceRef = React.useRef<SciChartSurface>();
  const sciChartOverviewRef = React.useRef<SciChartOverview>();
  const seriesRef = React.useRef<IdealDryData>()

  const { isMobile } = useScreenSize()
  const type = "idealDryChart";
  const unsubscribe = React.useCallback(() => {
    sciChartSurfaceRef.current?.xAxes
      .get(0)
      ?.visibleRangeChanged.unsubscribeAll();
    sciChartSurfaceRef.current?.delete();
    sciChartOverviewRef.current?.delete();
    sciChartSurfaceRef.current = undefined;
    sciChartOverviewRef.current = undefined;
  }, []);

  const {data, dryName, targetMoisture} = props

  const getData = useCallback(() => {
    const series = [
        {
          name: "Ideal Moisture Curve",
          data: {
            xx: data.time_elapsed_hrs.map((d) => {return d/24}),
            y: data.lower_bound,
            y1: data.upper_bound
          },
          color: "#3F6C51",
          strokeDashArray: [5,5]
        }
    ];
    return series
  }, [data])

  const getTargetMoistureData = useCallback(() => {
    const tm_series = [
        {
          name: "Target Moisture",
          data: {
            xx: data.time_elapsed_hrs.map((d) => {return d/24}),
            y: data.time_elapsed_hrs.map(() => {return targetMoisture})
          },
          color: "#ffffff",

          strokeDashArray: [5,5]
        }
    ];
    return tm_series
  }, [data, targetMoisture])

  React.useEffect(() => {
    const series = getData();
    const tm_series = getTargetMoistureData();
    const fetchData = async () => {
      const { sciChartSurface, overview } = await surfaceChart(type, series, tm_series, isMobile)();
      sciChartSurfaceRef.current = sciChartSurface;
      sciChartOverviewRef.current = overview;
      seriesRef.current = data
    };
    fetchData();

    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        unsubscribe();
        return;
      }

      // else postpone deletion
      fetchData().then(() => {
        unsubscribe();
      });
    };
  }, [unsubscribe]);

  React.useEffect(() => {

    if (sciChartSurfaceRef.current) {
      let series = getData()
      
      const redneredSeries = sciChartSurfaceRef.current.renderableSeries
    }
  }, [data])

  return (
    <div className="IdealDryChart">
      {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
      <div className="w-full flex">
        <div className="w-5/6">
          <div
            id={`${type + divElementId}`}
            style={{ width: "100%", height: 370 }}
          />
          <div
            id={`${type + divOverviewId}`}
            style={{ width: "100%", height: 100 }}
          />
          <div style={{ width: "100%" }}>
            <div
              id={`legend-${type}`}
              style={{ height: 100, overflowY: "auto" }}
            />
          </div>
        </div>
        <div className="w-1/6">
          <div
            id={`tooltip-${type}`}
            style={{ height: 600, overflowY: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default IdealDryChart;
