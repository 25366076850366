import React from 'react';
import './ErrorText.css';

interface ErrorTextProps {
  children: React.ReactNode;
}

function ErrorText(props: ErrorTextProps) {
  return (
    <span className="ErrorText">
      {props.children}
    </span>
  );
}

export default ErrorText;
