import { makeAutoObservable } from "mobx";
import {
  SensorServerJSONType,
  SensorPackServerJSONType,
  BatteryLevelType,
} from "../types";
import RootStore from "./RootStore";
import HTTPClient from "../HTTPClient";
import { Dry } from "./DryStore";

class SensorStore {
  sensorPacks = new Map<string, SensorPack>();
  sensors = new Map<string, Sensor>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

  updateSensorPackFromServer(json: SensorPackServerJSONType) {
    let sensorPack = this.sensorPacks.get(json.id);
    if (!sensorPack) {
      sensorPack = new SensorPack(this);
    }
    sensorPack.updateFromJSON(json);
    if (!sensorPack.sensorsLoaded) {
      sensorPack.loadSensors();
    }
    this.sensorPacks.set(sensorPack.id, sensorPack);
  }

  updateSensorFromServer(json: SensorServerJSONType, sensorPackId: string) {
    let sensor = this.sensors.get(json.id);
    const mergedJson = Object.assign({ sensor_pack_id: sensorPackId }, json);
    if (!sensor) {
      sensor = new Sensor(this);
    }
    sensor.updateFromJSON(mergedJson);
    this.sensors.set(sensor.id, sensor);
  }

  createSensor() {
    return new Sensor(this);
  }

  fetchSensorPacks() {
    const sensorStore = this;
    this.setLoaded();
    HTTPClient.apiGet("/packs")
    .then((res) => {
      res.data.forEach(function (d: SensorPackServerJSONType) {
        sensorStore.updateSensorPackFromServer(d);
      });
      this.setLoaded();
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.setHasError();
      }
    });
  }

  async fetchSensorsWithPAckId(packId: string) {
    HTTPClient.apiGet(`/packs/${packId}/sensors`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if(err.response!.status >= 500) {
          this.setHasError();
        }
        return err.response;
      });
  }

  get sensorPacksInUse() {
    const sensorPacks = Array.from(this.sensorPacks.values());
    return sensorPacks.filter((s) => s.inUse);
  }

  get sensorPacksAvailable() {
    const sensorPacks = Array.from(this.sensorPacks.values());
    return sensorPacks.filter((s) => !s.inUse);
  }
}

export class SensorPack {
  constructor(parentStore: SensorStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: SensorStore;
  id = "";
  alias = "";
  sensor_ids: Array<string> = [];
  sensorsLoaded = false;
  // Assigned dry ID
  assignment = "";

  updateFromJSON(json: SensorPackServerJSONType) {
    this.id = json.id;
    this.alias = json.alias;
    this.assignment = json.assignment;
    this.sensor_ids = json.sensor_ids;
  }

  setSensorsLoaded() {
    this.sensorsLoaded = true;
  }

  setAlias(val: string) {
    this.alias = val;
  }

  loadSensors() {
    const sensorStore = this.store;
    const pack = this;
    HTTPClient.apiGet(`/packs/${this.id}/sensors`)
    .then((res) => {
      res.data.forEach(function (d: SensorServerJSONType) {
        sensorStore.updateSensorFromServer(d, pack.id);
      });
      this.setSensorsLoaded();
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.store.setHasError();
      }
      return err.response;
    });
  }

  get sensors() {
    if (!this.sensorsLoaded) {
      return [];
    }
    return this.sensor_ids.map((sensorId) => this.store.sensors.get(sensorId));
  }

  get assignedDry() {
    const { ongoingDries, notStartedDries } = this.store.rootStore.dryStore;
    const dries = [...ongoingDries, ...notStartedDries];
    const assignedDry: Dry | undefined = dries.find(
      (d) => d.sensor_pack_ids && d.sensor_pack_ids.includes(this.id)
    );
    return assignedDry;
  }

  get inUse() {
    const { ongoingDries, notStartedDries } = this.store.rootStore.dryStore;
    const notCompletedDries = ongoingDries.concat(notStartedDries);
    const sensorsPackIdsInUse = notCompletedDries.flatMap(
      (d) => d.sensor_pack_ids || []
    );
    return sensorsPackIdsInUse.includes(this.id);
  }

  get asJSON() {
    const jsonData: SensorPackServerJSONType = {
      id: this.id,
      alias: this.alias,
      assignment: this.assignment,
      sensor_ids: this.sensor_ids,
    };
    return JSON.stringify(jsonData);
  }

  save() {
    const payload = this.asJSON;
    return HTTPClient.apiPut(`/packs/${this.id}`, payload)
      .then((packResp) => {
        this.store.updateSensorPackFromServer(packResp.data);
        return packResp;
      })
      .catch((err) => {
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }
}

export class Sensor {
  constructor(parentStore: SensorStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: SensorStore;
  id = "";
  alias = "";
  last_alive = "";
  sensor_pack_id = "";
  enabled = false;
  battery: BatteryLevelType = 0;
  is_online = false;
  plant_connected = false;

  updateFromJSON(json: SensorServerJSONType) {
    this.id = json.id;
    this.alias = json.alias;
    this.sensor_pack_id = json.sensor_pack_id;
    this.battery = json.battery;
    this.enabled = json.enabled;
    this.is_online = json.is_online;
    this.plant_connected = json.plant_connected;
    this.last_alive = json.last_alive;
  }

  save() {
    const data = JSON.parse(this.asJSON);
    if (!this.id) {
      delete data.id;
    }
    const payload = JSON.stringify(data);
    const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    const updateUrl = this.id ? `/sensors/${this.id}` : "/sensors";
    updateMethod(updateUrl, payload)
    .then((resp) => {
      this.store.updateSensorFromServer(resp.data, this.sensor_pack_id);
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.store.setHasError();
      }
    });
  }

  get asJSON() {
    const jsonData: SensorServerJSONType = {
      id: this.id,
      alias: this.alias,
      sensor_pack_id: this.sensor_pack_id,
      battery: this.battery,
      enabled: this.enabled,
      plant_connected: this.plant_connected,
      is_online: this.is_online,
      last_alive: this.last_alive,
    };
    return JSON.stringify(jsonData);
  }

  get sensorPack() {
    const sensorPack = this.store.sensorPacks.get(this.sensor_pack_id);
    if (!sensorPack) {
      return undefined;
    }
    return sensorPack;
  }

  get assignedDry() {
    if (!this.sensorPack) {
      return undefined;
    }
    return this.sensorPack.assignedDry;
  }

  get batteryLevelName() {
    if (this.battery === 0) {
      return "EMPTY";
    } else if (this.battery === 1) {
      return "1/4 FULL";
    } else if (this.battery === 2) {
      return "1/2 FULL";
    } else if (this.battery === 3) {
      return "3/4 FULL";
    } else {
      return "FULL";
    }
  }
}

export default SensorStore;
