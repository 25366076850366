import React, { useState } from 'react';
import Select from 'react-select';
import { AuthEventData } from '@aws-amplify/ui';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import './AccountView.css';

import Button from '../Button';
import CancelButton from '../CancelButton';
import MainContentView from '../MainContentView';
import NotFound from '../NotFound';
import StringEditor from '../StringEditor';
import Toggle from '../Toggle';
import { useRootStore } from '../StoreProvider';
import {
  TemperatureUnitType,
  AccountAlertLevelType,
  WeightUnitType,
} from '../types';

interface AccountViewProps {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
}

const AccountView = observer(function AccountView(props: AccountViewProps) {
  const { uiStore, accountStore } = useRootStore();
  const user = accountStore.currentAccount;
  const navigate = useNavigate();
  const [saveClicked, setSaveClicked] = useState(false);
  if (!user) {
    return <NotFound />;
  }

  const alertLevelOpts = [
    { label: 'INFO', value: '0' },
    { label: 'WARNING', value: '1' },
    { label: 'CRITICAL', value: '2' },
  ];

  const temperatureUnitOpts = [
    { label: 'Fahrenheit', value: '°F' },
    { label: 'Celsius', value: '°C' },
  ];

  const weightUnitOpts = [
    { label: 'Kilogram', value: 'kg' },
    { label: 'Pound', value: 'lb' },
  ];

  const onSave = () => {
    setSaveClicked(true);
    user.save().then(resp => {
      if (resp === 'SUCCESS') {
        navigate("/");
      }
      setSaveClicked(false);
    }).catch(err => setSaveClicked(false));
  }

  const handleSignOut = () => {
    if (props.signOut) {
      props.signOut();
      setTimeout(() => (window.location.href = "/"), 500);
    }
  }

  return (
    <MainContentView>
      <div className="text-xl">
        <div className="grid grid-cols-1 gap-4">
          <h2 className="text-3xl">Account Settings</h2>
          <Button onPress={handleSignOut}>SIGN OUT</Button>
        </div>
        <div className="my-8 grid grid-cols-1 items-center gap-4">
          <div><b>First Name:</b></div>
          <StringEditor
            text={user.givenName}
            value={user.givenName}
            onChange={(evt) => user.setGivenName(evt.target.value)}
          />
          <div><b>Last Name:</b></div>
          <StringEditor
            text={user.familyName}
            value={user.familyName}
            onChange={(evt) => user.setFamilyName(evt.target.value)}
          />
          <div><b>Email:</b></div>
          <div>{user.email}</div>
          <div><b>Phone:</b></div>
          <StringEditor
            text={user.phoneNumber}
            value={user.phoneNumber.slice(2)}
            onChange={(evt) => user.setPhoneNumber(`+1${evt.target.value}`)}
          />
          <div><b>Role:</b></div>
          <div>{user.role}</div>
          <div><b>Alert Level:</b></div>
          <Select
            onChange={(level) => {
              const val = level?.value || '0';
              let newAlertLevel: AccountAlertLevelType = '0';
              if (val === '1') {
                newAlertLevel = '1';
              } else if (val === '2') {
                newAlertLevel = '2';
              }
              user.setAlertLevel(newAlertLevel);
            }}
            value={alertLevelOpts.find(o => o.value === user.alertLevel)}
            options={alertLevelOpts}
            className="AccountView-dropdown w-full md:w-1/3"
          />
          <div><b>Preferred temperature unit:</b></div>
          <Select
            onChange={(unit) => {
              const val = unit?.value;
              let newUnit: TemperatureUnitType = '°F';
              if (val === '°F') {
                newUnit = '°F';
              } else if (val === '°C') {
                newUnit = '°C';
              }
              uiStore.setPreferredTemperatureUnit(newUnit);
            }}
            value={temperatureUnitOpts.find(u => u.value === uiStore.preferredTemperatureUnit)}
            options={temperatureUnitOpts}
            className="AccountView-dropdown w-full md:w-1/3"
          />
          <div><b>Preferred weight unit:</b></div>
          <Select
            onChange={(unit) => {
              const val = unit?.value;
              let newUnit: WeightUnitType = 'kg';
              if (val === 'kg') {
                newUnit = 'kg';
              } else if (val === 'lb') {
                newUnit = 'lb';
              }
              uiStore.setPreferredWeightUnit(newUnit);
            }}
            value={weightUnitOpts.find(u => u.value === uiStore.preferredWeightUnit)}
            options={weightUnitOpts}
            className="AccountView-dropdown w-full md:w-1/3"
          />
        </div>
        <div className="AccountView-notification-section-header">
          How would you like to receive notifications?
        </div>
        <div className="AccountView-toggles-container">
          <div>
            Email:&nbsp;
          </div>
          <Toggle
            onChange={() => {
              const newVal = user.receiveEmail === "false" ? "true" : "false";
              user.setReceiveEmail(newVal);
            }}
            checked={user.receiveEmail === "true"}
          />
        </div>
      </div>
      {user.dirty &&
        <div className="my-8 grid grid-cols-1 md:grid-cols-4 gap-4">
          <Button onPress={onSave} disabled={saveClicked}>
            {saveClicked ? 'SAVING...' : 'SAVE CHANGES'}
          </Button>
          {!saveClicked &&
            <CancelButton onCancel={() => user.discardChanges()} />
          }
          <div/>
          <div/>
        </div>
      }
    </MainContentView>
  );
});

export default AccountView;
