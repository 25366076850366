import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import './EndDryWithConfirmation.css';
import Modal from 'react-modal';
import Button from './Button';

type onStopType = () => void;
interface EndDryWithConfirmationProps {
  onStop: onStopType;
  key: any;
  itemName: any;
  itemType: any;
}

function EndDryWithConfirmation(props: EndDryWithConfirmationProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modelOpen, setModalOpen] = useState(false)
  return (
    <div className="EndDryWithConfirmation">
      <Button
        className="Button-red"
        onPress={() => setModalOpen(true)}
      >
      END DRY
      </Button>

     
      <Modal
        isOpen={modelOpen}
        onRequestClose={() => setModalOpen(false)}
        className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 42, 64, 0.8)",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }
        }}
        contentLabel="Example Modal"
      >
        <h1 className='modal-title' >{`End ${props.itemType} - ${props.itemName}`}</h1>
        <p className='modal-description' >
          {`Ending a ${props.itemType} is an irreversible process. Are you sure you want to end this ${props.itemType}?`}
        </p>
        <div className='w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4'>
          <Button onPress={() => setModalOpen(false)} >No</Button>
          <Button className='Button-red' onPress={() => props.onStop()} >Yes</Button>
        </div>

      </Modal >
    </div>
  );
}

export default EndDryWithConfirmation;
