import React, { useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import './StringEditor.css';

type onChangeType = (evt: React.ChangeEvent<HTMLInputElement>) => void;
interface StringEditorProps {
  onChange: onChangeType;
  text: string;
  value: string;
}

function StringEditor(props: StringEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {isEditing &&
        <div>
          <input
            autoFocus
            onBlur={() => setIsEditing(false)}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                setIsEditing(false);
              }
            }}
            className="StringEditor-input"
            type="text"
            value={props.value}
            onChange={props.onChange}
          />
        </div>
      }
      {!isEditing &&
        <div className="StringEditor-text flex" onClick={() => setIsEditing(true)}>
          {props.text} <MdModeEdit className='mx-2' />
        </div>
      }
    </>
  );
}

export default StringEditor;
