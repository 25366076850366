import React, { Component, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import ReportsView from "./reports/ReportsView";
import DryContainer from "./dries/DryContainer";
import HomeView from "./home/HomeView";
import Sensor from "./sensors/Sensor";
import SensorPack from "./sensors/SensorPack";
import OrderSensorsView from "./sensors/OrderSensorsView";
import AccountView from "./account/AccountView";
import SettingsView from "./settings/SettingsView";
import Space from "./spaces/Space";
import Strain from "./strains/Strain";
import SupportView from "./support/SupportView";
import UpdateDryView from "./dries/UpdateDryView";
import UpdateSpaceView from "./spaces/UpdateSpaceView";
import UpdateStrainView from "./strains/UpdateStrainView";
import Attributions from "./attributions/Attributions";
import Gateway from "./gateways/Gateway";
import UsersView from "./users/UsersView";
import UpdateUser from "./users/UpdateUser";
import UpdateUserView from "./users/UpdateUserView";
import HubspotSurvey from "./feedback/HubspotSurvey";

interface RoutesComponentProps {
  signOut?: any;
}

class RedirectToSupportPage extends React.Component{
    componentDidMount(): void {
        window.location.href="https://support.growvera.com/zone-cannabis-drying-sensors";
    }
    render(): React.ReactNode {
        return null
    }
}

function RoutesComponent(props: RoutesComponentProps) {
  const { signOut } = props;
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();

  const TIMEOUT = 60 * 60 * 1000; // 5 minutes in milliseconds

  let timeoutId: NodeJS.Timeout;

  const resetTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => setIsInactive(true), TIMEOUT);
  };

  const onActive = () => {
    if (isInactive) {
      setIsInactive(false);
    }
    resetTimer();
  };

  useEffect(() => {
    window.addEventListener("mousemove", onActive);
    window.addEventListener("mousedown", onActive);
    window.addEventListener("keypress", onActive);
    window.addEventListener("touchmove", onActive);

    resetTimer();

    return () => {
      window.removeEventListener("mousemove", onActive);
      window.removeEventListener("mousedown", onActive);
      window.removeEventListener("keypress", onActive);
      window.removeEventListener("touchmove", onActive);

      clearTimeout(timeoutId);
    };
  }, [isInactive]);

  useEffect(() => {
    if (isInactive) {
      signOut();
      setTimeout(() => navigate("/"), 1000);
    }
  }, [isInactive]);
  return (
    <Routes>
      <Route path="home" element={<HomeView />} />
      <Route path="/" element={<HomeView />} />

      <Route path="account" element={<AccountView signOut={signOut} />} />
      <Route path="reports" element={<ReportsView />}>
        <Route path="new" element={<p>new view</p>} />
      </Route>
      <Route path="/reports/dries/:id" element={<DryContainer />} />
      <Route path="/reports/dries/:id/update" element={<UpdateDryView />} />
      <Route path="/reports/dries/new" element={<UpdateDryView />} />

      <Route path="/home/dries/:id" element={<DryContainer />} />
      <Route path="/home/dries/:id/update" element={<UpdateDryView />} />
      <Route path="/home/dries/new" element={<UpdateDryView />} />

      <Route path="settings" element={<SettingsView />} />
      <Route path="settings/spaces/:id" element={<Space />} />
      <Route path="settings/spaces/:id/update" element={<UpdateSpaceView />} />
      <Route path="settings/spaces/new" element={<UpdateSpaceView />} />
      <Route path="team/new" element={<UpdateUserView />} />
      <Route path="settings/sensors/:id" element={<Sensor />} />
      <Route path="settings/sensors/order" element={<OrderSensorsView />} />
      <Route path="settings/sensor-packs/:id" element={<SensorPack />} />
      <Route path="settings/strains/:id" element={<Strain />} />
      <Route path="help" element={<RedirectToSupportPage/>} />
      <Route
        path="settings/strains/:id/update"
        element={<UpdateStrainView />}
      />
      <Route path="team" element={<UsersView />} />
      <Route path="settings/strains/new" element={<UpdateStrainView />} />
      <Route path="settings/gateway/:id" element={<Gateway />} />
      <Route path="credits" element={<Attributions />} />
      <Route path="*" element={<p>Not Found</p>} />
      {/* <Route path="feedback" element={<HubspotSurvey />} /> */}
    </Routes>
  );
}

export default RoutesComponent;
