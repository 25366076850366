import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRootStore } from "../StoreProvider";
import DataTable from "react-data-table-component";
import { Sensor } from "../stores/SensorStore";
import Button from "../Button";
import Modal from "react-modal";
import httpClient from "../HTTPClient";
import CancelButton from "../CancelButton";
import { useNavigate } from "react-router-dom";
import ErrorText from "../ErrorText";
import { AxiosError } from "axios";

type SVGProps = {
  color: string;
  height: number;
  width: number;
};
const LeftArrow = ({ color, height, width, style }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      style={style}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

const RightArrow = ({ color, height, width, style }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      style={style}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

const SensorChart = ({
  sensors,
  versionV2 = false,
  dry,
  onCancelClick,
  paginationIndex,
  setPaginationIndex,
  chart,
  setChart,
  displayData,
  setDisplayData,
  setLoading,
}: any) => {
  const navigate = useNavigate();
  const { dryStore } = useRootStore();
  const { fetchDries } = dryStore;
  const [navigationLoading, setNavigationLoading] = useState(false);
  // const [displayData, setDisplayData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableSensorMenus, setDisableSensorMenus] = useState<any>();

  const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const handleMenu = (id: any, state: boolean) => {
    setDisableSensorMenus((prevState: any) => ({
      ...prevState,
      [id]: state,
    }));
  };

  const sensorPolling = useCallback(async () => {
    const updatedData: any = [];
    for (const item of dry.sensor_pack_ids) {
      const res = await dryStore.fetchSensorsInStore(item);
      const sorted = res.data.sort((a: any, b: any) =>
        a.alias.localeCompare(b.alias)
      );
      updatedData.push(sorted);
    }

    if (
      JSON.stringify([].concat(...updatedData)) !=
      JSON.stringify([].concat(...chart))
    ) {
      setChart(JSON.parse(JSON.stringify(updatedData)));
      // setDisplayData(
      //   JSON.parse(JSON.stringify(updatedData[paginationIndex]))
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryStore]);

  useEffect(() => {
    sensorPolling();
    intervalIdRef.current = setInterval(sensorPolling, 10000);
    return () => {
      clearInterval(intervalIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dryStore]);

  useEffect(() => {
    setDisplayData(chart[paginationIndex]);
  }, [chart, paginationIndex, dryStore]);

  const GoodIndicator = ({ is_good }: { is_good: boolean }) => {
    const is_good_style = {
      display: "inline-block",
      backgroundColor: "#35B44C",
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      justifyContent: "center",
    };

    const not_is_good_style = {
      display: "inline-block",
      backgroundColor: "crimson",
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      justifyContent: "center",
    };
    return (
      <>
        <div
          className="GoodIndicator"
          style={is_good ? is_good_style : not_is_good_style}
        ></div>
      </>
    );
  };

  const toggleEnabled = async (
    sensorPackId: any,
    sensorId: string,
    enabled: boolean
  ) => {
    try {
      const response = await httpClient.apiPut(
        `/packs/${sensorPackId}/sensors/${sensorId}`,
        JSON.stringify({ enabled: !enabled, buzzer_on: false })
      );
      return { isError: false, data: response.data };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response!.status >= 500) {
          dryStore.setHasError();
        }
      }
      return { isError: true, data: error };
    }
  };

  const CustomToggle = ({
    row,
    setCustomToggleEnabled,
  }: {
    row: Sensor;
    setCustomToggleEnabled: (enabled: boolean) => void;
  }) => {
    const { sensorStore } = useRootStore();

    const sensor = sensorStore.sensors.get(row.id);
    // const sensorPack = sensorStore.sensorPacks.get(row.id);
    const rowDup: any = row;
    const [enabled, setEnabled] = useState(row.enabled);
    const [modalOpen, setModalOpen] = useState(false);

    const handleToggleOnchange = async () => {
      handleMenu(row.id, row.enabled);
      if (enabled) {
        setModalOpen(true);
      } else {
        const newEnabled = !enabled;
        const res = await toggleEnabled(
          sensor?.sensor_pack_id,
          row.id,
          row.enabled
        );
        if (!res.isError) {
          setEnabled(newEnabled);
          setCustomToggleEnabled(newEnabled);
        }
      }
    };
    const handleDisable = async () => {
      const newEnabled = !enabled;
      const res = await toggleEnabled(
        sensor?.sensor_pack_id,
        row.id,
        row.enabled
      );
      if (!res.isError) {
        setEnabled(newEnabled);
        setCustomToggleEnabled(newEnabled);
        setModalOpen(false);
      }
    };

    useEffect(() => {
      if (disableSensorMenus?.[row.id]) {
        setModalOpen(true);
      }
    }, []);

    return (
      <>
        <div>
          <label className="switch">
            <input
              type="checkbox"
              checked={enabled}
              onChange={handleToggleOnchange}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <Modal
          isOpen={modalOpen}
          // onAfterOpen={}
          onRequestClose={() => {
            handleMenu(row.id, false);
            setModalOpen(false);
          }}
          className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 42, 64, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            },
          }}
          contentLabel="Example Modal"
        >
          <h1 className="modal-title break-words">Disable Sensor - {row.id}</h1>
          <p className="modal-description">
            Disabling Sensors immediately terminates sensor data collection. Are
            you sure you want to disable the sensor?
          </p>
          <div className='w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4'>
            <Button
              onPress={() => {
                setModalOpen(false);
                handleMenu(row.id, false);
              }}
            >
              No
            </Button>
            <Button
              className="Button-red"
              onPress={() => {
                handleDisable();
                handleMenu(row.id, false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      </>
    );
  };

  function calculateDifferenceInMinutes(date: Date): string {
    const currentTime = new Date();
    const differenceInSeconds = Math.floor(
      (currentTime.getTime() - date.getTime()) / 1000
    ); // Difference in seconds

    const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
    const hours = Math.floor(
      (differenceInSeconds % (60 * 60 * 24)) / (60 * 60)
    );
    const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
    if (days > 7) {
      return `Over a Week`;
    } else if (days <= 7 && days > 0) {
      return `${days} d, ${hours} hr, ${minutes} min`;
    } else if (days === 0 && hours > 0) {
      return `${hours} hr, ${minutes} min`;
    } else {
      return `${minutes} min`;
    }
  }

  const sensorColumns: any = [
    {
      name: "Sensor ID",
      selector: (row: Sensor) => row.id,
      cell: (row: Sensor) => (<span>{row.id}</span>),
      id: 0,
    },
    {
      name: "Alias",
      selector: (row: Sensor, index: number) => row.alias,
      id: "alias",
      sortable: true,
    },
    {
      name: "Online",
      selector: (row: Sensor) => <GoodIndicator is_good={row.is_online} />,
    },
    {
      name: "Plant Connected",
      selector: (row: Sensor) => (
        <GoodIndicator is_good={row.plant_connected} />
      ),
    },
    {
      name: "Time Since Last Message",
      selector: (row: Sensor) => row.last_alive,
      cell: (row: Sensor) => (
        <div>{calculateDifferenceInMinutes(new Date(row.last_alive))}</div>
      ),
    },
    {
      name: "Enabled",
      cell: (row: Sensor) => (
        <CustomToggle
          row={row}
          setCustomToggleEnabled={(enabled) => (row.enabled = enabled)}
        />
      ),
    },
  ];

  if (chart?.length == 0) {
    return <h5 style={{ color: "white" }}>Loading....</h5>;
  }

  // const dryButttonDisbale = chart?.filter(
  //   (item: any) => item?.plant_connected == false
  // );
  const handlePageNext = () => {
    if (paginationIndex < chart.length - 1) {
      setPaginationIndex((prevIndex: any) => prevIndex + 1);
      // setDisplayData(chart[paginationIndex + 1]);
    }
  };

  const handlePagePrev = () => {
    if (paginationIndex > 0) {
      setPaginationIndex((prevIndex: any) => prevIndex - 1);
      // setDisplayData(chart[paginationIndex - 1]);
    }
  };

  // Check if all sensors are connected to the plant and online or disabled
  const concatArray: any = [].concat(...chart);
  const allSensorsConnectedAndOnline = concatArray.every(
    (sensor: any) =>
      (sensor.plant_connected && sensor.is_online) || !sensor.enabled
  );

  // Check if at least one sensor from all packs assigned to the dry is enabled
  const atLeastOneSensorEnabled = concatArray.some(
    (sensor: any) => sensor.enabled
  );
  const error2 = atLeastOneSensorEnabled
    ? ""
    : "At least one sensor must be enabled";

  return (
    <>
      <div
        className="Dry-info-row"
        style={{ marginBottom: "10px", fontSize: "20px" }}
      >
        <div
          className="Dry-info-key"
          style={{ padding: "0 10px 0 0", width: "auto" }}
        >
          Sensor Pack:
        </div>
        <b>{dry.sensor_pack_ids[paginationIndex]}</b>
      </div>
      <DataTable
        columns={sensorColumns}
        data={displayData}
        // pagination
        // paginationTotalRows={2}
        defaultSortFieldId={"alias"}
        // onChangePage={handlePageChange}
      />
      <div className="pagination-icons">
        <div style={{ cursor: "pointer" }} onClick={() => handlePagePrev()}>
          <LeftArrow
            style={{
              height: 36,
              width: 36,
              fontSize: 500,
              color: paginationIndex === 0 ? "#666666" : "white",
            }}
          />
        </div>
        <div style={{ cursor: "pointer", color: "white", fontSize: "24px" }}>
          {paginationIndex + 1}/{chart?.length}
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => handlePageNext()}>
          <RightArrow
            style={{
              height: 36,
              width: 36,
              fontSize: 500,
              color:
                paginationIndex === chart?.length - 1 ? "#666666" : "white",
            }}
          />
        </div>
      </div>
      {/* <h1 >prev</h1>
      <h1 onClick={handlePageNext}>next</h1> */}
      {dry.actionStatus === "NOT_STARTED" && (
        <>
          <div className="action-buttons">
            {buttonLoading ? (
              <p>Loading....</p>
            ) : (
              <CancelButton
                onCancel={() => {
                  setButtonLoading(true);
                  onCancelClick();
                }}
              />
            )}

            <Button
              disabled={!atLeastOneSensorEnabled}
              onPress={async () => {
                setLoading(true);
                await dry.start();
                await fetchDries();
                setTimeout(() => {
                  navigate("/home");
                }, 5000);
              }}
            >
              START DRY
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ErrorText> {error2}</ErrorText>
          </div>
        </>
      )}
    </>
  );
};

export default SensorChart;
