import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import './BackButton.css';

interface BackButtonProps {
  children: React.ReactNode;
  to: string;
}

function BackButton(props: BackButtonProps) {
  const {
    children,
    to,
  } = props;
  const navigate = useNavigate();
  return (
    <div className="BackButton">
      <div className="BackButton-container" onClick={() => navigate(to)}>
        <span className="BackButton-icon">
          <MdArrowBack />
        </span>
        <span className="BackButton-text">
          &nbsp;{children}
        </span>
      </div>
    </div>
  );
}

export default BackButton;
