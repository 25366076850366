import React from 'react';
import './StrainTile.css';
import { useNavigate } from 'react-router-dom';

import { Strain } from '../stores/StrainStore';

interface StrainTileProps {
  strain: Strain;
}

function StrainTile(props: StrainTileProps) {
  const { strain } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/settings/strains/${strain.id}`)}
      className="StrainTile"
    >
      <h4>{strain.name}</h4>
      <h4>Target Moisture: {strain.moisture_target}%</h4>
      {/* <h4>Variability: {strain.moisture_spatial_variability}%</h4> */}
    </div>
  );
}

export default StrainTile;
