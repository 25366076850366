export type TemperatureUnitType = '°F' | '°C';

class Temperature {
  degrees: number;
  unit: TemperatureUnitType;

  constructor(degrees: number, unit: TemperatureUnitType) {
    this.degrees = degrees;
    this.unit = unit;
  }

  toString(decimalPlaces: number) {
    const roundedDegrees = Math.round((10 ** decimalPlaces) * this.degrees) / (10 ** decimalPlaces);
    return `${roundedDegrees}${this.unit}`;
  }
}

export class CelsiusTemperature extends Temperature {
  constructor(degrees: number) {
    super(degrees, '°C');
  }

  get asFahrenheit() {
    return (this.degrees * 9 / 5) + 32;
  }
}

export class CelsiusTemperatureDelta extends Temperature {
  constructor(degrees: number) {
    super(degrees, '°C');
  }

  get asFahrenheit() {
    return (this.degrees * 9 / 5);
  }
}
