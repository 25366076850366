import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { AmplifyConfig } from './AWSConfig';
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import DataLoadingComponent from './DataLoadingComponent';
import RoutesComponent from './RoutesComponent';
import TopBar from './TopBar';
import Footer from './Footer';
import { RootStoreProvider } from './StoreProvider';
import logo_stacked from './logowhitestacked.png';
import {
  AccountAlertLevelType,
  AccountReceiveEmailSettingType,
  AccountReceiveTextSettingType,
  AccountRoleType,
} from './types';
import ErrorPage from './ErrorPage';
import { SciChartSurface } from "scichart";

Amplify.configure(AmplifyConfig);

SciChartSurface.loadWasmFromCDN();
// Set this code in application startup, before creating a SciChartSurface
SciChartSurface.setRuntimeLicenseKey("qGEtDDiMisHtTPfTCxROSYvdYWxebCH+5oZDv2wHBOFCv0PQZAvdhwhTNK6nA6Fo6tOudMmi2WcT6XOr6ssXRLfbOi6plPsdxkpS9BYC/FRFhjjQUyzBdgqV7XJodbdPVURo+pFZ832dldzTzkNDZ/i/RPNmFAGIZkyniNU7FTRt4KFPvy2dprv7VLpYXvYCbPLzoIJ44c2lDJPgOaXjZ1gL49TFL8M2djnyz+rhi6EZJD2Gr74vx08WUOSDBokKSUbZs9ZdNf7XnhuiOREG4NhWrhGAigca61x+eFj0Zwf72gXBxlnv6rjRApU10YoAIkpgyEQS+HM9iHhA3696HWH99L8hhc4qznEUq4mMevnfjWNm3fWrhUJab9+7dKbqfhf7zAKRhd7QxOa9fB5HOxJUqq+j7xGgoWapoibwTesCTY175P36LCqVC4RUh6Xv6OMyCImApO3o6dD5WbiOpR5LYa+OBxfywbvP6iliwVJoEGgUFb9E1HElOqh8R6AK4SrtaIjwU3xQHSGb+Zbp6j/buo85rbkqjl2RdfKSbOOqoj6P+4YRorCHaQQKZm6o6/9AYyBPnce4hvfrfBCFQI9KOhvyUnwsLsSq11U2wZJPRxnbvT5X8NdGAqvP+fVHhGuecQyS2GQQjHqV6d13dHTYpQArgOyCi6dcXCITmFIeJXn697PAAFE1fJhkih6nfmBMlbNPBLVGKaqW0ohFqeRXakz4c4y/");

const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Growvera logo"
            src={logo_stacked}
          />
        </View>
      );
    }
}
function App() {
  return (
    <RootStoreProvider>
      <BrowserRouter>
        <Authenticator
          loginMechanisms={['username']}
          variation="modal"
          hideSignUp={true}
          components={components}
        >
          {({ signOut, user }) => {
            if (user && user.attributes) {
              const cognitoAttrs = user.attributes;

              // Because everything returned from Cognito is a string, we have
              // to "convert" it to the proper types
              const roleAttr: AccountRoleType =
                cognitoAttrs['custom:role'] === 'ADMIN' ?
                  'ADMIN' : 'USER';
              const receiveEmailAttr: AccountReceiveEmailSettingType =
                cognitoAttrs['custom:receive_email'] === "false" ?
                "false" : "true";
              const receiveTextAttr: AccountReceiveTextSettingType =
                cognitoAttrs['custom:receive_text'] === "false" ?
                "false" : "true";
              let alertLevelAttr: AccountAlertLevelType;
              if (cognitoAttrs['custom:alert_level'] === '2') {
                alertLevelAttr = '2';
              } else if (cognitoAttrs['custom:alert_level'] === '1') {
                alertLevelAttr = '1';
              } else {
                alertLevelAttr = '0';
              }

              const attrs = {
                'custom:role': roleAttr,
                'custom:alert_level': alertLevelAttr,
                'custom:receive_email': receiveEmailAttr,
                'custom:receive_text': receiveTextAttr,
                phone_number: cognitoAttrs.phone_number,
                email: cognitoAttrs.email,
                given_name: cognitoAttrs.given_name,
                family_name: cognitoAttrs.family_name,
                sub: cognitoAttrs.sub,
              };

              return (
                <ErrorPage>
                  <DataLoadingComponent
                    cognitoUser={attrs}
                  >
                    <div className="App">
                      <TopBar />
                      <RoutesComponent signOut={signOut} />
                      <Footer />
                    </div>
                  </DataLoadingComponent>
                </ErrorPage>
              );
            } else {
              return (
                <p>
                  Something went wrong loading your account data. Please contact
                  <a href="mailto:support@growvera.com">
                    support@growvera.com
                  </a>
                </p>
              );
            }
          }}
        </Authenticator>
      </BrowserRouter>
    </RootStoreProvider>
  );
}

export default App;
