import React from 'react';
import { observer } from 'mobx-react';
import './ReportsView.css';

import MainContentView from '../MainContentView';
import ReportSelector from './ReportSelector';
import { useRootStore } from '../StoreProvider';

const ReportsView = observer(function ReportsView() {
  const { dryStore } = useRootStore();
  const completedDries = dryStore.completedDries;
  return (
    <div className="">
      <MainContentView>
        <div className="">
          <ReportSelector dries={completedDries} />
        </div>
      </MainContentView>
    </div>
  );
});

export default ReportsView;
