import DryStore from "./DryStore";
import GatewayStore from "./GatewayStore";
import SensorStore from "./SensorStore";
import SpaceStore from "./SpaceStore";
import StrainStore from "./StrainStore";
import UIStore from "./UIStore";
import AccountStore from "./AccountStore";
import UserStore from "./UserStore";

export default class RootStore {
  dryStore: DryStore;
  sensorStore: SensorStore;
  spaceStore: SpaceStore;
  strainStore: StrainStore;
  uiStore: UIStore;
  accountStore: AccountStore;
  gatewayStore: GatewayStore;
  userStore: UserStore;
  errorPageMessage: string;

  constructor() {
    this.dryStore = new DryStore(this);
    this.sensorStore = new SensorStore(this);
    this.spaceStore = new SpaceStore(this);
    this.strainStore = new StrainStore(this);
    this.uiStore = new UIStore(this);
    this.accountStore = new AccountStore(this);
    this.gatewayStore = new GatewayStore(this);
    this.userStore = new UserStore(this);
    this.errorPageMessage = "Something went wrong."
  }

  get loaded() {
    return (
      this.dryStore.loaded &&
      this.sensorStore.loaded &&
      this.spaceStore.loaded &&
      this.strainStore.loaded &&
      this.uiStore.loaded &&
      this.accountStore.loaded &&
      this.gatewayStore.loaded &&
      this.userStore.loaded
    );
  }

  get hasError() {
    return (
      this.dryStore.hasError ||
      this.sensorStore.hasError ||
      this.spaceStore.hasError ||
      this.strainStore.hasError ||
      this.uiStore.hasError ||
      this.accountStore.hasError ||
      this.gatewayStore.hasError ||
      this.userStore.hasError
    );
  }

  // set hasError(hasError : boolean) {
  //     this.dryStore.hasError = hasError;
  //     this.sensorStore.hasError = hasError;
  //     this.spaceStore.hasError = hasError;
  //     this.strainStore.hasError = hasError;
  //     this.uiStore.hasError = hasError;
  //     this.accountStore.hasError = hasError;
  //     this.gatewayStore.hasError = hasError;
  //     this.userStore.hasError = hasError;
  // }
}
