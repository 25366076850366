import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import HTTPClient from "../HTTPClient";
import { GatewayServerJSONType } from "../types/GatewayType";

class GatewayStore {
  gateways = new Map<string, Gateway>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

  updateGatewayFromServer(json: GatewayServerJSONType) {
    let gateway = this.gateways.get(json.id);
    if (!gateway) {
      gateway = new Gateway(this);
    }
    gateway.updateFromJSON(json);
    this.gateways.set(gateway.id, gateway);
  }

  fetchGateways() {
    const gatewayStore = this;
    HTTPClient.apiGet(`/gateways`)
      .then((res) => {
        res.data.forEach(function (d: GatewayServerJSONType) {
          gatewayStore.updateGatewayFromServer(d);
        });
        this.setLoaded();
      })
      .catch((err) => {
        console.log(err);
        if(err.response!.status >= 500) {
          this.setHasError();
        }
      });
  }
}

export class Gateway {
  constructor(parentStore: GatewayStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: GatewayStore;
  id = "";
  alias = "";
  online = false;
  last_uplink_received = "";
  persistedJSON = "";

  updateFromJSON(json: GatewayServerJSONType) {
    this.id = json.id;
    this.alias = json.alias;
    this.online = json.online;
    this.last_uplink_received = json.last_uplink_received;

    this.persistedJSON = this.asJSON;
  }

  setAlias(alias: string) {
    this.alias = alias;
  }

  save() {
    const payload = JSON.stringify({ alias: this.alias });
    return HTTPClient.apiPut(`/gateways/${this.id}`, payload)
      .then((gatewayResp) => {
        this.store.updateGatewayFromServer(gatewayResp.data);
        return gatewayResp;
      })
      .catch((err) => {
        return err.response;
      });
  }

  get asJSON() {
    const jsonData: GatewayServerJSONType = {
      id: this.id,
      alias: this.alias,
      online: this.online,
      last_uplink_received: this.last_uplink_received,
    };
    return JSON.stringify(jsonData);
  }
}

export default GatewayStore;
