import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Space.css';
import './UpdateSpace.css';
import Button from '../Button';
import CancelButton from '../CancelButton';
import ErrorText from '../ErrorText';
import MainContentView from '../MainContentView';
import Slider from '../Slider';
import TitleEditor from '../TitleEditor';
import { observer } from 'mobx-react';
import { validateSpaceForm, SpaceFormErrors } from '../FormValidators';
import { Space } from '../stores/SpaceStore';
import { CelsiusTemperature } from '../types';

interface UpdateSpaceProps {
  space: Space;
}

const UpdateSpace = observer(function UpdateSpace(props: UpdateSpaceProps) {
  const { space } = props;
  const { uiStore } = space.store.rootStore;
  const navigate = useNavigate();
  const initialErrors: SpaceFormErrors = {};
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [saveClicked, setSaveClicked] = useState(false);
  const initialHumidityValues = [space.humidity_lower_bound, space.humidity_upper_bound];
  const initialVpdValues = [space.vpd_lower_bound, space.vpd_upper_bound];
  const initialTemperatureValues = [
    uiStore.displayTemperature(space.temperature_lower_bound),
    uiStore.displayTemperature(space.temperature_upper_bound)
  ];
  const [temperatureRange, setTemperatureRange] = useState(initialTemperatureValues);
  const [temperatureSpatialVariability, setTemperatureSpatialVariability] = useState(
    uiStore.displayTemperatureDelta(space.temperature_spatial_variability) || '');
  const [humidityRange, setHumidityRange] = useState(initialHumidityValues);
  const [vpdRange, setVpdRange] = useState(initialVpdValues);
  const [humiditySpatialVariability, setHumiditySpatialVariability] = useState(space.humidity_spatial_variability || '');

  useEffect(() => {
    return function cleanup() {
      space.discardChanges();
    };
  }, [space]);

  const updateTemperatureRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    space.setTemperatureUpperBound(uiStore.toCelsius(upperBound));
    space.setTemperatureLowerBound(uiStore.toCelsius(lowerBound));
    setTemperatureRange(range);
  }

  const updateHumidityRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    space.setHumidityUpperBound(upperBound);
    space.setHumidityLowerBound(lowerBound);
    setHumidityRange(range);
  }

  const updateVpdRange = (range: Array<number>) => {
    const [lowerBound, upperBound] = range;
    space.setVpdUpperBound(upperBound);
    space.setVpdLowerBound(lowerBound);
    setVpdRange(range);
  }

  const onSave = () => {
    const errors = validateSpaceForm(space);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSaveClicked(true);
      space.save().then(resp => {
        if (resp.status === 200 || resp.status === 201) {
          navigate('/settings');
        }
        setSaveClicked(false);
      }).catch(err => setSaveClicked(false));
    }
  }

  return (
    <MainContentView>
      <div className="Space-header">
        <div className="Space-header-left">
          <TitleEditor
            title={space.name}
            onChange={(evt) => {
              space.setName(evt.target.value);
            }}
            setIsEditingFromOutside={() => {}}
            value={space.name}
          />
        </div>

      </div>
      {formErrors.name &&
          <ErrorText>{formErrors.name}</ErrorText>
        }
      <h2 className="title">Default Alerting Ranges</h2>
      <p>
        When you start a dry in this space, these values will be used as
        defaults on the dry. Sensor measurements outside these thresholds
        will trigger an alert.
      </p>
      <div className="UpdateSpace-setting">
        <Slider
          value={temperatureRange}
          title="Temperature"
          unitIndicator={`${uiStore.preferredTemperatureUnit}`}
          minDistance={5}
          onChange={(range) => updateTemperatureRange(range)}
          min={uiStore.displayTemperature(new CelsiusTemperature(0))}
          max={uiStore.displayTemperature(new CelsiusTemperature(60))}
        />
      </div>
      {/* <div className="UpdateSpace-setting">
        <div className="UpdateSpace-setting-key">Temperature Variability:</div>
        <div className="UpdateSpace-input">
          <input
            type="number"
            value={temperatureSpatialVariability}
            onChange={(evt) => {
              const val = Number.parseInt(evt.target.value) || '';
              setTemperatureSpatialVariability(val);
              const spaceVal = val || 0;
              space.setTemperatureSpatialVariability(uiStore.toCelsiusDelta(spaceVal));
            }}
          />{uiStore.preferredTemperatureUnit}
        </div>
        {formErrors.temperature_spatial_variability &&
          <ErrorText>{formErrors.temperature_spatial_variability}</ErrorText>
        }
      </div> */}
      <div className="UpdateSpace-setting">
        <Slider
          value={humidityRange}
          title="Humidity"
          minDistance={5}
          unitIndicator="%"
          onChange={(range) => updateHumidityRange(range)}
        />
      </div>
      <div className="UpdateSpace-setting">
        <Slider
            value={vpdRange}
            title="VPD"
            unitIndicator=" kPa"
            onChange={(range) => updateVpdRange(range)}
            minDistance={0.05}
            min={0}
            max={3}
            step={0.05}
        />
      </div>
      {/* <div className="UpdateSpace-setting">
        <div className="UpdateSpace-setting-key">Humidity Variability:</div>
        <div className="UpdateSpace-input">
          <input
            type="number"
            value={humiditySpatialVariability}
            onChange={(evt) => {
              const val = Number.parseInt(evt.target.value) || '';
              setHumiditySpatialVariability(val);
              const spaceVal = val || 0;
              space.setHumiditySpatialVariability(spaceVal);
            }}
          />%
        </div>
        {formErrors.humidity_spatial_variability &&
          <ErrorText>{formErrors.humidity_spatial_variability}</ErrorText>
        }
      </div> */}
      <div className="flex flex-col md:flex-row md:items-center my-12">
        <Button className='mr-4' onPress={onSave} disabled={saveClicked}>
          {saveClicked ?
            'SAVING...'
            : space.id ? 'UPDATE SPACE' : 'CREATE NEW SPACE'
          }
        </Button>
        <br/>
        {!saveClicked &&
          <CancelButton onCancel={() => {
            space.discardChanges();
            navigate(-1);
          }} />
        }
      </div>
      {Object.keys(formErrors).length > 0 &&
        <div>
          <ErrorText>Check for errors in the fields above</ErrorText>
        </div>
      }
    </MainContentView>
  );
});

export default UpdateSpace;
