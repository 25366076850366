import React, { useState, useEffect, useRef } from 'react';
import { MdModeEdit } from 'react-icons/md';
import './PlainTextEditor.css';

type onChangeType = (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
type onBlurType = () => void | null;
interface PlainTextEditorProps {
  onChange: onChangeType;
  onBlur?: onBlurType;
  text: string;
  value: string;
}

function PlainTextEditor(props: PlainTextEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(props.value);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      const length = localValue.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [isEditing, localValue]);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(evt.target.value);
    props.onChange(evt);
  };

  const handleBlur = () => {
    props.onChange({ target: { value: localValue } } as React.ChangeEvent<HTMLTextAreaElement>);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <>
        <div className='PlainTextEditor-container'>
          <textarea
            autoFocus
            ref={textareaRef}
            onBlur={handleBlur}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                handleBlur();
              }
            }}
            className="PlainTextEditor-textarea"
            value={localValue}
            onChange={handleChange}
          />
        </div>
    </>
  );
}

export default PlainTextEditor;
