import React, { useState } from 'react';
import './Space.css';
import { observer } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRootStore } from '../StoreProvider';
import BackButton from '../BackButton';
import DeleteWithConfirmation from '../DeleteWithConfirmation';
import Edit from '../Edit';
import MainContentView from '../MainContentView';
import NotFound from '../NotFound';
import StatBox from '../StatBox';

const Space = observer(function Space() {
  const { spaceStore, uiStore } = useRootStore();
  const { id } = useParams();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const navigate = useNavigate();

  if (id === undefined) {
    return <NotFound />;
  }

  const space = spaceStore.spaces.get(id);
  if (!space) {
    return <NotFound />;
  }

  const onDelete = () => {
    setDeleteInProgress(true);
    space.remove().then(resp => {
      if (resp.status === 204) {
        navigate('/settings');
      } else {
        setDeleteInProgress(false);
      }
    });
  }

  if (deleteInProgress) {
    return <MainContentView><h2>Deleting space...</h2></MainContentView>;
  }

  return (
    <MainContentView>
      <div className="Space">
        <BackButton to="/settings">Back to Settings</BackButton>
        <div className="Space-header">
          <h1 className="Space-name">{space.name}</h1>
          <Edit to="update" />
          <DeleteWithConfirmation
            key={space.id}
            onDelete={onDelete}
            itemName={space.name}
            itemType={"space"}
          />
        </div>
        <div className="Space-statbox">
          <StatBox title="Normal Temperature Range">
            {uiStore.displayTemperatureString(space.temperature_lower_bound, 0)} - {uiStore.displayTemperatureString(space.temperature_upper_bound, 0)}
          </StatBox>
          {/* <StatBox title="Allowed Temperature Variability">
            {uiStore.displayTemperatureDeltaString(space.temperature_spatial_variability, 2)}
          </StatBox> */}
          <StatBox title="Normal Humidity Range">
            {space.humidity_lower_bound}% - {space.humidity_upper_bound}%
          </StatBox>
          <StatBox title="Normal VPD Range">
            {space.vpd_lower_bound} kPa - {space.vpd_upper_bound} kPa
          </StatBox>
          {/* <StatBox title="Allowed Humidity Variability">
            {space.humidity_spatial_variability}%
          </StatBox> */}
        </div>
      </div>
    </MainContentView>
  );
});

export default Space;
