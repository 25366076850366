import React from 'react'
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import './Edit.css';

interface EditProps {
  to: string;
}

function Edit(props: EditProps) {
  const navigate = useNavigate();
  const pushRoute = () => navigate(props.to);
  return (
    <div onClick={pushRoute} className="Edit">
      <MdEdit />
    </div>
  );
}

export default Edit;
