import React, { useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import './NumberEditor.css';

type onChangeType = (num: number) => void;
interface NumberEditorProps {
  onChange: onChangeType;
  text: string;
  value: number; // Changed to number type
}

function NumberEditor(props: NumberEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(props.value.toFixed(2));

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value);
  };

  const handleBlur = () => {
    const newValue = parseFloat(inputValue);
    if (!isNaN(newValue)) {
      props.onChange(newValue); // Send the new number value to the parent component
    } else props.onChange(0)
    setIsEditing(false);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <>
      {isEditing && (
        <div>
          <input
            autoFocus
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            className="NumberEditor-input"
            type="number"
            min="0"
            defaultValue={0}
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
      )}
      {!isEditing && (
        <div className="NumberEditor-text flex" onClick={() => setIsEditing(true)}>
          {props.text} <MdModeEdit className="mx-2" />
        </div>
      )}
    </>
  );
}

export default NumberEditor;
