export const downloadCSV = (csvString: string, filename: string) => {
    var blob = new Blob([csvString], { type: "text/csv" });
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  }

 export const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toISOString(); // or any other date format you prefer
  };


 export const capCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
