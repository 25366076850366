import React, { useState, useEffect, memo, useCallback, useRef } from "react";
import "./SensorPack.css";
import { observer } from "mobx-react";
import { useRootStore } from "../StoreProvider";
import { Link } from "react-router-dom";
import BackButton from "../BackButton";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import StatBox from "../StatBox";
import TitleEditor from "../TitleEditor";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Sensor } from "../stores/SensorStore";
import httpClient from "../HTTPClient";
import Modal from "react-modal";
import Button from "../Button";
import CancelButton from "../CancelButton";
import { SensorPackErrors, validateSensorPackForm } from "../FormValidators";
import ErrorText from "../ErrorText";

const SensorPack = observer(function SensorPack() {
  const [sensorData, setSensorData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { sensorStore, dryStore } = useRootStore();
  const { id } = useParams();
  const initialErrors: SensorPackErrors = {};
  const sensorPack = sensorStore.sensorPacks.get(id ?? "");
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [disableSensorMenus, setDisableSensorMenus] = useState<any>();
  const [aliasInput, setAliasInput] = useState(sensorPack?.alias ?? "");
  const [isEditing, setIsEditing] = useState(false);
  const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const handleMenu = (id: any, state: boolean) => {
    setDisableSensorMenus((prevState: any) => ({
      ...prevState,
      [id]: state,
    }));
  };

  const sensorPolling = useCallback(async () => {
    if (id === undefined) return;
    const res = await dryStore.fetchSensorsInStore(id);
    const newData = res.data;
    const sorted = newData.sort((a: any, b: any) =>
      a.alias.localeCompare(b.alias)
    );
    // Update sensorData only when there is a change

    if (JSON.stringify(sensorData) != JSON.stringify(newData)) {
      setSensorData(sorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id === undefined) return;
    sensorPolling();
    intervalIdRef.current = setInterval(sensorPolling, 10000);
    return () => {
      clearInterval(intervalIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (id === undefined) {
    return <NotFound />;
  }

  if (!sensorPack) {
    return <NotFound />;
  }

  const toggleEnabled = async (sensorId: string, enabled: boolean) => {
    try {
      const response = await httpClient.apiPut(
        `/packs/${sensorPack.id}/sensors/${sensorId}`,
        JSON.stringify({ enabled: !enabled, buzzer_on: false })
      );
      return { isError: false, data: response.data };
    } catch (error) {
      return { isError: true, data: error };
    }
  };

  const CustomToggle = ({
    row,
    setCustomToggleEnabled,
  }: {
    row: Sensor;
    setCustomToggleEnabled: (enabled: boolean) => void;
  }) => {
    const [enabled, setEnabled] = useState(row.enabled);
    const [modalOpen, setModalOpen] = useState(false);

    const handleToggleOnChange = async () => {
      handleMenu(row.id, row.enabled);
      if (enabled) {
        setModalOpen(true);
      } else {
        const newEnabled = !enabled;
        const res = await toggleEnabled(row.id, row.enabled);
        if (!res.isError) {
          setEnabled(newEnabled);
          setCustomToggleEnabled(newEnabled);
        }
      }
    };

    const handleDisable = async () => {
      const newEnabled = !enabled;
      const res = await toggleEnabled(row.id, row.enabled);
      if (!res.isError) {
        setEnabled(newEnabled);
        setCustomToggleEnabled(newEnabled);
        setModalOpen(false);
      }
    };

    useEffect(() => {
      if (disableSensorMenus?.[row.id]) {
        setModalOpen(true);
      }
    }, []);

    return (
      <>
        <div>
          <label className="switch">
            <input
              type="checkbox"
              checked={enabled}
              onChange={handleToggleOnChange}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => {
            handleMenu(row.id, false);
            setModalOpen(false);
          }}
          className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 42, 64, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            },
          }}
          contentLabel="Example Modal"
        >
          <h1 className="modal-title break-words">Disable Sensor - {row.id}</h1>
          <p className="modal-description">
            Disabling Sensors immediately terminates sensor data collection. Are
            you sure you want to disable the sensor?
          </p>
          <div className='w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4'>
            <Button
              onPress={() => {
                setModalOpen(false);
                handleMenu(row.id, false);
              }}
            >
              No
            </Button>
            <Button
              className="Button-red"
              onPress={() => {
                handleDisable();
                handleMenu(row.id, false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      </>
    );
  };

  const GoodIndicator = ({ is_good }: { is_good: boolean }) => {
    const is_good_style = {
      display: "inline-block",
      backgroundColor: "#35B44C",
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      justifyContent: "center",
    };

    const not_is_good_style = {
      display: "inline-block",
      backgroundColor: "crimson",
      borderRadius: "50%",
      width: "2rem",
      height: "2rem",
      justifyContent: "center",
    };
    return (
      <>
        <div
          className="GoodIndicator"
          style={is_good ? is_good_style : not_is_good_style}
        ></div>
      </>
    );
  };

  function calculateDifferenceInMinutes(date: Date): string {
    const currentTime = new Date();
    const differenceInSeconds = Math.floor(
      (currentTime.getTime() - date.getTime()) / 1000
    ); // Difference in seconds

    const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
    const hours = Math.floor(
      (differenceInSeconds % (60 * 60 * 24)) / (60 * 60)
    );
    const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
    if (days > 7) {
      return `Over a Week`;
    } else if (days <= 7 && days > 0) {
      return `${days} d, ${hours} hr, ${minutes} min`;
    } else if (days === 0 && hours > 0) {
      return `${hours} hr, ${minutes} min`;
    } else {
      return `${minutes} min`;
    }
  }

  const sensorColumns: any = [
    {
      name: "Sensor ID",
      selector: (row: Sensor) => row.id,
      cell: (row: Sensor) => (<span>{row.id}</span>),
    },
    {
      name: "Alias",
      selector: (row: Sensor, index: number) => row.alias,
      id: "alias",
      sortable: true,
    },
    {
      name: "Online",
      selector: (row: Sensor) => <GoodIndicator is_good={row.is_online} />,
    },
    {
      name: "Plant Connected",
      selector: (row: Sensor) => (
        <GoodIndicator is_good={row.plant_connected} />
      ),
    },
    {
      name: "Time Since Last Message",
      selector: (row: Sensor) => row.last_alive,
      cell: (row: Sensor) => (
        <div>{calculateDifferenceInMinutes(new Date(row.last_alive))}</div>
      ),
    },
    {
      name: "Enabled",
      cell: (row: Sensor) => (
        <CustomToggle
          row={row}
          setCustomToggleEnabled={(enabled) => (row.enabled = enabled)}
        />
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "4rem", // override the row height
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const onSave = () => {
    const errors = validateSensorPackForm(sensorPack);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      sensorPack
        .save()
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            setLoading(false);
            setIsEditing(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  return (
    <MainContentView>
      <BackButton to="/settings">Back to Settings</BackButton>
      <div className="my-8">
        <div className="flex flex-col md:flex-row items-start space-x-2">
          <TitleEditor
            title={sensorPack?.alias}
            onChange={(evt) => {
              sensorPack.setAlias(evt.target.value);
            }}
            setIsEditingFromOutside={setIsEditing}
            onBlur={() => {}}
            value={sensorPack?.alias}
          />
          <br />
          {isEditing && (
            <div className="flex flex-col md:flex-row">
              <CancelButton
                onMouseDown={() => onCancel()}
                onCancel={() => {}}
              />
              <Button
                className="my-2 md:my-0 md:ml-4"
                onPress={() => {}}
                onMouseDown={onSave}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        {formErrors.alias && <ErrorText>{formErrors.alias}</ErrorText>}
        <h2>Sensor Pack ID: {sensorPack.id}</h2>
      </div>
      {sensorPack.assignedDry && (
        <h2>
          Assigned to:&nbsp;
          <Link to={`/home/dries/${sensorPack.assignedDry.id}`}>
            {sensorPack.assignedDry.name}
          </Link>
        </h2>
      )}
      <div className="SensorPack-stat-boxes">
        <StatBox title="Sensor Count">{sensorPack.sensors.length}</StatBox>
      </div>
      <div>
        <h2>Sensors</h2>
        <DataTable
          columns={sensorColumns}
          data={sensorData}
          customStyles={customStyles}
          defaultSortFieldId={"alias"}
        />
      </div>
    </MainContentView>
  );
});

export default SensorPack;
