import React from 'react';
import './Sensor.css';
import { observer } from 'mobx-react';
import { useRootStore } from '../StoreProvider';
import { Link } from 'react-router-dom';
import BackButton from '../BackButton';
import MainContentView from '../MainContentView';
import NotFound from '../NotFound';
import StatBox from '../StatBox';
import { useParams } from 'react-router-dom';

const Sensor = observer(function Sensor() {
  const { sensorStore } = useRootStore();
  const { id } = useParams();

  if (id === undefined) {
    return <NotFound />;
  }

  const sensor = sensorStore.sensors.get(id);
  if (!sensor) {
    return <NotFound />;
  }

  return (
    <MainContentView>
      <BackButton to="/settings">Back to Settings</BackButton>
      <h1 className="title">{sensor.id}</h1>
      {sensor.assignedDry &&
        <h2>
          Assigned to:&nbsp;
          <Link to={`/home/dries/${sensor.assignedDry.id}`}>{sensor.assignedDry.name}</Link>
        </h2>
      }
      <div className="Sensor-stat-boxes">
        <StatBox title="Battery">
          {sensor.batteryLevelName}
        </StatBox>
      </div>
    </MainContentView>
  );
});

export default Sensor;
