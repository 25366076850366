import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import HTTPClient from "../HTTPClient";
import moment from "moment";
import {
  CelsiusTemperature,
  CelsiusTemperatureDelta,
  DryServerJSONType,
  DryServerWritableJSONType,
  DryTimeseriesDataType,
  DryEventType,
  NoteType,
  DryIndividualSensorData,
  IndividualSensorData,
  IdealDryData,
  NoteServerWritableJSONType

} from "../types";
   // Interface for the structure of timestamps in the response
  interface SensorTimestamp {
    timestamp: string; // ISO string format
  }
  
  // Since temperature is directly provided as a number, the structure below matches the provided data
  interface SensorTemperature {
    temperature: number;
  }
  
  // Humidity data structure, assuming humidity is directly provided as a number
  interface SensorHumidity {
    humidity: number;
  }
  
  // Interface for the API fetch response, including the sensor data and pagination token
  interface FetchResponse extends DryIndividualSensorData {
    NextToken: string | null;
  }

  
class DryStore {
  userDries = new Map<string, Dry>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

    // Create a new note for a specific Dry
    createNote(dryId: string, noteJSON: string) {
        return HTTPClient.apiPost(`/dries/${dryId}/notes`, noteJSON)
          .then((res) => {
            const dry = this.userDries.get(dryId);
            if (dry) {
              dry.noteDataLoaded = false;
              dry.fetchNoteData();
            }
            return res
          })
          .catch((err) => {
            if (err.response!.status >= 500) {
              this.setHasError();
            }
            return err
          });
      }
      


  // Update an existing note for a specific Dry
  updateNote(dryId: string, noteId: string, noteJSON: string) {
    return HTTPClient.apiPut(`/dries/${dryId}/notes/${noteId}`, noteJSON)
      .then((res) => {
        const dry = this.userDries.get(dryId);
        if (dry) {
          dry.noteDataLoaded = false;
          dry.fetchNoteData();
        }
        return res
      })
      .catch((err) => {
        if (err.response!.status >= 500) {
          this.setHasError();
        }
        return err
      });
  }

  // Delete an existing note for a specific Dry
  deleteNote(dryId: string, noteId: string) {
    return HTTPClient.apiDelete(`/dries/${dryId}/notes/${noteId}`)
      .then((res) => {
        const dry = this.userDries.get(dryId);
        if (dry) {
           dry.fetchNoteData();
        }
      })
      .catch((err) => {
        if (err.response!.status >= 500) {
          this.setHasError();
        }
      });
  }

  updateDryFromServer(json: DryServerJSONType) {
    let dry = this.userDries.get(json.id);
    if (!dry) {
      dry = new Dry(this);
    }
    dry.updateFromJSON(json);
    // check for changes to needsattention field
    if (dry.needsattention !== json.needsattention) {
      dry.needsattention = json.needsattention;
    }
    this.userDries.set(dry.id, dry);
  }

  createDry() {
    return new Dry(this);
  }

  fetchDries() {
    const dryStore = this;
    HTTPClient.apiGet(`/dries`)
      .then((res) => {
        res.data.forEach(function (d: DryServerJSONType) {
          dryStore.updateDryFromServer(d);
        });
        this.setLoaded();
      })
      .catch((err) => {
        console.log(err)
        if(err.response!.status >= 500) {
          dryStore.setHasError();
        }
      });
  }

  fetchSensorsInStore(packId: string) {
    const updateMethod = HTTPClient.apiGet;
    const updateUrl = `/packs/${packId}/sensors`;
    return updateMethod(updateUrl)
      .then((dryResp) => {
        return dryResp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.setHasError();
        }
        return err.response;
      });
  }

  // This method exists just for the poller, which is how we have to check if
  // any of the dries' need_attention state has changed, and as a bonus it will
  // populate new dries. Using fetchDries would kill local state changes.
  fetchUpdatedDries() {
    const dryStore = this;
    HTTPClient.apiGet(`/dries`).then((res) => {
      res.data.forEach(function (d: DryServerJSONType) {
        dryStore.updateDryFromServer(d);
        // const existingDry = dryStore.userDries.get(d.id);
        // if (existingDry && (existingDry.needsattention !== d.needsattention)) {
        //   dryStore.updateDryFromServer(d);
        // }
        // // new dry was added from somewhere else
        // if (!existingDry) {
        //   dryStore.updateDryFromServer(d);
        // }
      });
      this.setLoaded();
    }).catch((err) => {
      if(err.response!.status >= 500) {
        dryStore.setHasError();
      }
    });
  }

  get ongoingDries() {
    const dries = Array.from(this.userDries.values());
    return dries
      .filter((d) => d.actionStatus === "ONGOING")
      .sort((d1, d2) => {
        if (d1.starttime && d2.starttime) {
          return (
            new Date(d1.starttime).getTime() - new Date(d2.starttime).getTime()
          );
        }
        return 0;
      });
  }

  get notStartedDries() {
    const dries = Array.from(this.userDries.values());
    return dries.filter((d) => d.actionStatus === "NOT_STARTED");
  }

  get completedDries() {
    const dries = Array.from(this.userDries.values());
    return dries
      .filter((d) => d.actionStatus === "COMPLETED")
      .sort((d1, d2) => {
        if (d1.stoptime && d2.stoptime) {
          return (
            new Date(d2.stoptime).getTime() - new Date(d1.stoptime).getTime()
          );
        }
        return 0;
      });
  }

  get idealDryCandidates() {
    const dries = Array.from(this.userDries.values());
    const candidates = dries
      .filter((d) => {
        if (d.actionStatus === "COMPLETED" && d.starttime && d.stoptime) {
          const startTime = new Date(d.starttime).getTime();
          const stopTime = new Date(d.stoptime).getTime();
          const duration = stopTime - startTime;
          const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
          return duration > threeDaysInMilliseconds;
        }
        return false;
      })
      .sort((d1, d2) => {
        if (d1.starttime && d2.starttime) {
          return (
            new Date(d2.starttime).getTime() - new Date(d1.starttime).getTime()
          );
        }
        return 0;
      });

      candidates.forEach((dry) => {
        if (!dry.timeseriesDataLoaded ){
            dry.fetchTimeseriesData();
        }
      });

      return candidates;
  }

  get showNeedsAttention() {
    const dries = Array.from(this.userDries.values());
    return !!dries.find((d) => d.needsattention);
  }
}

export class Dry {
  constructor(parentStore: DryStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: DryStore;
  id = "";
  name = "";
  lot_number: string | undefined;
  wet_weight: number = 0;
  dry_weight: number = 0;
  actionStatus: "NOT_STARTED" | "ONGOING" | "COMPLETED" = "NOT_STARTED";
  starttime: string | undefined;
  stoptime: string | undefined;
  strain_id: string | undefined;
  space_id: string | undefined;
  moisture: number | undefined;
  needsattention = false;
  temperature: CelsiusTemperature | undefined;
  humidity: number | undefined;
  vpd: number | undefined;
  humidity_upper_bound = 50;
  humidity_lower_bound = 10;
  humidity_spatial_variability = 5;
  vpd_upper_bound = 2.9;
  vpd_lower_bound = 0.3;
  vpd_spatial_variability = 5;
  moisture_target = 13;
  moisture_spatial_variability = 5;
  persistedJSON = "";
  temperature_upper_bound = new CelsiusTemperature(80);
  temperature_lower_bound = new CelsiusTemperature(40);
  temperature_spatial_variability = new CelsiusTemperatureDelta(5);
  sensor_pack_ids: Array<string> = [];
  pack_ids: Array<string> = [];
  // Data fetched/updated as part of subscriptions
  timeseriesData: Array<DryTimeseriesDataType> = [];
  individualTimeseriesData: DryIndividualSensorData = {timestamps: [], data: []};
  eventData: Array<DryEventType> = [];
  noteData: Array<Note> = [];
  ideal_dry_data: IdealDryData = {time_elapsed_hrs:[], upper_bound: [], lower_bound: []};
  timeseriesDataLoaded = false;
  noteDataLoaded = false;
  noteDataLoading = false;
  individualTimeseriesDataLoaded = false;
  individualTimeseriesDataLoading = false;
  eventDataLoaded = false;
  lastSyncUnixTimestamp: number | undefined;

  private timeoutId: null | ReturnType<typeof setTimeout> = null;

  private subscriptionPollerId: null | ReturnType<typeof setInterval> = null;
  private individualSubscriptionPollerId: null | ReturnType<typeof setInterval> = null;

  addSensorPack(sensor_pack_id: string) {
    // this.sensor_pack_ids.push(sensor_pack_id);
    // Ignore if it's already included
    if (!this.sensor_pack_ids.find((id) => sensor_pack_id === id)) {
      this.sensor_pack_ids.push(sensor_pack_id);
    }
    if (!this.pack_ids.find((id) => sensor_pack_id === id)) {
      this.pack_ids.push(sensor_pack_id);
    }
  }

  removeSensorPack(sensor_pack_id: string) {
    this.sensor_pack_ids = this.sensor_pack_ids.filter(
      (sId) => sId !== sensor_pack_id
    );
  }

  discardChanges() {
    if (this.persistedJSON) {
      const json = JSON.parse(this.persistedJSON);
      json.needsattention = this.needsattention;
      json.starttime = this.starttime;
      json.stoptime = this.stoptime;
      json.pack_ids = this.sensor_pack_ids;
      json.humidity = this.humidity;
      json.vpd = this.vpd;
      json.moisture = this.moisture;
      this.updateFromJSON(json);
    }
  }

  setName(name: string) {
    this.name = name;
  }

  setLotNum(lot_number: string) {
    if (lot_number === "") {
      this.lot_number = undefined
    }
    this.lot_number = lot_number;
  }

  setDryWeight(weight: number) {
   this.dry_weight = weight
  }

  setWetWeight(weight: number) {
    this.wet_weight = weight
  }

  setStrain(strain_id: string) {
    this.strain_id = strain_id;
    // Applies initial values based on defaults from strain
    const strain = this.store.rootStore.strainStore.strains.get(strain_id);
    if (strain) {
      this.moisture_target = strain.moisture_target;
    }
  }

  setSpace(space_id: string) {
    this.space_id = space_id;
    const space = this.store.rootStore.spaceStore.spaces.get(space_id);
    if (space) {
      this.humidity_upper_bound = space.humidity_upper_bound;
      this.humidity_lower_bound = space.humidity_lower_bound;
      this.temperature_upper_bound = space.temperature_upper_bound;
      this.temperature_lower_bound = space.temperature_lower_bound;
      this.vpd_upper_bound = space.vpd_upper_bound;
      this.vpd_lower_bound = space.vpd_lower_bound;
    }
  }

  setTemperatureUpperBound(bound: number) {
    this.temperature_upper_bound.degrees = bound;
  }

  setTemperatureLowerBound(bound: number) {
    this.temperature_lower_bound.degrees = bound;
  }

  setHumidityUpperBound(bound: number) {
    this.humidity_upper_bound = bound;
  }

  setHumidityLowerBound(bound: number) {
    this.humidity_lower_bound = bound;
  }

  setVpdUpperBound(bound: number) {
    this.vpd_upper_bound = bound;
  }

  setVpdLowerBound(bound: number) {
    this.vpd_lower_bound = bound;
  }

  setMoistureTarget(moisture: number) {
    this.moisture_target = moisture;
  }

  setMoistureSpatialVariability(variability: number) {
    this.moisture_spatial_variability = variability;
  }

  setTemperatureSpatialVariability(variability: number) {
    this.temperature_spatial_variability.degrees = variability;
  }

  setHumiditySpatialVariability(variability: number) {
    this.humidity_spatial_variability = variability;
  }

  start() {
    this.actionStatus = "ONGOING";
    this.save();
  }

  stop() {
    this.actionStatus = "COMPLETED";
    this.save();
  }

  updateFromJSON(json: DryServerJSONType) {
    this.id = json.id;
    this.name = json.name;
    this.lot_number = json.lot_number;
    this.dry_weight = json.dry_weight;
    this.wet_weight = json.wet_weight;
    this.actionStatus = json.status;
    this.starttime = json.starttime;
    this.stoptime = json.stoptime;
    this.strain_id = json.strain_id;
    this.space_id = json.space_id;
    this.needsattention = json.needsattention;
    this.humidity = json.humidity;
    this.vpd = json.vpd;
    this.humidity_lower_bound = json.configuration.humidity.lowerbound;
    this.humidity_spatial_variability =
      json.configuration.humidity.spatial_variability;
    this.humidity_upper_bound = json.configuration.humidity.upperbound;
    this.vpd_lower_bound = json.configuration.vpd.lowerbound;
    this.vpd_spatial_variability =
      json.configuration.vpd.spatial_variability;
    this.vpd_upper_bound = json.configuration.vpd.upperbound;
    this.moisture = json.moisture;
    this.moisture_spatial_variability =
      json.configuration.moisture.spatial_variability;
    this.moisture_target = json.configuration.moisture.target;
    this.temperature_lower_bound.degrees =
      json.configuration.temperature.lowerbound;
    this.temperature_upper_bound.degrees =
      json.configuration.temperature.upperbound;
    this.temperature_spatial_variability.degrees =
      json.configuration.temperature.spatial_variability;
    this.sensor_pack_ids = json.pack_ids || [];
    if (json.temperature) {
      this.temperature = new CelsiusTemperature(json.temperature);
    }
    if (json.ideal_dry_data !== null && json.ideal_dry_data !== undefined){
        this.ideal_dry_data = json.ideal_dry_data;
    }

    this.persistedJSON = this.asJSON;
  }

  // The API doesn't handle changing sensors on dry update
  get removedSensorPackIds() {
    if (!this.persistedJSON) {
      return [];
    }
    const persistedData = JSON.parse(this.persistedJSON);
    const persistedIds = persistedData.pack_ids || [];
    const localIds = this.sensor_pack_ids || [];
    const removedIds: Array<string> =
      persistedIds.filter((sId: string) => !localIds.includes(sId)) || [];
    return removedIds;
  }

  // The API doesn't handle changing sensors on dry create/update
  get addedSensorPackIds() {
    if (!this.persistedJSON) {
      return this.sensor_pack_ids || [];
    }
    const persistedData = JSON.parse(this.persistedJSON);
    const persistedIds = persistedData.sensor_pack_ids || [];
    const localIds = this.sensor_pack_ids || [];
    const addedIds: Array<string> =
      localIds.filter((sId: string) => !persistedIds.includes(sId)) || [];
    return addedIds;
  }

  save() {
    const data = JSON.parse(this.asJSON);
    if (this.lot_number === "") {
      data.lot_number = null
    }   
    if (!this.id) {
      delete data.id;
      delete data.dry_weight
    }
    const payload = JSON.stringify(data);
    const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    const updateUrl = this.id ? `/dries/${this.id}` : "/dries";
    const addedSensorPackIds = this.addedSensorPackIds;
    const removedSensorPackIds = this.removedSensorPackIds;
    return updateMethod(updateUrl, payload)
      .then((dryResp) => {
        this.store.updateDryFromServer(dryResp.data);
        this.store.fetchDries();
        return dryResp;
        // addedSensorPackIds.forEach((sId) => {
        //   HTTPClient.apiPost(
        //     `/dries/${dryResp.data.id}/packs`,
        //     JSON.stringify({ packId: sId })
        //   ).then((addResp) => {
        //     // We add these back because they'll be removed by the call to
        //     // updateDryFromServer above
        //     dryResp.data.pack_ids.push(sId);
        //     this.store.updateDryFromServer(dryResp.data);
        //   });
        // });

        // if ((this.actionStatus = "COMPLETED")) {
        //   removedSensorPackIds.forEach((sId) => {
        //     HTTPClient.apiDelete(`/dries/${dryResp.data.id}/packs/${sId}`).then(
        //       (removeResp) => {
        //         const index = dryResp.data.pack_ids.indexOf(sId);
        //         dryResp.data.pack_ids.splice(index, 1);
        //         this.store.updateDryFromServer(dryResp.data);
        //       }
        //     );
        //   });
        // }
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  fetchSensors(packId: string) {
    const updateMethod = HTTPClient.apiGet;
    const updateUrl = `/packs/${packId}/sensors`;
    return updateMethod(updateUrl)
      .then((dryResp) => {
        return dryResp;
        // HTTPClient.apiGet(`/packs/${dryResp?.data?.id}/sensors`).then((res) => {
        //   console.log(res, "inside fetch sensor then");
        //   return res;
        // });
        // .catch((err) => {
        //   console.log("inside err");
        //   return err;
        // });
        // return dryResp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  // fetchSensors(packId: string) {
  //   HTTPClient.apiGet(`/packs/${packId}/sensors`)
  //     .then((res) => {
  //       console.log(res, "inside fetch sensor then");
  //       return res;
  //     })
  //     .catch((err) => {
  //       console.log("inside err");
  //       return err;
  //     });
  // }

  updateSensorsInDB(sensorPackId: string) {
    const payload = JSON.stringify({ packId: sensorPackId });
    const updateMethod = HTTPClient.apiPost;

    const updateUrl = `/dries/${this.id}/packs`;
    return updateMethod(updateUrl, payload)
      .then((dryResp) => {
        return dryResp;
        // HTTPClient.apiGet(`/packs/${dryResp?.data?.id}/sensors`).then((res) => {
        //   console.log(res, "inside fetch sensor then");
        //   return res;
        // });
        // .catch((err) => {
        //   console.log("inside err");
        //   return err;
        // });
        // return dryResp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  remove() {
    return HTTPClient.apiDelete(`/dries/${this.id}`)
      .then((resp) => {
        if (resp.status === 204) {
          this.store.userDries.delete(this.id);
        }
        return resp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  removeSensorPacks(packId: string) {
    return HTTPClient.apiDelete(`/dries/${this.id}/packs/${packId}`)
      .then((resp) => {
        if (resp.status === 204) {
          this.sensor_pack_ids = this.sensor_pack_ids.filter(
            (sId) => sId !== packId
          );
          this.pack_ids = this.pack_ids.filter((sId) => sId !== packId);
        }
        return resp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        if (err.response.status === 409) {
            this.sensor_pack_ids = this.sensor_pack_ids.filter(
              (sId) => sId !== packId
            );
            this.pack_ids = this.pack_ids.filter((sId) => sId !== packId);
        }
        return err.response;
      });
  }

  setEventData(data: Array<DryEventType>) {
    data.forEach((d) => {
      d.timestamp = moment(d.timestamp).valueOf(); // date -> epoch
    });
    data = data.sort((a, b) =>
      a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0
    );
    this.eventData = data;
    this.eventDataLoaded = true;
  }

  setTimeseriesData(data: Array<DryTimeseriesDataType>) {
    data.forEach((d) => {
      d.timestamp = moment(d.timestamp).valueOf(); // date -> epoch
    });
    
    this.lastSyncUnixTimestamp = data[data.length-1]?.timestamp
    this.timeseriesData = data;
    this.timeseriesDataLoaded = true;
  }

  fetchTimeseriesData() {
    this.timeseriesDataLoaded = true;
    // this.lastSyncUnixTimestamp = new Date().getTime();
    HTTPClient.apiGet(`/dries/${this.id}/stats`)
    .then((res) => {
      if (res.data) {
        this.setTimeseriesData(res.data);
      }
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.store.setHasError();
      }
    });
  }

  setNoteData(data: Array<Note>) {
    this.noteData = data;
    this.noteDataLoaded = true;
    this.noteDataLoading = false;
  }

  fetchNoteData() {
    this.noteDataLoading = true;
    this.noteDataLoaded = false;
    return HTTPClient.apiGet(`/dries/${this.id}/notes`)
      .then((res) => {
        if (res.data) {
          this.setNoteData(res.data);
        }
      })
      .catch((err) => {
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
      });
  }
  
  
  setIndividualTimeseriesData(individual_sensor_data: DryIndividualSensorData) {
    individual_sensor_data.timestamps = individual_sensor_data.timestamps.map((t) => {
        let dt = moment.utc(t, "YYYY-MM-DD HH:mm").valueOf();
        return dt // date -> epoch
    });
    this.individualTimeseriesData = individual_sensor_data;
    this.individualTimeseriesDataLoaded = true;
    this.individualTimeseriesDataLoading = false;
  }



async fetchIndividualTimeseriesData() {
    this.individualTimeseriesDataLoading = true;
    this.individualTimeseriesDataLoaded = false;
    // this.lastSyncUnixTimestamp = new Date().getTime();
  
    let allSensorData: DryIndividualSensorData = {timestamps: [], data: []};
    let NextToken: string | null = null;
    
    do {
      const axiosRes = await HTTPClient.apiGet(`/dries/${this.id}/timeseries`, { params: { NextToken } });
       
      const res: FetchResponse = {
        timestamps: axiosRes.data.timestamps,
        data: axiosRes.data.data,
        NextToken: axiosRes.data.NextToken,
      };

      // Append new timestamps
      allSensorData.timestamps.push(...res.timestamps);

      if (res.data) {
        res.data.forEach((sensor) => {
            const existingSensorIndex = allSensorData.data.findIndex(s => s.id === sensor.id);
            if (existingSensorIndex !== -1) {
                // Sensor exists, append temperatures and humidities
                allSensorData.data[existingSensorIndex].temperatures.push(...sensor.temperatures);
                allSensorData.data[existingSensorIndex].humidities.push(...sensor.humidities);
            } else {
                // New sensor, add it to the data array
                allSensorData.data.push(sensor);
            }
          });
      }
  
      NextToken = res.NextToken;

    } while (NextToken);
  
    this.setIndividualTimeseriesData(allSensorData);
}

  
  startSubscription(intervalMs = 600000) {

    clearTimeout(this.timeoutId || undefined);
  
    // Set a new timeout
    this.timeoutId = setTimeout(() => {
      // Perform the fetch action after debounce delay (e.g., 500ms)
      if (this.id && !this.subscriptionPollerId) {
        const fetchTimeseriesData = this.fetchTimeseriesData.bind(this);
        const fetchEventData = this.fetchEventData.bind(this);
        const fetchData = () => {
          fetchTimeseriesData();
          fetchEventData();
        };
        fetchData();
        this.subscriptionPollerId = setInterval(fetchData, intervalMs);
      }
    }, 500);
  }

  stopSubscription() {
    if (this.subscriptionPollerId) {
      clearInterval(this.subscriptionPollerId);
      clearTimeout(this.timeoutId || undefined);
      this.timeoutId = null
      this.subscriptionPollerId = null;
    }
  }

  startIndividualSubscription(intervalMs = 60000) { // Default is now 600,000 ms (10 minutes)
    if (this.id && !this.individualSubscriptionPollerId) {
      const fetchIndividualTimeseriesData = this.fetchIndividualTimeseriesData.bind(this);
  
      // Only call fetchIndividualTimeseriesData
      const fetchData = () => {
        fetchIndividualTimeseriesData();
      };
  
      fetchData(); // Initial fetch to ensure data is fetched once immediately
      this.individualSubscriptionPollerId = setInterval(fetchData, intervalMs); // Subsequent fetches every 10 minutes
    }
  }

  addNote(note: string) {
    const resp = this.store.createNote(this.id, note);
    return resp;
  }

  updateNote(noteId: string, note: string) {
    const resp = this.store.updateNote(this.id, noteId, note);
    return resp;
  }

  deleteNote(noteId: string) {
    this.store.deleteNote(this.id, noteId);
  }

  stopIndividualSubscription() {
    if (this.individualSubscriptionPollerId) {
      clearInterval(this.individualSubscriptionPollerId);
      this.individualSubscriptionPollerId = null; // Clear the interval ID to stop polling
    }
  }

  fetchEventData() {
    this.eventDataLoaded = true;
    HTTPClient.apiGet(`/dries/${this.id}/events`)
    .then((res) => {
      if (res.data) {
        this.setEventData(res.data);
      }
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.store.setHasError();
      }
    });
  }

  get asJSON() {
    const jsonData: DryServerWritableJSONType = {
      id: this.id,
      name: this.name,
      lot_number: this.lot_number,
      dry_weight: this.dry_weight || 0,
      wet_weight: this.wet_weight || 0,
      status: this.actionStatus,
      strain_id: this.strain_id,
      space_id: this.space_id,
      // pack_ids: this.pack_ids,
      // sensor_pack_ids: this.sensor_pack_ids,
      configuration: {
        temperature: {
          upperbound: this.temperature_upper_bound.degrees,
          lowerbound: this.temperature_lower_bound.degrees,
          spatial_variability: this.temperature_spatial_variability.degrees,
        },
        humidity: {
          upperbound: this.humidity_upper_bound,
          lowerbound: this.humidity_lower_bound,
          spatial_variability: this.humidity_spatial_variability,
        },
        vpd: {
          upperbound: this.vpd_upper_bound,
          lowerbound: this.vpd_lower_bound,
          spatial_variability: this.vpd_spatial_variability,
        },
        moisture: {
          target: this.moisture_target,
          spatial_variability: this.moisture_spatial_variability,
        },
      },
    };
    return JSON.stringify(jsonData);
  }

  get strain() {
    const { strainStore } = this.store.rootStore;
    const strain = this.strain_id
      ? strainStore.strains.get(this.strain_id)
      : undefined;
    if (!strain) {
      return undefined;
    }
    return strain;
  }

  get space() {
    const { spaceStore } = this.store.rootStore;
    const space = this.space_id
      ? spaceStore.spaces.get(this.space_id)
      : undefined;
    if (!space) {
      return undefined;
    }
    return space;
  }

  get sensorPacks() {
    const { sensorStore } = this.store.rootStore;
    if (!this.sensor_pack_ids) {
      return undefined;
    }
    return this.sensor_pack_ids.map((sId) => sensorStore.sensorPacks.get(sId));
  }
}

export class Note {
    constructor(parentStore: DryStore, public id = "", public creator = "", public type: "GENERAL" | "OTHER" = "OTHER", public timestamp = "", public details = "") {
      makeAutoObservable(this);
      this.store = parentStore;
    }
  
    store: DryStore;
    persistedJSON = "";

    setTimestamp(timestamp: string) {
        this.timestamp = timestamp;
    }

    setType(type: 'GENERAL' | 'OTHER') {
        this.type = type;
    }

    setDetails(details: string){
        this.details = details;
    }

    discardChanges() {
      if (this.persistedJSON) {
        const json = JSON.parse(this.persistedJSON);
        json.type = this.type;
        json.timestamp = this.timestamp;
        json.details = this.details;
        this.updateFromJSON(json);
      }
    }
  
    updateFromJSON(json: NoteType) {
      this.id = json.id;
      this.creator = json.creator;
      this.type = json.type;
      this.timestamp = json.timestamp;
      this.details = json.details;
  
      this.persistedJSON = this.asJSON;
    }

    // save(dryId: string) {
    //     const data = JSON.parse(this.asJSON);
    //     if (!this.id) {
    //       delete data.id;
    //     }
    //     const payload = JSON.stringify(data);
    //     const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    //     const updateUrl = this.id ? `/dries/${dryId}/notes/${this.id}` : `/dries/${dryId}/notes`;
    
    //     return updateMethod(updateUrl, payload)
    //       .then((res) => {
    //         this.store.userDries.get(dryId)?.updateNote;
    //         return res;
    //       })
    //       .catch((err) => {
    //         if (err.response!.status >= 500) {
    //           this.store.setHasError();
    //         }
    //         return err.response;
    //       });
    //   }
    
    //   remove(dryId: string) {
    //     return HTTPClient.apiDelete(`/dries/${dryId}/notes/${this.id}`)
    //       .then((res) => {
    //         if (res.status === 204) {
    //           const dry = this.store.userDries.get(dryId);
    //           if (dry) {
    //             dry.noteData = dry.noteData.filter(note => note.id !== this.id);
    //           }
    //         }
    //         return res;
    //       })
    //       .catch((err) => {
    //         if (err.response!.status >= 500) {
    //           this.store.setHasError();
    //         }
    //         return err.response;
    //       });
    //   }
  
    get asJSON() {
      const jsonData: NoteServerWritableJSONType = {
        type: this.type,
        timestamp: this.timestamp,
        details: this.details
      }

      return JSON.stringify(jsonData);
    }

  }

export default DryStore;
