import { makeAutoObservable } from "mobx";
import { IdealDryData, StrainServerJSONType, StrainServerWritableJSONType } from "../types";
import RootStore from "./RootStore";
import HTTPClient from "../HTTPClient";

class StrainStore {
  strains = new Map<string, Strain>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

  updateStrainFromServer(json: StrainServerJSONType) {
    let strain = this.strains.get(json.id);
    if (!strain) {
      strain = new Strain(this);
    }
    strain.updateFromJSON(json);
    this.strains.set(strain.id, strain);
  }

  createStrain() {
    return new Strain(this);
  }

  fetchStrains() {
    const strainStore = this;
    HTTPClient.apiGet("/strains")
      .then((res) => {
        console.log();
        res.data.forEach(function (d: StrainServerJSONType) {
          strainStore.updateStrainFromServer(d);
        });

        this.setLoaded();
      })
      .catch((err) => {
        console.log(err, "in fetch strains");
        if(err.response!.status >= 500) {
          this.setHasError();
        }
      });
  }
}

export class Strain {
  constructor(parentStore: StrainStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: StrainStore;
  id = "";
  name = "";
  // Setting values here effectively sets a default for new strains
  moisture_target = 13.5;
  moisture_spatial_variability = 5;
  ideal_dry_id: string | null = null;
  ideal_dry_data: IdealDryData = {time_elapsed_hrs:[], upper_bound: [], lower_bound: []};

  persistedJSON = "";

  setName(name: string) {
    this.name = name;
  }

  setMoistureTarget(moisture: number) {
    this.moisture_target = moisture;
  }

  setIdealDryId(ideal_dry_id: string | null) {
    this.ideal_dry_id = ideal_dry_id;
  }

  setMoistureSpatialVariability(variance: number) {
    this.moisture_spatial_variability = variance;
  }

  updateFromJSON(json: StrainServerJSONType) {
    this.id = json.id;
    this.name = json.name;
    this.moisture_target = json.moisture_configuration.target;
    this.moisture_spatial_variability =
      json.moisture_configuration.spatial_variability;

    this.ideal_dry_id = json.ideal_dry_id;
    if (json.ideal_dry_data !== null && json.ideal_dry_data !== undefined){
        this.ideal_dry_data = json.ideal_dry_data;
    }

    this.persistedJSON = this.asJSON;
  }

  discardChanges() {
    if (this.persistedJSON) {
      const json = JSON.parse(this.persistedJSON);
      this.updateFromJSON(json);
    }
  }

  save() {
    const data = JSON.parse(this.asJSON);
    if (!this.id) {
      delete data.id;
    }
    const payload = JSON.stringify(data);
    const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    const updateUrl = this.id ? `/strains/${this.id}` : "/strains";
    return updateMethod(updateUrl, payload)
      .then((resp) => {
        this.store.updateStrainFromServer(resp.data);
        return resp;
      })
      .catch((err) => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  remove() {
    return HTTPClient.apiDelete(`/strains/${this.id}`)
      .then((resp) => {
        console.log("resp", resp);
        if (resp.status === 204) {
          this.store.strains.delete(this.id);
        }

        return resp;
      })
      .catch((err) => {
        console.log(err, "in remove ");
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  get asJSON() {
    const jsonData: StrainServerWritableJSONType = {
      id: this.id,
      name: this.name,
      moisture_configuration: {
        target: this.moisture_target,
        spatial_variability: this.moisture_spatial_variability,
      },
      ideal_dry_id: this.ideal_dry_id
    };
    return JSON.stringify(jsonData);
  }
}

export default StrainStore;
