import React from 'react';
import './SensorTile.css';
import { useNavigate } from 'react-router-dom';

import { SensorPack } from '../stores/SensorStore';

interface SensorPackTileProps {
  sensorPack: SensorPack;
}

function SensorPackTile(props: SensorPackTileProps) {
  const { sensorPack } = props;
  const navigate = useNavigate();
  const className = `SensorTile ${sensorPack.inUse ? 'SensorTile-assigned' : 'SensorTile-available'}`;

  return (
    <div
      onClick={() => navigate(`/settings/sensor-packs/${sensorPack.id}`)}
      className={className}
    >
      <h4>{sensorPack.id}</h4>
      <h4>Sensors: {sensorPack.sensor_ids.length}</h4>
      {sensorPack.assignedDry &&
        <h4>Dry: {sensorPack.assignedDry.name}</h4>
      }
    </div>
  );
}

export default SensorPackTile;
