import React, { useState } from "react";
import "./Gateway.css";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useRootStore } from "../StoreProvider";
import BackButton from "../BackButton";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import moment from "moment";
import TitleEditor from "../TitleEditor";
import { GatewayErrors, validateGatewayForm } from "../FormValidators";
import CancelButton from "../CancelButton";
import Button from "../Button";
import ErrorText from "../ErrorText";

const Gateway = observer(function Strain() {
  const { gatewayStore } = useRootStore();
  const { id } = useParams();
  const gateway = gatewayStore.gateways.get(id ?? "");

  const initialErrors: GatewayErrors = {};
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [aliasInput, setAliasInput] = useState(gateway?.alias ?? "");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!gatewayStore.loaded || id === undefined) {
    return <NotFound />;
  }

  if (!gateway) {
    return <NotFound />;
  }

  const onSave = () => {
    const errors = validateGatewayForm(gateway);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      gateway
        .save()
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            setLoading(false);
            setIsEditing(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const onCancel = () => {
    setIsEditing(false);
  };
  return (
    <MainContentView>
      <BackButton to="/settings">Back to Settings</BackButton>
      <div className="my-8">
        <div className="flex flex-col md:flex-row items-start space-x-2">
          <TitleEditor
            title={gateway?.alias}
            onChange={(evt) => {
              gateway.setAlias(evt.target.value);
            }}
            value={gateway?.alias}
            setIsEditingFromOutside={setIsEditing}
            onBlur={() => {}}
          />
          {isEditing && (
            <div className="flex flex-col md:flex-row">
              <CancelButton
                onMouseDown={() => onCancel()}
                onCancel={() => {}}
              />

              <Button
                className="my-2 md:my-0 md:ml-4"
                onPress={() => {}}
                onMouseDown={onSave}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        {formErrors.alias && <ErrorText>{formErrors.alias}</ErrorText>}
        <div className="gateway-info">
          <h3>
            Status:{" "}
            <span style={{ color: gateway.online ? "#35B44C" : "crimson" }}>
              {gateway.online ? "ONLINE" : "OFFLINE"}
            </span>
          </h3>
          <h3>
            Last Message Received:{" "}
            {moment(gateway.last_uplink_received).format(
              "  MM/DD/YY hh:mm A  "
            )}
          </h3>
          <h3>ID: {gateway.id}</h3>
        </div>
      </div>
    </MainContentView>
  );
});

export default Gateway;
