import { makeAutoObservable } from 'mobx';
import { SpaceServerJSONType } from '../types';
import RootStore from './RootStore';
import HTTPClient from '../HTTPClient';
import { CelsiusTemperature, CelsiusTemperatureDelta } from '../types';

class SpaceStore {
  spaces = new Map<string, Space>();
  loaded = false;
  hasError = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setLoaded() {
    this.loaded = true;
  }

  setHasError() {
    this.hasError = true;
  }

  updateSpaceFromServer(json: SpaceServerJSONType) {
    let space = this.spaces.get(json.id);
    if (!space) {
      space = new Space(this);
    }
    space.updateFromJSON(json);
    this.spaces.set(space.id, space);
  }

  createSpace() {
    return new Space(this);
  }

  fetchSpaces() {
    const spaceStore = this;
    HTTPClient.apiGet('/spaces').then(res => {
      res.data.forEach(function(d: SpaceServerJSONType) {
        spaceStore.updateSpaceFromServer(d);
      });
      this.setLoaded();
    })
    .catch((err) => {
      if(err.response!.status >= 500) {
        this.setHasError();
      }
    });
  }
}

export class Space {
  constructor(parentStore: SpaceStore) {
    makeAutoObservable(this);
    this.store = parentStore;
  }

  store: SpaceStore;
  id = '';
  name = '';
  temperature_upper_bound = new CelsiusTemperature(30);
  temperature_lower_bound = new CelsiusTemperature(10);
  temperature_spatial_variability = new CelsiusTemperatureDelta(5);
  humidity_upper_bound = 50;
  humidity_lower_bound = 5;
  humidity_spatial_variability = 5;
  vpd_upper_bound = 2.9;
  vpd_lower_bound = 0.3;
  vpd_spatial_variability = 5;

  persistedJSON = '';

  setName(name: string) {
    this.name = name;
  }

  setTemperatureUpperBound(bound: number) {
    this.temperature_upper_bound = new CelsiusTemperature(bound);
  }

  setTemperatureLowerBound(bound: number) {
    this.temperature_lower_bound = new CelsiusTemperature(bound);
  }

  setTemperatureSpatialVariability(variance: number) {
    this.temperature_spatial_variability.degrees = variance;
  }

  setHumidityUpperBound(bound: number) {
    this.humidity_upper_bound = bound;
  }

  setHumidityLowerBound(bound: number) {
    this.humidity_lower_bound = bound;
  }

  setVpdUpperBound(bound: number) {
    this.vpd_upper_bound = bound;
  }

  setVpdLowerBound(bound: number) {
    this.vpd_lower_bound = bound;
  }

  setHumiditySpatialVariability(variance: number) {
    this.humidity_spatial_variability = variance;
  }

  updateFromJSON(json: SpaceServerJSONType) {
    this.id = json.id;
    this.name = json.name;
    this.temperature_upper_bound.degrees = json.temperature_configuration.upperbound;
    this.temperature_lower_bound.degrees = json.temperature_configuration.lowerbound;
    this.temperature_spatial_variability.degrees = json.temperature_configuration.spatial_variability;
    this.humidity_upper_bound = json.humidity_configuration.upperbound;
    this.humidity_lower_bound = json.humidity_configuration.lowerbound;
    this.humidity_spatial_variability = json.humidity_configuration.spatial_variability;
    this.vpd_upper_bound = json.vpd_configuration.upperbound;
    this.vpd_lower_bound = json.vpd_configuration.lowerbound;
    this.vpd_spatial_variability = json.vpd_configuration.spatial_variability;

    this.persistedJSON = this.asJSON;
  }

  discardChanges() {
    if (this.persistedJSON) {
      const json = JSON.parse(this.persistedJSON);
      this.updateFromJSON(json);
    }
  }

  save() {
    const data = JSON.parse(this.asJSON);
    if (!this.id) {
      delete data.id;
    }
    const payload = JSON.stringify(data);
    const updateMethod = this.id ? HTTPClient.apiPut : HTTPClient.apiPost;
    const updateUrl = this.id ? `/spaces/${this.id}` : '/spaces';
    return updateMethod(updateUrl, payload)
      .then(resp => {
        this.store.updateSpaceFromServer(resp.data);
        return resp;
      })
      .catch(err => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  remove() {
    return HTTPClient.apiDelete(`/spaces/${this.id}`)
      .then(resp => {
        if (resp.status === 204) {
          this.store.spaces.delete(this.id);
        }
        return resp;
      })
      .catch(err => {
        // This will allow the components to handle errors consistently
        // since `then` above also returns the response object
        if(err.response!.status >= 500) {
          this.store.setHasError();
        }
        return err.response;
      });
  }

  get asJSON() {
    const jsonData: SpaceServerJSONType = {
      id: this.id,
      name: this.name,
      temperature_configuration: {
        upperbound: this.temperature_upper_bound.degrees,
        lowerbound: this.temperature_lower_bound.degrees,
        spatial_variability: this.temperature_spatial_variability.degrees,
      },
      humidity_configuration: {
        upperbound: this.humidity_upper_bound,
        lowerbound: this.humidity_lower_bound,
        spatial_variability: this.humidity_spatial_variability,
      },
      vpd_configuration: {
        upperbound: this.vpd_upper_bound,
        lowerbound: this.vpd_lower_bound,
        spatial_variability: this.vpd_spatial_variability,
      },
    };
    return JSON.stringify(jsonData);
  }
}

export default SpaceStore;
