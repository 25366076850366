import React, { useState, useEffect, memo, useCallback } from "react";
import "./UsersView.css";
import { observer } from "mobx-react";
import { useRootStore } from "../StoreProvider";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import AddNewTile from "../AddNewTile";
import StatBox from "../StatBox";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserStore, { User } from "../stores/UserStore";
import Modal from "react-modal";
import Button from "../Button";
import Select from "react-select";
import DeleteWithConfirmation from "../DeleteWithConfirmation";

const UsersView = observer(function UsersView() {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { userStore, accountStore } = useRootStore();
  const users = Array.from(userStore.users.values());
  const navigate = useNavigate();
  const account = accountStore.currentAccount;

  if (!account) {
    return <NotFound />;
  }
  var usersColumns: any = [];
  
  if (deleteInProgress) {
    return <MainContentView><h2>Deleting user...</h2></MainContentView>;
  }

  if (!User) {
    return <NotFound />;
  }

  const CustomSelect = ({
        row,
        setCustomSelect,
    }: {
        row: User;
        setCustomSelect: (role: string) => void;
    }) => {
    const [selectedRole, setSelectedRole] = useState({value:row.role, label:row.role});
    const [originalRole, setOriginalRole] = useState({value:row.role, label:row.role});
    const [modalOpen, setModalOpen] = useState(false);

    const userRoleOpts = [
        {label:"ADMIN", value : "ADMIN"},
        {label:"USER", value: "USER"}
    ];

    const handleConfirm = async () => {
        // console.log('Confirmed action for option:', selectedRole);

        row.setRole(selectedRole.value);
        row
        .save()
        .then((resp) => {
          if (resp.status === 200) {
            setOriginalRole(selectedRole);
          }
          else {
            setSelectedRole(originalRole);
            setModalOpen(false);
            return;
          }
        })
        setModalOpen(false);
    };

    const handleChange = (selectedOption: any) => {
        setSelectedRole(selectedOption);
        setModalOpen(true);
    }
    
    const handleCancel = () => {
        setSelectedRole(originalRole); 
        setModalOpen(false); 
    }
    
    return (
        <>
          <div>
            <Select
            value={selectedRole}
            onChange={handleChange}
            options={userRoleOpts}
            className="text-black w-36"
            placeholder="Select Role Here"
            />
          </div>
          <Modal
            isOpen={modalOpen}
            onRequestClose={() => {
              setModalOpen(false);
            }}
            className="rounded-lg w-5/6 p-4 shadow-md bg-gradient-to-b from-blue to-dBlue"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 42, 64, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
            contentLabel="Example Modal"
          >
            <h1 className="modal-title">Update User Role for - {row.given_name} {row.family_name}</h1>
            {selectedRole.value === "ADMIN" ? 
                <p className="modal-description">
                    The <b>ADMIN</b> role allows the user to add, delete, and update other users within your team. 
                    Are you sure you want to elevate this user's permissions?
                </p> :
                <p className="modal-description">
                    The <b>USER</b> role prohibits the user from adding, deleting, or updating other users within your team. 
                    Are you sure you want to downgrade this users permissions? 
                </p>
            }
            <div className="w-full flex flex-col justify-around items-center md:flex-row py-4 space-y-4">
              <Button
                onPress={handleCancel}
              >
                No
              </Button>
              <Button
                className="Button-red"
                onPress={handleConfirm}
              >
                Yes
              </Button>
            </div>
          </Modal>
        </>
      );
    };

  const clickDelete = async (user: User) => {
    setDeleteInProgress(true);
    user.remove().then(resp => {
      if (resp.status === 204) {
        navigate('/team');
      } 
      setDeleteInProgress(false);
    });
  };

  const basicUsersColumns: any = [
    {
      name: "Name",
      selector: (row: User, index: number) => row.given_name +" "+ row.family_name,
      cell: (row: User) => (account.sub === row.id ? row.given_name +" "+ row.family_name + " (me)": row.given_name +" "+ row.family_name), 
      id: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: User) => row.email,
    },
    {
      name: "Phone",
      selector: (row: User) => row.phone_number,
    },
    {
      name: "Role",
      selector: (row: User) => row.role
    }
  ];

  const adminUsersColumns: any = [
    {
      name: "Name",
      selector: (row: User, index: number) =>  row.given_name +" "+ row.family_name,
      cell: (row: User) => (account.sub === row.id ? row.given_name +" "+ row.family_name + " (me)": row.given_name +" "+ row.family_name), 
      id: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: User) => row.email,
    },
    {
      name: "Phone",
      selector: (row: User) => row.phone_number,
    },
    {
      name: "Role",
      cell: (row: User) => (
        account.sub !== row.id ?
        <CustomSelect
          row={row}
          setCustomSelect={(role) => (row.role = role)}
        /> :
        row.role
      )
    },
    {
      name: "",
      cell: (row: User) => (
        account.sub !== row.id ?
        <DeleteWithConfirmation
          key={row.id}
          onDelete={() => clickDelete(row)}
          itemName={row.given_name + " " + row.family_name}
          itemType={"User"}
        /> :
        ""
      ),
    },
  ];


  if (account.role === "ADMIN"){
    usersColumns = adminUsersColumns
  } else {
    usersColumns = basicUsersColumns
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "4rem", // override the row height
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };


  return (
    <MainContentView>
      <div>
        <h2 className="text-3xl">My Team</h2>
        {account.role === "ADMIN" ?
        <DataTable
          columns={adminUsersColumns}
          data={users}
          customStyles={customStyles}
          defaultSortFieldId={"Name"}
        />
        : 
        <DataTable
          columns={usersColumns}
          data={users}
          customStyles={customStyles}
          defaultSortFieldId={"Name"}
        />
        }
        <div className="SettingsView-tile-container">
            <StatBox title="Total Users:">{users.length}</StatBox>
        </div>
        {account.role === "ADMIN" ?
            <div className="SettingsView-tile-container">
            <AddNewTile text="Add New User" path="/team/new" />
            </div>
            : 
            ""
        }
      </div>
    </MainContentView>
  );
});

export default UsersView;
