import { CelsiusTemperature } from "./types";
import { Dry, Note } from "./stores/DryStore";
import { Space } from "./stores/SpaceStore";
import { Strain } from "./stores/StrainStore";
import { Gateway } from "./stores/GatewayStore";
import { Sensor, SensorPack } from "./stores/SensorStore";
import { User} from "./stores/UserStore";

export interface DryFormErrors {
  name?: string;
  strain_id?: string;
  space_id?: string;
  lot_number?: string;
  dry_weight?: string;
  wet_weight?: string;
  moisture_target?: string;
  temperature_spatial_variability?: string;
  humidity_spatial_variability?: string;
  moisture_spatial_variability?: string;
}
export interface NoteFormErrors {
  details?: string;
}
export interface GatewayErrors {
  alias?: string;
}
export interface SensorPackErrors {
  alias?: string;
}

export interface SpaceFormErrors {
  name?: string;
  temperature_spatial_variability?: string;
  humidity_spatial_variability?: string;
}

export interface StrainFormErrors {
  name?: string;
  moisture_target?: string;
  moisture_spatial_variability?: string;
}

export interface UserFormErrors {
    given_name?: string;
    family_name?: string;
    email?: string;
    role?: string;
}

export function validateUserForm(user: User) {
    const formErrors: UserFormErrors = {};
    const name_regexp: RegExp = new RegExp(/^[a-zA-Z -]+$/);
    const email_regexp: RegExp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    if (user.given_name.length === 0) {
      formErrors.given_name = "First name is required";
    }
  
    if (!name_regexp.test(user.given_name)) {
      formErrors.given_name =
        "First name can only contain letters, spaces, and hyphens";
    }
    if (user.family_name.length === 0) {
      formErrors.family_name = "Last name is required";
    }
  
    if (!name_regexp.test(user.family_name)) {
      formErrors.family_name =
        "Last name can only contain letters, spaces, and hyphens";
    }
    
    if (user.email.length === 0) {
        formErrors.email = "Email is required";
      }
    
      if (!email_regexp.test(user.email)) {
        formErrors.email =
          "Email invalid, check the format and try again.";
      }
      
    if (!User.roleOpts.includes(user.role)){
        formErrors.role = "You must input a valid role. Valid options are ${user.roleOpts.join(', ')}";
    }
    return formErrors;
  }

export function validateDryForm(dry: Dry) {
  const formErrors: DryFormErrors = {};
  const dry_name_regexp: RegExp = new RegExp(/^[\w\s\-"']{1,100}$/);

  if (dry.name.length === 0) {
    formErrors.name = "Dry name is required";
  }

  if (!dry_name_regexp.test(dry.name)) {
    formErrors.name =
      "Dry name cannot contain any special characters other than -\"'";
  }

  if (dry.lot_number && !dry_name_regexp.test(dry.lot_number)) {
    formErrors.lot_number =
      "Lot number cannot contain any special characters other than -\"'";
  }

  if (dry.dry_weight < 0) {
    formErrors.dry_weight = "You must set non-negative Dry Weight";
  }

  if (dry.wet_weight < 0) {
    formErrors.wet_weight = "You must set non-negative Wet Weight";
  }

  if (!dry.strain_id) {
    formErrors.strain_id = "You must set a strain";
  }
  if (!dry.space_id) {
    formErrors.space_id = "You must set a space";
  }
  if (dry.moisture_target < 5 || dry.moisture_target > 20) {
    formErrors.moisture_target = "Moisture target must be between 5-20%";
  }
  if (
    dry.temperature_spatial_variability.degrees < 0.0001 ||
    dry.temperature_spatial_variability.degrees > 100
  ) {
    const lowerLimit =
      dry.store.rootStore.uiStore.displayTemperatureDeltaString(
        new CelsiusTemperature(0.0001),
        0
      );
    const upperLimit =
      dry.store.rootStore.uiStore.displayTemperatureDeltaString(
        new CelsiusTemperature(100),
        0
      );
    formErrors.temperature_spatial_variability = `Temperature variability must be between ${lowerLimit} and ${upperLimit}`;
  }
  if (
    dry.humidity_spatial_variability < 0.0001 ||
    dry.humidity_spatial_variability > 100
  ) {
    formErrors.humidity_spatial_variability =
      "Humidity variability must be between 0.0001-100";
  }
  if (
    dry.moisture_spatial_variability < 0.0001 ||
    dry.moisture_spatial_variability > 100
  ) {
    formErrors.moisture_spatial_variability =
      "Moisture variability must be between 0.0001-100";
  }
  return formErrors;
}


export function validateNoteForm(note: Note) {
    const formErrors: NoteFormErrors = {};
    const note_details_regexp: RegExp = new RegExp(/^[a-zA-Z0-9 .,:#!?%'"()\-\r\n]*$/);
  
    if (note.details.length === 0) {
      formErrors.details = "Enter something for the details!";
    }
    if (!note_details_regexp.test(note.details)) {
      formErrors.details =
        "Details can only have letters, numbers, spaces, and the following characters: .,#!?%\'\"()\-";
    }
    return formErrors;
  }

export function validateSpaceForm(space: Space) {
  const formErrors: SpaceFormErrors = {};
  const space_name_regexp: RegExp = new RegExp(/^[\w\s\-"']{1,100}$/);

  if (space.name.length === 0) {
    formErrors.name = "Space name is required";
  }
  if (!space_name_regexp.test(space.name)) {
    formErrors.name =
      "Space name cannot contain any special characters other than -\"'";
  }
  if (
    space.temperature_spatial_variability.degrees < 0.0001 ||
    space.temperature_spatial_variability.degrees > 100
  ) {
    const lowerLimit =
      space.store.rootStore.uiStore.displayTemperatureDeltaString(
        new CelsiusTemperature(0.0001),
        0
      );
    const upperLimit =
      space.store.rootStore.uiStore.displayTemperatureDeltaString(
        new CelsiusTemperature(100),
        0
      );
    formErrors.temperature_spatial_variability = `Temperature variability must be between ${lowerLimit} and ${upperLimit}`;
  }
  if (
    space.humidity_spatial_variability < 0.0001 ||
    space.humidity_spatial_variability > 100
  ) {
    formErrors.humidity_spatial_variability =
      "Humidity variability must be between 0.0001-100%";
  }
  return formErrors;
}

export function validateStrainForm(strain: Strain) {
  const formErrors: StrainFormErrors = {};
  const strain_name_regexp: RegExp = new RegExp(/^[\w\s\-"']{1,100}$/);

  if (strain.name.length === 0) {
    formErrors.name = "Strain name is required";
  }
  if (!strain_name_regexp.test(strain.name)) {
    formErrors.name =
      "Space name cannot contain any special characters other than -\"'";
  }
  if (strain.moisture_target < 9 || strain.moisture_target > 20) {
    formErrors.moisture_target = "Target moisture must be between 9-20%";
  }
  if (
    strain.moisture_spatial_variability < 0.0001 ||
    strain.moisture_spatial_variability > 100
  ) {
    formErrors.moisture_spatial_variability =
      "Moisture variability must be between 0.0001-100%";
  }
  return formErrors;
}
export function validateGatewayForm(gateway: Gateway) {
  const formErrors: GatewayErrors = {};
  const gateway_alias_regexp: RegExp = new RegExp(/^[\w"']{1,100}$/);

  if (gateway.alias.length === 0) {
    formErrors.alias = "Gateway alias is required";
  }
  if (!gateway_alias_regexp.test(gateway.alias)) {
    formErrors.alias =
      "Gateway alias must only consist of (a-z), (A-Z), (0-9), quotations, or _ (underscore)";
  }
  return formErrors;
}
export function validateSensorPackForm(sensorPack: SensorPack) {
  const formErrors: GatewayErrors = {};
  const sensorPack_alias_regexp: RegExp = new RegExp(/^[\w"']{1,100}$/);

  if (sensorPack.alias.length === 0) {
    formErrors.alias = "Gateway alias is required";
  }
  if (!sensorPack_alias_regexp.test(sensorPack.alias)) {
    formErrors.alias =
      "SensorPack alias must only consist of (a-z), (A-Z), (0-9), quotations, or _ (underscore)";
  }
  return formErrors;
}
