import React from 'react';
import { Link } from 'react-router-dom';
import './AccountDisplay.css';

import AccountImage from './AccountImage';
import { Account } from './stores/AccountStore';

interface AccountDisplayProps {
  account: Account;
}

function AccountDisplay(props: AccountDisplayProps) {
  const account = props.account;
  return (
    <Link to="/account" className="AccountDisplay">
      <AccountImage />
      &nbsp;{account.name}
    </Link>
  );
}

export default AccountDisplay;
