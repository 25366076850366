import React from 'react';
import './ReportSelector.css';
import { Link } from 'react-router-dom';
import { Dry } from '../stores/DryStore';
import DataTable from 'react-data-table-component';
import DateTime from '../DateTime';

// TODO(eric): once reports are implemented, most likely this would be
// receiving an array of reports as props

interface ReportSelectorProps {
  dries: Array<Dry>;
}

function ReportSelector(props: ReportSelectorProps) {
  const { dries } = props;

  const caseInsensitiveSort = (field: keyof Dry) => (rowA: Dry, rowB: Dry) => {
    const a = String(rowA[field] || "-")?.toLowerCase();
    const b = String(rowB[field] || "-")?.toLowerCase();
    
    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortedDries = dries.sort((d1, d2) => {
    if (d2.stoptime && d1.stoptime) {
      return new Date(d2.stoptime).getTime() - new Date(d1.stoptime).getTime()
    }
    return 0;
  });

  const columns: any = [
    {
      name: 'Dry Name',
      selector: (row: Dry) => row.name,
      sortable: true,
      sortFunction: caseInsensitiveSort("name"),
    },
    {
      name: 'Lot Number',
      selector: (row: Dry) => row.lot_number || "-",
      sortable: true,
      sortFunction: caseInsensitiveSort("lot_number"),
    },
    {
      name: 'Dry Start Time',
      selector: (row: Dry) => row.starttime,
      cell: (row: Dry) => <DateTime iso8601={row.starttime} />,
      sortable: true,
    },
    {
      name: 'View',
      selector: (row: Dry) => <Link to={`/reports/dries/${row.id}`}>view report</Link>
    },
  ];

  return (
    <div className="w-full">
      <DataTable
        columns={columns}
        data={sortedDries}
      />
    </div>
  );
}

export default ReportSelector;
