import React from 'react';

const packages = [
    {
        "packageName": "@aws amplify/ui react",
        "packageLink": "https://www.npmjs.com/package/@aws-amplify/ui-react",
        "licenseLink": "https://www.npmjs.com/package/@aws-amplify/ui-react/license",
        "licenseIssuer": "Apache-"
    },
    {
        "packageName": "@testing library/jest dom",
        "packageLink": "https://www.npmjs.com/package/@testing-library/jest-dom",
        "licenseLink": "https://www.npmjs.com/package/@testing-library/jest-dom/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@testing library/react",
        "packageLink": "https://www.npmjs.com/package/@testing-library/react",
        "licenseLink": "https://www.npmjs.com/package/@testing-library/react/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@testing library/user event",
        "packageLink": "https://www.npmjs.com/package/@testing-library/user-event",
        "licenseLink": "https://www.npmjs.com/package/@testing-library/user-event/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@types/jest",
        "packageLink": "https://www.npmjs.com/package/@types/jest",
        "licenseLink": "https://www.npmjs.com/package/@types/jest/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@types/node",
        "packageLink": "https://www.npmjs.com/package/@types/node",
        "licenseLink": "https://www.npmjs.com/package/@types/node/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@types/react",
        "packageLink": "https://www.npmjs.com/package/@types/react",
        "licenseLink": "https://www.npmjs.com/package/@types/react/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "@types/react dom",
        "packageLink": "https://www.npmjs.com/package/@types/react-dom",
        "licenseLink": "https://www.npmjs.com/package/@types/react-dom/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "aws amplify",
        "packageLink": "https://www.npmjs.com/package/aws-amplify",
        "licenseLink": "https://www.npmjs.com/package/aws-amplify/license",
        "licenseIssuer": "Apache-"
    },
    {
        "packageName": "axios",
        "packageLink": "https://www.npmjs.com/package/axios",
        "licenseLink": "https://www.npmjs.com/package/axios/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "mobx",
        "packageLink": "https://www.npmjs.com/package/mobx",
        "licenseLink": "https://www.npmjs.com/package/mobx/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "mobx react",
        "packageLink": "https://www.npmjs.com/package/mobx-react",
        "licenseLink": "https://www.npmjs.com/package/mobx-react/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "moment",
        "packageLink": "https://www.npmjs.com/package/moment",
        "licenseLink": "https://www.npmjs.com/package/moment/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react",
        "packageLink": "https://www.npmjs.com/package/react",
        "licenseLink": "https://www.npmjs.com/package/react/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react data table component",
        "packageLink": "https://www.npmjs.com/package/react-data-table-component",
        "licenseLink": "https://www.npmjs.com/package/react-data-table-component/license",
        "licenseIssuer": "Apache-"
    },
    {
        "packageName": "react datepicker",
        "packageLink": "https://www.npmjs.com/package/react-datepicker",
        "licenseLink": "https://www.npmjs.com/package/react-datepicker/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react dom",
        "packageLink": "https://www.npmjs.com/package/react-dom",
        "licenseLink": "https://www.npmjs.com/package/react-dom/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react icons",
        "packageLink": "https://www.npmjs.com/package/react-icons",
        "licenseLink": "https://www.npmjs.com/package/react-icons/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react modal",
        "packageLink": "https://www.npmjs.com/package/react-modal",
        "licenseLink": "https://www.npmjs.com/package/react-modal/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react router dom",
        "packageLink": "https://www.npmjs.com/package/react-router-dom",
        "licenseLink": "https://www.npmjs.com/package/react-router-dom/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react scripts",
        "packageLink": "https://www.npmjs.com/package/react-scripts",
        "licenseLink": "https://www.npmjs.com/package/react-scripts/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react select",
        "packageLink": "https://www.npmjs.com/package/react-select",
        "licenseLink": "https://www.npmjs.com/package/react-select/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react slider",
        "packageLink": "https://www.npmjs.com/package/react-slider",
        "licenseLink": "https://www.npmjs.com/package/react-slider/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "react toggle",
        "packageLink": "https://www.npmjs.com/package/react-toggle",
        "licenseLink": "https://www.npmjs.com/package/react-toggle/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "recharts",
        "packageLink": "https://www.npmjs.com/package/recharts",
        "licenseLink": "https://www.npmjs.com/package/recharts/license",
        "licenseIssuer": "MIT"
    },
    {
        "packageName": "typescript",
        "packageLink": "https://www.npmjs.com/package/typescript",
        "licenseLink": "https://www.npmjs.com/package/typescript/license",
        "licenseIssuer": "Apache-"
    },
    {
        "packageName": "web vitals",
        "packageLink": "https://www.npmjs.com/package/web-vitals",
        "licenseLink": "https://www.npmjs.com/package/web-vitals/license",
        "licenseIssuer": "Apache-"
    }
]


function Attributions() {

    const rows = packages.map((credit) => (
        <tr key={credit.packageName}>
            <td>{credit.packageName}</td>
            <td><a href={credit.packageLink}>{credit.packageLink}</a></td>
            <td><a href={credit.licenseLink}>{credit.licenseIssuer.split("-")}</a></td>
        </tr>
    ))

    return (
        <div style={{ padding: "2% 10%" }}>
            <h1 style={{ textAlign: "center" }} >Growvera Zone Attributions</h1>
            <table>
                <thead>
                    <tr style={{ color: 'white' }}>
                        <th>Package Name</th>
                        <th>Homepage</th>
                        <th>License</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}

export default Attributions