import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AddNewTile.css';

interface AddNewTileProps {
  text: string;
  path: string;
}

function AddNewTile(props: AddNewTileProps) {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(props.path)} className="AddNewTile">
      <h3>{ props.text }</h3>
      <div className="AddNewTile-plus" />
    </div>
  );
}

export default AddNewTile;
