import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../StoreProvider';
import { useParams } from 'react-router-dom';
import { Space } from '../stores/SpaceStore';
import UpdateSpace from './UpdateSpace';
import NotFound from '../NotFound';

const UpdateSpaceView = observer(function UpdateSpaceView() {
  const { spaceStore } = useRootStore();
  const { id } = useParams();

  let space: Space | undefined;
  if (id) {
    space = spaceStore.spaces.get(id);
  } else {
    space = spaceStore.createSpace();
  }
  if (space === undefined) {
    return <NotFound />;
  }

  return (
    <div>
      <UpdateSpace space={space} />
    </div>
  );
});

export default UpdateSpaceView;
