import React from 'react';
import './Loading.css'

interface LoadingProps {
  children?: React.ReactNode;
}

function Loading(props: LoadingProps) {
  const loadText = props.children || "Loading...";
  return (
    <div>
      <h2 className="Loading">
        {loadText}
      </h2>
    </div>
  );
}

export default Loading;
