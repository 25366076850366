import React, { useCallback } from "react";
import "./DryChart.css";
import { DryTimeseriesDataType, IdealDryData, NoteType } from "../types";
import moment from "moment";
import { Note } from '../stores/DryStore';

import {
AnnotationClickEventArgs,
IAnnotation,
  EAutoRange,
  buildSeries,
  EAxisAlignment,
  ELegendOrientation,
  ELineDrawMode,
  FastLineRenderableSeries,
  FastBandRenderableSeries,
  LegendModifier,
  MouseWheelZoomModifier,
  NumberRange,
  NumericAxis,
  RolloverModifier,
  SciChartOverview,
  SciChartSurface,
  VisibleRangeChangedArgs,
  XyDataSeries,
  XyyDataSeries,
  XySeriesInfo,
  XyySeriesInfo,
  ZoomExtentsModifier,
  SeriesInfo,
  IRenderableSeries,
  ZoomPanModifier,
  CustomAnnotation,
  TextAnnotation,
  EVerticalAnchorPoint,
  AnnotationHoverModifier,
  EHorizontalAnchorPoint
} from "scichart";

import { downloadCSV, formatDate } from "../Utils";
import CreateNoteButton from "./CreateNoteButton";
import CustomNoteAnnotation from "./CustomNoteAnnotation";  // Adjust the import path as needed


type onAddNoteType = () => void;
interface DryChartProps {
  data: Array<DryTimeseriesDataType>;
  idealData: IdealDryData;
  dryName: string;
  dryStartTime: string | undefined;
  moistureTarget: number;
  noteData: Array<Note>;
  noteDataLoaded: boolean;
  newNote: Note;
  addNote: () => void;
}

export const divElementId = "chart";
export const divOverviewId = "overview";


const handleCSVExport = (csvData: DryTimeseriesDataType[] | undefined, dryName: string) => {
  if (!!!csvData) return

  const headers = ['Timestamp ISO8601 Format', 'Average Temperature', 'Average Humidity', 'Average Moisture', 'Average VPD']
  let csvContent = headers.join(",") + "\n";
  const now = moment(Date.now()).toISOString();
  const chartName = dryName.replace(/\s+/g, "") + "_" + now + "_Average_Data"

  csvData.forEach((tsData) => {
    const {timestamp, avg_temperature, avg_humidity, avg_moisture, avg_vpd} = tsData
    csvContent += `${formatDate(timestamp)},${avg_temperature},${avg_humidity},${avg_moisture},${avg_vpd}\n`
  })
  downloadCSV(csvContent, chartName+".csv")
}

const surfaceChart = (type: string, series: Array<any>, bandSeriesData: any, tmSeriesData: any, INCLUDE_VPD_AXIS: boolean, noteData: Array<NoteType>) => async () => {
  // Create a SciChartSurface
  const { wasmContext, sciChartSurface } = await SciChartSurface.create(
    `${type + divElementId}`
  );

  // Create an XAxis and YAxis
  const xAxis = new NumericAxis(wasmContext);
  const xAxisFormatter = (diffInDays: number) => (dataValue: number) => {
    return diffInDays < 3 ? moment(dataValue).format("D MMM YY hh:mm A").valueOf() : moment(dataValue).format("D MMM YY").valueOf();
  }

  xAxis?.visibleRangeChanged.subscribe(
    (data: VisibleRangeChangedArgs | undefined) => {
      const millisecondsInDay = 1000 * 60 * 60 * 24;
      const diffInDays = Math.floor((data?.visibleRange.diff || 0) / millisecondsInDay);
      xAxis.labelProvider.formatLabel = xAxisFormatter(diffInDays)
  });

  xAxis.labelProvider.formatCursorLabel = (dataValue) => {
    return moment(dataValue).format("MM/DD/YY hh:mm A").valueOf();
  };

  const yAxis = new NumericAxis(wasmContext, {
    id:"DefaultAxisId",
    axisAlignment: EAxisAlignment.Left,
    growBy: new NumberRange(0.1, 0.2),
    labelPrecision: 1,
    autoRange: EAutoRange.Always,
  });


  const yAxis1 = new NumericAxis(wasmContext, {
    id:"VpdY",
    axisTitle:"VPD (kPa)",
    axisAlignment: EAxisAlignment.Right,
    // growBy: new NumberRange(0.1, 0.2),
    visibleRange: new NumberRange(0, 3),
    labelPrecision: 2,
    autoRange: EAutoRange.Never,
  });
  yAxis1.visibleRange = new NumberRange(0, 3);

  sciChartSurface.xAxes.add(xAxis);
  sciChartSurface.yAxes.add(yAxis);
  if (INCLUDE_VPD_AXIS){
    sciChartSurface.yAxes.add(yAxis1);
  }

  
  const axisPreservingTransform = (rendSeries: IRenderableSeries) => {
    // clone the series using builder api
    const [overviewSeries] = buildSeries(wasmContext, rendSeries.toJSON(true));
    overviewSeries.dataSeries = rendSeries.dataSeries;
    overviewSeries.xAxisId = xAxis.id;
    // assuming you have added y axes to the overview with matching Ids.  Note that you need to create a new axis (or clone it using buildAxes).  You cannot add the same axis instance to more than one chart
    overviewSeries.yAxisId = yAxis.id;
    return overviewSeries;
  };

  const { xValues, yValues, y1Values } = {
    xValues: bandSeriesData.data.map((d: { xx: any }) => d.xx),
    yValues: bandSeriesData.data.map((d: { y: any }) => d.y),
    y1Values: bandSeriesData.data.map((d: { y1: any }) => d.y1),
    };
    const bandSeries = new FastBandRenderableSeries(wasmContext, {
        dataSeries: new XyyDataSeries(wasmContext, {
            xValues,
            yValues,
            y1Values,
            dataSeriesName: `${bandSeriesData.name}`,
            isSorted: true
        }),
        strokeThickness: 2,
        fill: '#69ad85',
        fillY1: '#69ad85',
        opacity:0.3,
        stroke: bandSeriesData.color,
        strokeY1: bandSeriesData.color,
        strokeDashArray: bandSeriesData.strokeDashArray,
        strokeY1DashArray: bandSeriesData.strokeDashArray,
    })

    // const bandTooltipDataTemplate: TRolloverTooltipDataTemplate = (seriesInfo: SeriesInfo): string[] => {
    //     const valuesWithLabels: string[] = [];
    //     // Perform a type check and cast if it's indeed XyySeriesInfo
    //     const xyySeriesInfo = seriesInfo as XyySeriesInfo;
    //     // Add logic to handle XyySeriesInfo specific data
    //     valuesWithLabels.push(`Y1 Value: ${xyySeriesInfo.y1Value}`);
    //     // Add other specific data from xyySeriesInfo if needed
    //     // Handle generic SeriesInfo case if needed
    //     valuesWithLabels.push(`Y Value: ${seriesInfo.yValue}`);
    //     return valuesWithLabels;
    // };
    // bandSeries.rolloverModifierProps.tooltipDataTemplate = bandTooltipDataTemplate;
    // bandSeries.rolloverModifierProps1.tooltipDataTemplate = bandTooltipDataTemplate;

    bandSeries.rolloverModifierProps1.tooltipTitle = ''
    bandSeries.rolloverModifierProps.tooltipTitle = ''
    bandSeries.rolloverModifierProps.tooltipLabelY = "Lower Limit"
    bandSeries.rolloverModifierProps1.tooltipLabelY = "Upper Limit"
    sciChartSurface.renderableSeries.add(bandSeries);


  for (let i = 0; i < series.length; i++) {
    // Create arrays of x, y values (just arrays of numbers)
    const { xValues, yValues } = {
        xValues: series[i].data.map((d: { xx: any }) => d.xx),
        yValues: series[i].data.map((d: { y: any }) => d.y || Number.NaN),
    };
    let yAxisId = "DefaultAxisId"
    if (series[i].name ==  "Average VPD"){
        yAxisId = "VpdY"
    } 

    const lineSeries = new FastLineRenderableSeries(wasmContext, {
        yAxisId:yAxisId,
        dataSeries: new XyDataSeries(wasmContext, {
        xValues,
        yValues,
        dataSeriesName: `${series[i].name}`,
        isSorted: true
        }),
        stroke: series[i].color,
        strokeThickness: 3,
        strokeDashArray:series[i].strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });

    const tooltipDataTemplate = (seriesInfo: XySeriesInfo): string[] => {
        const valuesWithLabels: string[] = [];
        // Line Series
        const xySeriesInfo = seriesInfo as XySeriesInfo;
        valuesWithLabels.push(
            ` ${xySeriesInfo.seriesName}: ${xySeriesInfo.formattedYValue} `
        );
        return valuesWithLabels;
        };
    
        lineSeries.rolloverModifierProps.tooltipDataTemplate = tooltipDataTemplate;
        sciChartSurface.renderableSeries.add(lineSeries);
  }

  // PLOT TARGET MOISTURE-
    const { tmxValues, tmyValues } = {
        tmxValues: tmSeriesData.data.map((d: { xx: any }) => d.xx),
        tmyValues: tmSeriesData.data.map((d: { y: any }) => d.y),
    };
    const tm_lineSeries = new FastLineRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
        xValues:tmxValues,
        yValues:tmyValues,
        dataSeriesName: `${tmSeriesData.name}`,
        isSorted: true
        }),
        stroke: tmSeriesData.color,
        strokeThickness: 3,
        strokeDashArray:tmSeriesData.strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });

    tm_lineSeries.rolloverModifierProps.tooltipTextColor = "black"
    sciChartSurface.renderableSeries.add(tm_lineSeries);

  sciChartSurface.background = "#002a40";

  // Optional: Add some interactivity to the chart
  const modifierGroup = type;
  const rollover = new RolloverModifier({
    modifierGroup,
    showAxisLabel: true,
    rolloverLineStrokeDashArray: [2, 2],
    showTooltip: true,
    // placementDivId: `tooltip-${type}`,
  });


  rollover.includeSeries(tm_lineSeries, false)
  rollover.includeSeries(bandSeries, false)

  sciChartSurface.chartModifiers.add(
    new ZoomExtentsModifier({ modifierGroup }),
    new MouseWheelZoomModifier({ modifierGroup }),
    new ZoomPanModifier({ modifierGroup }),
    new AnnotationHoverModifier(),
    rollover,
    new LegendModifier({
      modifierGroup,
      showCheckboxes: true,
      orientation: ELegendOrientation.Horizontal,
      placementDivId: `legend-${type}`,
      backgroundColor: "#002a40",
    })
  );

  sciChartSurface.zoomExtents();


  // You have to manually add the second axis traces to the scroll bar
    const { OverviewXValues, OverviewYValues } = {
        OverviewXValues: series[3].data.map((d: { xx: any }) => d.xx),
        OverviewYValues: series[3].data.map((d: { y: any }) => d.y || Number.NaN),
    };

    const OverviewVpdSeries = new FastLineRenderableSeries(wasmContext, {
        yAxisId:"OverviewVpdY",
        dataSeries: new XyDataSeries(wasmContext, {
        xValues:OverviewXValues,
        yValues:OverviewYValues,
        dataSeriesName: `${series[3].name}`,
        isSorted: true
        }),
        stroke: series[3].color,
        strokeThickness: 3,
        strokeDashArray:series[3].strokeDashArray,
        drawNaNAs: ELineDrawMode.DiscontinuousLine,
    });

    
  const overview = await SciChartOverview.create(
    sciChartSurface,
    `${type + divOverviewId}`
  );

  
  overview.overviewSciChartSurface.yAxes.add(
        new NumericAxis(wasmContext, {
        id:"OverviewVpdY",
        axisAlignment: EAxisAlignment.Right,
        drawLabels:false,
        visibleRange: new NumberRange(0,3),
        autoRange:EAutoRange.Never
    })
    );

    overview.overviewSciChartSurface.renderableSeries.add(OverviewVpdSeries)

    noteData.forEach(note => {
        const timestamp_value = moment(note.timestamp).valueOf()
        const annotation = new CustomNoteAnnotation(timestamp_value, note.details);
        sciChartSurface.annotations.add(annotation);
    });

  return { wasmContext, sciChartSurface, overview };
};

const DryTHChart = (props: DryChartProps) => {
 
  const sciChartSurfaceRef = React.useRef<SciChartSurface>();
  const sciChartOverviewRef = React.useRef<SciChartOverview>();
  const seriesRef = React.useRef<DryTimeseriesDataType[]>()

  const type = "dryChart";
  const unsubscribe = React.useCallback(() => {
    sciChartSurfaceRef.current?.xAxes
      .get(0)
      ?.visibleRangeChanged.unsubscribeAll();
    sciChartSurfaceRef.current?.delete();
    sciChartOverviewRef.current?.delete();
    sciChartSurfaceRef.current = undefined;
    sciChartOverviewRef.current = undefined;
  }, []);

  const {data, idealData, dryName, dryStartTime, moistureTarget, noteData, noteDataLoaded, newNote, addNote} = props

  const getBandData = useCallback(() => {
    const bandSeriesData = 
      {
        name: "Ideal Moisture Curve",
        data: idealData.time_elapsed_hrs.map((t, index) => ({
          x: moment(dryStartTime).add(t, 'hours').format("MM/DD/YY hh:mm A").valueOf(),
          xx: moment(dryStartTime).add(t, 'hours').valueOf(),
          y: idealData.lower_bound[index],
          y1: idealData.upper_bound[index],
        })),
        color: "#3F6C51",
        strokeDashArray: [5,5]
      }
    ;
    return bandSeriesData
  }, [data, idealData, dryName, dryStartTime])

  const getData = useCallback(() => {
    const series = [
      {
        name: "Average Temperature",
        data: data.map((d) => ({
          x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
          xx: d.timestamp,
          y: d.avg_temperature,
        })),
        color: "#cc3c0c",
      },
      {
        name: "Average Humidity",
        data: data.map((d) => ({
          x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
          xx: d.timestamp,
          y: d.avg_humidity,
        })),
        color: "#4287f5",
      },
      {
        name: "Average Moisture",
        data: data.map((d) => ({
          x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
          xx: d.timestamp,
          y: d.avg_moisture,
        })),
        color: "#82ca9d",
      },
      {
        name: "Average VPD",
        data: data.map((d) => ({
          x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
          xx: d.timestamp,
          y: d.avg_vpd,
        })),
        color: "#c8a2c8",
      }
    ];
    return series
  }, [data, idealData, dryName, dryStartTime])

  const getTargetMoistureData = useCallback(() => {
    let tm_series: any = {};
    const data_ts = data.map(d => d.timestamp)
    const data_diff_hrs = (Math.max(...data_ts) - Math.min(...data_ts))/ (1000 * 60 * 60) 
    const total_time_elapsed_ideal = Math.max(...idealData.time_elapsed_hrs);

    if (data_diff_hrs >= total_time_elapsed_ideal){
    tm_series = {
        name: "Target Moisture",
        data: data.map((d) => ({
          x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
          xx: d.timestamp,
          y: moistureTarget,
        })),
        color: "#ffffff",
        strokeDashArray: [5,5]
      };
    } else {
        tm_series = {
              name: "Target Moisture",
              data: idealData.time_elapsed_hrs.map((t, index) => ({
                x: moment(dryStartTime).add(t, 'hours').format("MM/DD/YY hh:mm A").valueOf(),
                xx: moment(dryStartTime).add(t, 'hours').valueOf(),
                y: moistureTarget,
              })),
              color: "#ffffff",
              strokeDashArray: [5,5]
            }
    }
    return tm_series
  }, [data, moistureTarget, idealData])

  React.useEffect(() => {
    const series = getData()
    const bandSeries = getBandData()
    const tmSeriesData = getTargetMoistureData()
    const INCLUDE_VPD_AXIS = true;
    const fetchData = async () => {
      const { sciChartSurface, overview } = await surfaceChart(type, series, bandSeries, tmSeriesData, INCLUDE_VPD_AXIS, noteData)();
      sciChartSurfaceRef.current = sciChartSurface;
      // Make sure the visible range gets applied
      const sec_y = sciChartSurfaceRef.current.yAxes.getById('VpdY')
      sec_y.visibleRange = new NumberRange(0,3);
      sciChartOverviewRef.current = overview;
      seriesRef.current = data


        // noteData.forEach(note => {
        //     const timestamp_value = moment(note.timestamp).valueOf()
        //     const annotation = new CustomNoteAnnotation(timestamp_value, note.details);
        //     sciChartSurface.annotations.add(annotation);
        // });
    console.log('hello')
    };
    fetchData();

    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        unsubscribe();
        return;
      }

      // else postpone deletion
      fetchData().then(() => {
        unsubscribe();
      });
    };
  }, [unsubscribe]);

  React.useEffect(() => {

    if (sciChartSurfaceRef.current) {
      let series = getData()
      let bandSeriesData = getBandData()
      let tmSeriesData = getTargetMoistureData();
      const redneredSeries = sciChartSurfaceRef.current.renderableSeries
      // Make sure the visible range gets applied
      const sec_y = sciChartSurfaceRef.current.yAxes.getById('VpdY')
      sec_y.visibleRange = new NumberRange(0,3);
      for (let i = 0; i < redneredSeries.size(); i++) {
        let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries
        const temp = series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()) || []
        const { xValues, yValues } = {
          xValues: temp.map((d: { xx: any }) => d.xx),
          yValues: temp.map((d: { y: any }) => d.y || Number.NaN),
        };

        
        xValues?.forEach((x, ind) => {
          xyDs.append(x, yValues?.[ind] || Number.NaN) 
        })
        
      }
    }
  }, [data])

  return (
    <div className="">
      
      <div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0.5em",
        }}
        >
        <CreateNoteButton note={newNote} addNote={addNote}/> 
        <button  className="export-button" onClick={() => handleCSVExport(seriesRef.current, dryName)}>Export CSV</button>
        </div>
      {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
      <div style={{}}>
        <div>
          <div
            id={`${type + divElementId}`}
            style={{ width: "100%", height: 370 }}
          />
          <div
            id={`${type + divOverviewId}`}
            style={{ width: "100%", height: 100 }}
          />
          <div id={`legend-${type}`}  />
          {/* <div id={`tooltip-${type}`} style={{ width: "10%" }} />  */}
        </div>
      </div>
    </div>
  );
};


const DryVPDChart = (props: DryChartProps) => {
 
    const sciChartSurfaceRef = React.useRef<SciChartSurface>();
    const sciChartOverviewRef = React.useRef<SciChartOverview>();
    const seriesRef = React.useRef<DryTimeseriesDataType[]>()
  
    const type = "dryChart";
    const unsubscribe = React.useCallback(() => {
      sciChartSurfaceRef.current?.xAxes
        .get(0)
        ?.visibleRangeChanged.unsubscribeAll();
      sciChartSurfaceRef.current?.delete();
      sciChartOverviewRef.current?.delete();
      sciChartSurfaceRef.current = undefined;
      sciChartOverviewRef.current = undefined;
    }, []);
  
    const {data, idealData, dryName, dryStartTime, moistureTarget, noteData, noteDataLoaded} = props
  
    const getBandData = useCallback(() => {
      const bandSeriesData = 
        {
          name: "Ideal Moisture Curve",
          data: idealData.time_elapsed_hrs.map((t, index) => ({
            x: moment(dryStartTime).add(t, 'hours').format("MM/DD/YY hh:mm A").valueOf(),
            xx: moment(dryStartTime).add(t, 'hours').valueOf(),
            y: idealData.lower_bound[index],
            y1: idealData.upper_bound[index],
          })),
          color: "#3F6C51",
          strokeDashArray: [5,5]
        }
      ;
      return bandSeriesData
    }, [data, idealData, dryName, dryStartTime])
  
    const getData = useCallback(() => {
      const series = [
        {
          name: "Average Moisture",
          data: data.map((d) => ({
            x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
            xx: d.timestamp,
            y: d.avg_moisture,
          })),
          color: "#82ca9d",
        },
        {
          name: "Average VPD",
          data: data.map((d) => ({
            x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
            xx: d.timestamp,
            y: d.avg_vpd,
          })),
          color: "#ff7733",
        }
      ];
      return series
    }, [data, idealData, dryName, dryStartTime])
  
    const getTargetMoistureData = useCallback(() => {
      let tm_series: any = {};
      const data_ts = data.map(d => d.timestamp)
      const data_diff_hrs = (Math.max(...data_ts) - Math.min(...data_ts))/ (1000 * 60 * 60) 
      const total_time_elapsed_ideal = Math.max(...idealData.time_elapsed_hrs);
  
      if (data_diff_hrs >= total_time_elapsed_ideal){
      tm_series = {
          name: "Target Moisture",
          data: data.map((d) => ({
            x: moment(d.timestamp).format("MM/DD/YY hh:mm A").valueOf(),
            xx: d.timestamp,
            y: moistureTarget,
          })),
          color: "#ffffff",
          strokeDashArray: [5,5]
        };
      } else {
          tm_series = {
                name: "Target Moisture",
                data: idealData.time_elapsed_hrs.map((t, index) => ({
                  x: moment(dryStartTime).add(t, 'hours').format("MM/DD/YY hh:mm A").valueOf(),
                  xx: moment(dryStartTime).add(t, 'hours').valueOf(),
                  y: moistureTarget,
                })),
                color: "#ffffff",
                strokeDashArray: [5,5]
              }
      }
      return tm_series
    }, [data, moistureTarget, idealData])
  
    React.useEffect(() => {
      const series = getData()
      const bandSeries = getBandData()
      const tmSeriesData = getTargetMoistureData()
      const INCLUDE_VPD_AXIS = true;
      const fetchData = async () => {
        const { sciChartSurface, overview } = await surfaceChart(type, series, bandSeries, tmSeriesData, INCLUDE_VPD_AXIS, noteData)();
        sciChartSurfaceRef.current = sciChartSurface;
        sciChartOverviewRef.current = overview;
        seriesRef.current = data
      };
      fetchData();
  
      // Delete sciChartSurface on unmount component to prevent memory leak
      return () => {
        // check if chart is already initialized
        if (sciChartSurfaceRef.current) {
          unsubscribe();
          return;
        }
  
        // else postpone deletion
        fetchData().then(() => {
          unsubscribe();
        });
      };
    }, [unsubscribe]);
  
    React.useEffect(() => {
  
      if (sciChartSurfaceRef.current) {
        let series = getData()
        let bandSeriesData = getBandData()
        let tmSeriesData = getTargetMoistureData();
        const redneredSeries = sciChartSurfaceRef.current.renderableSeries
        for (let i = 0; i < redneredSeries.size(); i++) {
          let xyDs = redneredSeries.get(i).dataSeries as XyDataSeries
          const temp = series.find(vsl => vsl.name === xyDs.dataSeriesName)?.data.slice(xyDs.count()) || []
          const { xValues, yValues } = {
            xValues: temp.map((d: { xx: any }) => d.xx),
            yValues: temp.map((d: { y: any }) => d.y || Number.NaN),

          };
      
          // console.log(xyDs.count(), xValues, yValues)
          
          xValues?.forEach((x, ind) => {
            xyDs.append(x, yValues?.[ind] || Number.NaN) 
          })
          
        }
      }
    }, [data])
  
    return (
      <div className="">
        <button  className="export-button" onClick={() => handleCSVExport(seriesRef.current, dryName)}>Export CSV</button>
        {/* <Chart options={hum_options} series={series} type="line" height={350} /> */}
        <div style={{}}>
          <div>
            <div
              id={`${type + divElementId}`}
              style={{ width: "100%", height: 370 }}
            />
            <div
              id={`${type + divOverviewId}`}
              style={{ width: "100%", height: 100 }}
            />
            <div id={`legend-${type}`}  />
            {/* <div id={`tooltip-${type}`} style={{ width: "10%" }} />  */}
          </div>
        </div>
      </div>
    );
  };

export {
    DryTHChart, 
    DryVPDChart
};
