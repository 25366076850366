import React, { useState } from "react";
import { MdModeEdit } from "react-icons/md";
import "./TitleEditor.css";

type onChangeType = (evt: React.ChangeEvent<HTMLInputElement>) => void;
type onBlurType = () => void | null;
interface TitleEditorProps {
  onChange: onChangeType;
  onBlur?: onBlurType;
  title: string;
  value: string;
  setIsEditingFromOutside?: any;
}

function TitleEditor(props: TitleEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {isEditing && (
        <div>
          <input
            autoFocus
            onBlur={(evt) => {
              setIsEditing(false);
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                setIsEditing(false);
                if (props.onBlur) {
                  props.onBlur();
                }
              } else if (evt.key === "Escape") {
                setIsEditing(false);
              }
            }}
            className="TitleEditor-input"
            type="text"
            value={props.value}
            onChange={props.onChange}
          />
        </div>
      )}
      {!isEditing && (
        <h2
          className="flex text-3xl"
          onClick={() => {
            setIsEditing(true);
            props?.setIsEditingFromOutside(true);
          }}
        >
          {props.title || "Click to Edit Name"} <MdModeEdit className="mx-4"/>
        </h2>
      )}
    </>
  );
}

export default TitleEditor;
